import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import DistributionManager from "managers/survey_distribution/surveyDistributionManager";
import SurveyManager from "managers/survey_definition/surveyManager";
import { Helmet } from 'react-helmet'
import {Trophy} from "react-bootstrap-icons";

import "./surveyDistribution.css";

export default function SurveyDistribution(props) {

  let distributionManager = new DistributionManager(props.currentuser.smToken);
  let surveyManager = new SurveyManager(props.currentuser.smToken);

  let [surveys, setSurveys] = useState([]);
  let [distributions, setDistributions] = useState([]);
  let [selectionPlans, setSelectionPlans] = useState([]);
  let [contactPlans, setContactPlans] = useState([]);
  let [messagePlans, setMessagePlans] = useState([]);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    distributionManager.getDistributions().then((distro) => {
      setDistributions(distro);
      setLoading(false);
    });
  }, []);


  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: "",
      sort: true,
      headerStyle: () => {
        return { width: '3%' };
      },
    },
    {
      dataField: "openDate",
      text: "Open",
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "closeDate",
      text: "Close",
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },     
    {
      dataField: "isActive",
      text: "Active",
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: "eligibilityDays",
      text: "Eligibility Days",
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: "surveyName",
      text: "Survey Name",
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: "selectionPlanName",
      text: "Selecton Plan Name",
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: "contactPlanName",
      text: "Contact Plan Name",
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: "messagePlanName",
      text: "Message Plan Name",
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },

    {
      text: "",
      dataField: "",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
  ];

  function actionFormatter(cell, row) {
    return (
      <>
        <Link
          className="btn btn-sm btn-secondary"
          to={"/survey-distribution/edit/" + row.id}
        >
          Edit
        </Link>
      </>
    );
  }

  return (
    <Container fluid={true}>
      <Helmet><title>StayMetrics Admin - Survey Distribution</title></Helmet>
      <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Survey Distribution</Breadcrumb.Item>
            <Breadcrumb.Item href="#">List</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
                      <Col lg={6}>
                      <h2>Survey Distributions</h2>
                      </Col>
                      <Col lg={6} className="text-right">
                        <Link
                          className="btn btn-primary"
                          to="/survey-distribution/new"
                        >
                          New Distribution
                        </Link>
                      </Col>
      </Row>
      <LoadingOverlay
        active={loading}
        spinner={<BounceLoader />}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(91, 134, 96, 0.5)",
          }),
          content: (base) => ({
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }),
        }}
        text="Loading...">
        <>
          <Row>
            <Col lg={12}>
              <ToolkitProvider
                keyField="id"
                data={distributions}
                columns={columns}
              >
                {(props) => (
                  <>
                    <Row>
                      <Col lg={12}>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={ filterFactory() }
                          pagination={paginationFactory()}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        </>
      </LoadingOverlay>
    </Container>
  );
}
