import React from "react";

export default function Home() {

    return (
        <div>
            <h2>Home</h2>
        </div>
    );
}
