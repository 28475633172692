import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import { Helmet } from 'react-helmet'
import Home from "./components/Home";
import Login from "./components/Login";
import Logout from "./components/Logout";
import AzureAdCallback from "components/AzureAd/AzureAdCallback";
import QuestionList from "./components/survey_definition/question/QuestionList";
import QuestionFormTabs from "./components/survey_definition/question/question_form_tabs";
import PrintView from "./components/survey_definition/survey/PrintView";
import SurveyBuilder2 from "./components/survey_definition/survey/SurveyBuilder2";
import SurveyForm from "./components/survey_definition/survey/SurveyForm";
import SurveyList from "./components/survey_definition/survey/SurveyList";
import NewClient from "./components/client_portal/NewClient";
import EditClient from "./components/client_portal/EditClient";
import ListClients from "./components/client_portal/ListClients";
import ClientUserForm from "./components/client_portal/client_users/ClientUserForm";
import ClientUserList from "./components/client_portal/client_users/ClientUserList";
import DownloadQualtricsResponses from "./components/survey_response/downloadQualtricsResponses";
import SurveysResponses from "./components/survey_response/surveysResponses";
import DataWarehouseUpdate from "components/survey_response/data_warehouse";
import SurveyDistribution from "./components/survey_distribution/distributionList";
import NewDistribution from "./components/survey_distribution/newDistribution";
import SelectionPlan from "./components/survey_distribution/selection_plan/selectionPlan";
import ContactPlan from "./components/survey_distribution/contact_plan/contactPlan";
import SelectionList from "./components/survey_distribution/selectionList";
import ContactList from "./components/survey_distribution/contactList";
import MessageList from "./components/survey_distribution/messageList";

import User from "../src/models/user";
import '../src/App.css';
import Logo from "../src/images/new_logo.jpg"
import MessagePlan from "components/survey_distribution/message_plan/messagePlan";

export default function App() {
  return (
    <Router>
      <Helmet><title>StayMetrics Admin</title></Helmet>
      <Navbar expand="lg">
        <Navbar.Brand href="#home"><img className="logo" src={Logo} alt="logo"></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
        <Nav.Link href="/survey-definition/question">Questions Database</Nav.Link>
        <NavDropdown title="Distribution" id="basic-nav-dropdown">
              <NavDropdown.Item href="/survey-distribution">Distribution Plans</NavDropdown.Item>
              <NavDropdown.Item href="/survey-distribution/selections">Selection Plans</NavDropdown.Item>
              <NavDropdown.Item href="/survey-distribution/contacts">Contact Plans</NavDropdown.Item>
              <NavDropdown.Item href="/survey-distribution/messages ">Message Plans</NavDropdown.Item>
            </NavDropdown>
        <NavDropdown title="Surveys" id="basic-nav-dropdown">
              <NavDropdown.Item href="/survey-definition/survey">Survey List</NavDropdown.Item>
              <NavDropdown.Item href="/survey-responses/qualtrics/surveys">Responses</NavDropdown.Item>
              <NavDropdown.Item href="/survey-responses/qualtrics/download">Downloads</NavDropdown.Item>
              <NavDropdown.Item href="/survey-responses/datawarehouse">Data Warehouse Update</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Client Portal" id="basic-nav-dropdown">
              <NavDropdown.Item href="/client-portal/clients/list">Clients</NavDropdown.Item>
              <NavDropdown.Item href="/client-portal/client-users">Client Users</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/logout">Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <Route exact path={'/azuread/callback'} component={AzureAdCallback} />
          <Route exact path={"/login"} component={Login} />
          <Route exact path={"/logout"} component={Logout} />
          <PrivateRoute exact path="/survey-definition/question" component={QuestionList} />
          <PrivateRoute exact path="/survey-definition/survey" component={SurveyList} />
          <PrivateRoute exact path="/survey-definition/survey/new" component={SurveyForm} />
          <PrivateRoute exact path="/survey-definition/question/new" component={QuestionFormTabs} />
          <PrivateRoute path="/survey-definition/survey/:surveyId/build" component={SurveyBuilder2} />
          <PrivateRoute path="/survey-definition/survey/:surveyId/print" component={PrintView} />
          <PrivateRoute path="/survey-definition/survey/:surveyId" component={SurveyForm} />
          <PrivateRoute path="/survey-definition/question/:questionId" component={QuestionFormTabs} />
          <PrivateRoute exact path="/survey-responses/qualtrics/surveys" component={SurveysResponses} />
          <PrivateRoute exact path="/survey-responses/qualtrics/download" component={DownloadQualtricsResponses} />
          <PrivateRoute exact path="/client-portal/clients/new" component={NewClient} />
          <PrivateRoute exact path="/client-portal/clients/edit/:clientId" component={EditClient} />
          <PrivateRoute exact path="/client-portal/clients/list" component={ListClients} />
          <PrivateRoute exact path="/survey-responses/datawarehouse" component={DataWarehouseUpdate} />
          
          <PrivateRoute exact path="/survey-distribution" component={SurveyDistribution} />
          <PrivateRoute path="/survey-distribution/new" component={NewDistribution} />
          <PrivateRoute path="/survey-distribution/edit/:distributionPlanId" component={NewDistribution} />

          <PrivateRoute exact path="/survey-distribution" component={SurveyDistribution} />

          <PrivateRoute exact path="/survey-distribution/selections" component={SelectionList} />
          <PrivateRoute exact path="/survey-distribution/selections/new" component={SelectionPlan} />
          <PrivateRoute exact path="/survey-distribution/selections/edit/:selectionPlanId" component={SelectionPlan} />

          <PrivateRoute exact path="/survey-distribution/contacts" component={ContactList} />
          <PrivateRoute exact path="/survey-distribution/contacts/new" component={ContactPlan} />
          <PrivateRoute exact path="/survey-distribution/contacts/edit/:contactPlanId" component={ContactPlan} />
                          
          <PrivateRoute exact path="/survey-distribution/messages" component={MessageList} />
          <PrivateRoute exact path="/survey-distribution/messages/new" component={MessagePlan} />
          <PrivateRoute exact path="/survey-distribution/messages/edit/:messagePlanId" component={MessagePlan} />

         
          <Route exact path="/client-portal/client-users" component={ClientUserList} />
          <Route path="/client-portal/client-users/:clientUserId" component={ClientUserForm} />

        </Switch>
      </div>
    </Router>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {

  if(!User.isAuthorized() ) {
    User.removeStorageUser();
    window.location.href = '/login';
  }

  return (
    User.isAuthorized()
    ? <Route {...rest} render={(props) => (
      <Component {...props} currentuser={new User()} />
    )} />
    : <Redirect to='/login' />
  );
};
