import SurveyDistributionService from "services/survey_distribution/surveryDistributionService";
import SurveyManager from "managers/survey_definition/surveyManager";
import moment from 'moment'
import {SurveyDistribution} from "models/survey_distribution/surveyDistributions";

export default class SurveyDistributionManager {
  private surveyDistributionService: SurveyDistributionService;
  private surveyManager: SurveyManager;

  constructor(jwt: string) {
    this.surveyDistributionService = new SurveyDistributionService(jwt);
    this.surveyManager = new SurveyManager(jwt);
  }
  getMessagePlansById(id: number): Promise<SurveyDistribution> {
    return this.surveyDistributionService.getMessagePlansById(id).then((apiSurvey) => {
      return apiSurvey;
    });
  }

 async getSelectionPlansById(id: number): Promise<SurveyDistribution> {
    let api = await this.surveyDistributionService.getSelectionPlansById(id);
    let criteria = await this.getSelectionsCriteria();

    await Promise.all(api.criteria.map(async item =>{
      let component = criteria.find(c => c.variable === item.type);
        item.component = component;
     
      //we need to get the labels for the values, dispatcher id's, recruiter id's, etc.
      if (item.component.valuesUrl !== null){
        let newMultipleValues = [];
        let data = await this.getValuesUrlData(item.component.valuesUrl, api.clientId);
        item.multipleValues && item.multipleValues.forEach(sd => {
            let valObj = data.find(d => parseInt(d.key) === parseInt(sd));
            if (valObj !== undefined){
              let temp = {label: valObj.value, value: sd};
              newMultipleValues.push(temp);
            }
        });
        item.typeLabel = item.component.name;
        item.multipleValuesLabels = newMultipleValues;
      }
      else{
        let newMultipleValues = [];
        item.multipleValues && item.multipleValues.forEach(sd => {
          let temp = {label: sd, value: sd};
          newMultipleValues.push(temp);
        });
        item.multipleValuesLabels = newMultipleValues;
        item.typeLabel = item.component.name;
      }
    }));
    return api;
  }

  updateSelectionPlans(id, plan): Promise<any> {
    return this.surveyDistributionService. updateSelectionPlans(id, plan).then((plans) => {
      return plans;
    });
  }
  saveSelectionPlans(plan): Promise<any> {
    return this.surveyDistributionService.saveSelectionPlans(plan).then((plans) => {
      return plans;
    });
  }

  getSelectionPlans(): Promise<any> {
    return this.surveyDistributionService.getSelectionPlans().then((plans) => {
      return plans;
    });
  }

  async getSelectionPlansUsage(id): Promise<any> {

    let usagePromise = this.surveyDistributionService.getSelectionPlansUsage(id);
    let surveysPromise = this.surveyManager.getSurveys();
    
    return await Promise.all([usagePromise, surveysPromise]).then((values)=>{
      let usage = values[0];
      let surveys = values[1];

      usage?.forEach((plan)=>{
        plan.surveyDetails = surveys.find(item => item.id === plan.clientSurveyId);
        plan.surveyDetails.endDate = moment( plan.surveyDetails.endDate).format('LL') ;

      });

      return usage;
    });
  }

  getContactPlansById(id: number): Promise<any> {
    return this.surveyDistributionService.getContactPlansById(id).then((apiSurvey) => {
      return apiSurvey;
    });
  }


  getContactPlanMethods(): Promise<any> {
    return this.surveyDistributionService.getContactPlanMethods().then((plans) => {
      return plans;
    });
  }
  getContactPlans(): Promise<any> {
    return this.surveyDistributionService.getContactPlans().then((plans) => {
      return plans;
    });
  }

  async getContactPlansUsage(id): Promise<any> {
    let usagePromise = this.surveyDistributionService.getContactPlansUsage(id);
    let surveysPromise = this.surveyManager.getSurveys();
    
    return await Promise.all([usagePromise, surveysPromise]).then((values)=>{
      let usage = values[0];
      let surveys = values[1];

      usage?.forEach((plan)=>{
        plan.surveyDetails = surveys.find(item => item.id === plan.clientSurveyId);
        plan.surveyDetails.endDate = moment( plan.surveyDetails.endDate).format('LL') ;

      });

      return usage;
    });
  }
  saveMessagePlans(plan): Promise<any> {
    return this.surveyDistributionService.saveMessagePlans(plan).then((plans) => {
      return plans;
    });
  }

  updateMessagePlans(id, plan): Promise<any> {
    return this.surveyDistributionService.updateMessagePlans(id, plan).then((plans) => {
      return plans;
    });
  }

  getMessagePlans(): Promise<any> {
    return this.surveyDistributionService.getMessagePlans().then((plans) => {
      return plans;
    });
  }

  async getMessagePlansUsage(id): Promise<any> {
   let usagePromise = this.surveyDistributionService.getMessagePlansUsage(id);
    let surveysPromise = this.surveyManager.getSurveys();
    
    return await Promise.all([usagePromise, surveysPromise]).then((values)=>{
      let usage = values[0];
      let surveys = values[1];

      usage?.forEach((plan)=>{
        plan.surveyDetails = surveys.find(item => item.id === plan.clientSurveyId);
        plan.surveyDetails.endDate = moment( plan.surveyDetails.endDate).format('LL') ;

      });

      return usage;
    });
  }
  saveContactPlans(plan): Promise<any> {
    return this.surveyDistributionService.saveContactPlans(plan).then((plans) => {
      return plans;
    });
  }
  updateContactPlans(id, plan): Promise<any> {
    return this.surveyDistributionService.updateContactPlans(id, plan).then((plans) => {
      return plans;
    });
  }

  getSelectionsCriteria(): Promise<any> {
    return this.surveyDistributionService.getSelectionCriteria().then((plans) => {
      return plans;
    });
  }
  getValuesUrlData(url: string, clientId: string): Promise<any> {
    return this.surveyDistributionService.getValuesUrlData(url, clientId).then((data) => {
      return data;
    });
  }

  saveDistribution(plan): Promise<any> {
    return this.surveyDistributionService.saveDistribution(plan).then((plans) => {
      return plans;
    });
  }
  updateDistribution(id, plan): Promise<any> {
    return this.surveyDistributionService. updateDistribution(id, plan).then((plans) => {
      return plans;
    });
  }
  getDistributionsById(id: number): Promise<any> {
    return this.surveyDistributionService.getDistributionsById(id).then((api) => {
      return api;
    });
  }

  async getDistributions(): Promise<any> {

    const surveys = await this.surveyManager.getSurveys();
    const select = await this.getSelectionPlans();
    const contact = await this.getContactPlans();
    const message = await this.getMessagePlans();
    const distributions = await this.surveyDistributionService.getDistributions();

    const distro = [];
    return Promise.all([surveys,select,contact, message,distributions]).then((values)=>{
        let surveyList = values[0];
        let selectionList = values[1];
        let contactList = values[2];
        let messageList = values[3];
        let distributionList = values[4];

       
        // console.log(" messageList", messageList);
        // console.log("contactList", contactList);
        // console.log("selectionList", selectionList);
        // console.log("distributionList", distributionList);
        // console.log("surveyList", surveyList);

        let distro = [];

        distributionList.forEach(item => {
          let tempObject = {} as any;
          tempObject.id = item.id;
          
          let open = moment(item.openDate);
          let close = moment(item.closeDate);

          if ( open.isValid() && close.isValid() ){
            tempObject.openDate = open.format('LL');
            tempObject.closeDate = close.format('LL');
          }
          else{
            tempObject.openDate = null;
            tempObject.closeDate = null;
          }


          tempObject.isActive = item.isActive;
          tempObject.eligibilityDays = item.eligibilityDays;
          tempObject.surveyName = surveyList.find(x => x.id === item.clientSurveyId).title;
          tempObject.selectionPlanName = selectionList.find(x => x.id === item.selectionPlanIds[0]).name;
          tempObject.contactPlanName = contactList.find(x => x.id === item.contactPlans[0].contactPlanId).name;
          tempObject.messagePlanName = messageList.find(x => x.id === item.contactPlans[0]. messageTemplateId).name;
          distro.push(tempObject);
       });

       return distro;
    });

  }
}
