import React, {FC} from "react";
import {Container, Col, Row} from "react-bootstrap";
import Select, { createFilter } from 'react-select';

interface ISettingsPane {
  store: any;
  dispatch: DispatchCallback;
}

export interface IOptionPane {
  day: 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';
  clientInfo: any;
  dispatch: DispatchCallback;
}

const SmsSettingsPane : FC<ISettingsPane> = (props) => {
  return <Container>
    <TimeSettingPane day={'Monday'} clientInfo={props.store.clientInfo} dispatch={props.dispatch} />
    <TimeSettingPane day={'Tuesday'} clientInfo={props.store.clientInfo} dispatch={props.dispatch} />
    <TimeSettingPane day={'Wednesday'} clientInfo={props.store.clientInfo} dispatch={props.dispatch} />
    <TimeSettingPane day={'Thursday'} clientInfo={props.store.clientInfo} dispatch={props.dispatch} />
    <TimeSettingPane day={'Friday'} clientInfo={props.store.clientInfo} dispatch={props.dispatch} />
    <TimeSettingPane day={'Saturday'} clientInfo={props.store.clientInfo} dispatch={props.dispatch} />
    <TimeSettingPane day={'Sunday'} clientInfo={props.store.clientInfo} dispatch={props.dispatch} />
  </Container>;
};

const TimeSettingPane: FC<IOptionPane> = (props) => {
  const hoursOfDay = [
    {label: "OFF", value: null},
    {label: "12 AM", value: "00:00"}, {label: "12:30 AM", value: "12:30"},
    {label: "1 AM", value:"01:00"}, {label: "1:30 AM", value:"01:30"},
    {label: "2 AM", value:"02:00"}, {label: "2:30 AM", value:"02:30"},
    {label: "3 AM", value:"03:00"}, {label: "3:30 AM", value:"03:30"},
    {label: "4 AM", value:"04:00"}, {label: "4:30 AM", value:"04:30"},
    {label: "5 AM", value:"05:00"}, {label: "5:30 AM", value:"05:30"},
    {label: "6 AM", value:"06:00"}, {label: "6:30 AM", value:"06:30"},
    {label: "7 AM", value:"07:00"}, {label: "7:30 AM", value:"07:30"},
    {label: "8 AM", value:"08:00"}, {label: "8:30 AM", value:"08:30"},
    {label: "9 AM", value:"09:00"}, {label: "9:30 AM", value:"09:30"},
    {label: "10 AM", value:"10:00"}, {label: "10:30 AM", value:"10:30"},
    {label: "11 AM", value:"11:00"}, {label: "11:30 AM", value:"11:30"},
    {label: "12 PM", value:"12:00"}, {label: "12:30 PM", value:"12:30"},
    {label: "1 PM", value:"13:00"}, {label: "1:30 PM", value:"13:30"},
    {label: "2 PM", value:"14:00"}, {label: "2:30 PM", value:"14:30"},
    {label: "3 PM", value:"15:00"}, {label: "3:30 PM", value:"15:30"},
    {label: "4 PM", value:"16:00"}, {label: "4:30 PM", value:"16:30"},
    {label: "5 PM", value:"17:00"}, {label: "5:30 PM", value:"17:30"},
    {label: "6 PM", value:"18:00"}, {label: "6:30 PM", value:"18:30"},
    {label: "7 PM", value:"19:00"}, {label: "7:30 PM", value:"19:30"},
    {label: "8 PM", value:"20:00"}, {label: "8:30 PM", value:"20:30"},
    {label: "9 PM", value:"21:00"}, {label: "9:30 PM", value:"21:30"},
    {label: "10 PM", value:"22:00"}, {label: "10:30 PM", value:"22:30"},
    {label: "11 PM", value:"23:00"}, {label: "11:30 PM", value:"23:30"},
  ];
  const filterConfig = {
    stringify: option => {
      return `${option.label} ${option.value} ${option.value?.replace(":", "")}`;
    }
  };
  return <Row>
    <Col lg={4} className="text-right">SMS Hours: {props.day}</Col>
    <Col lg={4}>
      <Select
        options={hoursOfDay}
        value={hoursOfDay.find(value => value.value === props.clientInfo[`smsHoursStart${props.day}`])}
        onChange={selectedTime=> props.dispatch({type: 'setData', field: `smsHoursStart${props.day}`, payload: selectedTime.value})}
        isMulti={false}
        captureMenuScroll={false}
        filterOption={createFilter(filterConfig)}
      />
    </Col>
    <Col lg={4}>
      <Select
        options={hoursOfDay}
        value={hoursOfDay.find(value => value.value === props.clientInfo[`smsHoursStop${props.day}`])}
        onChange={selectedTime => props.dispatch({type: 'setData', field: `smsHoursStop${props.day}`, payload: selectedTime.value})}
        isMulti={false}
        captureMenuScroll={false}
        filterOption={createFilter(filterConfig)}
      />
    </Col>
  </Row>
};

export default SmsSettingsPane;
