import React from "react";
const axios = require('axios');

export default function Login() {

  const handleLogin = () => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BASEURL + "api/v2/auth/azuread/start",
      headers: {
          "Content-Type": "application/json-patch+json",
          "accept": "text/plain"
      }
    })
    .then(function (token) {
        window.location.href = token.data;
    })
    .catch(function (error){
        console.log(error);
    });
  };

  return (
  <div>
    Login page
    <br/>
    <button onClick={handleLogin}>Login</button>
  </div>);
}
