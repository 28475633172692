import QuestionService from "services/survey_definition/question";
import { ChangedFlag } from "models/changedFlag";
import { SurveyItem, SurveyItemReporting } from "models/surveyItem";
import { ItemTypeBySurveyType as ItemTypeBySurveyTypeForm, Question, ReportingData } from "./models";
import ItemTypeBySurveyType from "models/itemTypeBySurveyType";
import { SurveyItemConstruct } from "models/surveyItemConstruct";
import { QuestionState, QuestionReducerAction } from "managers/survey_definition/questionState";
import QuestionValidator from "./questionValidator";

export default class QuestionManager {
  private readonly questionService: QuestionService;

  constructor(jwt: string) {
    this.questionService = new QuestionService(jwt);
  }

  // TODO: This does not belong here in the long run (or the short one either. Just for now)
  // Narrator: This will be here for a looooooonnnnnngggggggg time
  static blankModel(): Question {
    return {
      id: 0,
      questionNumber: "",
      notes: "",
      itemTypeId: 0,
      responseSet: 0,
      matrixType: 1,
      matrixItems: [],
      supportingDataId: "",
      wordings: [],
      mass_choice_change: false,
      deleted_wordings: [],
      responseSetTemplateId: 0,
      responseSetTemplateDescription: "",
      choices: [],
      constructName: "",
      ratingType: 0,
      includesNa: false,  //no longer needed, but don't want to remove unless api is updated. -TE
      reverseCoded: false,
      ignoreZeroBlank: false, //no longer needed, but don't want to remove unless api is updated. -TE
      itemTypesBySurveyTypes: [
      ],
    } as Question;
  }

  convertApiToForm(api: SurveyItem, form: Question): Question {
    form.id = api.id;
    form.questionNumber = api.questionNumber;
    form.notes = api.notes || "";
    form.itemTypeId = api.itemTypeId;
    form.responseSet = api.responseSetTypeId;
    form.responseSetTemplateDescription = api.responseSetDescription;
    api.itemTypesBySurveyType.forEach((x: ItemTypeBySurveyType) => {
      form.itemTypesBySurveyTypes.push({
        isActive: x.isActive,
        name: x.name,
        surveyTypeId: x.surveyTypeId,
        itemTypeId: x.itemTypeId,
        itemTypeSurveyItemSurveyTypeId: x.itemTypeSurveyItemSurveyTypeId,
        changedFlag: x.changedFlag,
        group: x.group,
      });
    });

    if (api.matrix != null) {
      switch (api.matrix.type) {
        case "standard":
          form.matrixType = 2;
          break;
        case "supportingData":
          form.matrixType = 3;
          break;
        default:
          form.matrixType = 1;
          break;
      }
    } else
      form.matrixType = 1;


    form.matrixItems = [];
    form.supportingDataId = "";

    if (form.matrixType === 2) {
      api.matrix.values.forEach((x: any) => {

        form.matrixItems.push({
          id: x.id,
          sort: x.position,
          text: x.text
        })
      });
    } else if (form.matrixType === 3) {
      form.supportingDataId = api.matrix.supportingDataId;
    }

    form.wordings = [];
    api.wordings.forEach((wording: any) => {
      form.wordings.push({
        id: wording.id,
        wording: wording.text,
        default: wording.default,
        changedFlag: ChangedFlag.NoChange
      })
    });

    form.mass_choice_change = false;

    form.choices = [];
    api.choices.forEach((choice: any) => {
      form.choices.push({
        id: choice.id,
        text: choice.text,
        value: choice.value,
        position: choice.position,
        changedFlag: ChangedFlag.NoChange
      });
    });

    form.ratingType = api.ratingType;
    form.includesNa = api.includesNa;
    form.reverseCoded = api.reverseCoded;
    form.ignoreZeroBlank = api.ignoreZeroBlank;

    return form;
  }

  convertFormToApi(question: Question, originalQuestion: any): SurveyItem {
    let api: SurveyItem = {
      id: question.id,
      responseSetDescription: question.responseSetTemplateDescription,
      questionNumber: question.questionNumber,
      notes: question.notes || "",
      itemTypeId: question.itemTypeId,
      responseSetTypeId: question.responseSet,
      ratingType: question.ratingType,
      includesNa: question.includesNa,
      reverseCoded: question.reverseCoded,
      ignoreZeroBlank: question.ignoreZeroBlank,
      matrix: {
        id: 0,
        type: "",
        supportingDataId: "",
        changedFlag: ChangedFlag.NoChange,
        values: []
      },
      wordings: [],
      choices: [],
      isMatrix: false,
      approvalStatusId: 1,
      itemTypesBySurveyType: [],
    };

    switch (parseInt(question.matrixType.toString())) {
      case 2:
        api.isMatrix = true;
        api.matrix.type = "standard";

        question.matrixItems.forEach((x: any) => {
          api.matrix.values.push({
            id: 0,
            text: x.text,
            position: x.sort,
            changedFlag: ChangedFlag.Unknown
          });
        });
        break;
      case 3:
        api.isMatrix = true;
        api.matrix.type = "supportingData";
        api.matrix.supportingDataId = question.supportingDataId;
        break;
      default:
        api.matrix.type = "";
    }

    if (question.deleted_wordings.length > 0) {
      question.deleted_wordings.forEach((x: any) => {
        api.wordings.push({
          id: x.id,
          text: x.wording,
          default: x.default,
          changedFlag: ChangedFlag.Deleted,
        });
      });
    }

    question.wordings.forEach((x: any) => {
      api.wordings.push({
        id: x.id,
        text: x.wording,
        default: x.default,
        changedFlag: x.changedFlag,
      });
    });

    // Hold for when the API is not on "delete and load" everything
    // if(question.mass_choice_change) {
    //   // We need to load up all the choices from original question and put them in to delete them
    //   originalQuestion.choices.forEach(x => {
    //     api.choices.push({
    //       id: x.id,
    //       text: x.text,
    //       value: x.value,
    //       position: x.position,
    //       changedFlag: ChangedFlag.Deleted
    //     });
    //   });
    // }

    question.choices.forEach((x: any) => {
      api.choices.push({
        surveyItemId: 0,
        id: x.id,
        text: x.text,
        value: x.value,
        position: x.position,
        changedFlag: x.changedFlag
      });
    });
    question.itemTypesBySurveyTypes.forEach((x: ItemTypeBySurveyTypeForm) => {
      api.itemTypesBySurveyType.push({
        changedFlag: x.changedFlag,
        isActive: x.isActive,
        name: x.name,
        surveyTypeId: x.surveyTypeId,
        itemTypeId: x.itemTypeId,
        itemTypeSurveyItemSurveyTypeId: x.itemTypeSurveyItemSurveyTypeId,
        group: x.group,
      });
    });
    return api;
  }

  getById(questionId: string): Promise<Question> {
    let form = QuestionManager.blankModel();
    return this.questionService.getById(questionId)
      .then((question) => {
        return this.convertApiToForm(question, form);
      });
  }

  insert(question: Question): Promise<boolean> {
    let api = this.convertFormToApi(question, {});
    return this.questionService.insert(api)
      .then(result => result);
  }

  update(questionId: string, question: Question, originalQuestion: any): Promise<boolean> {
    let api = this.convertFormToApi(question, originalQuestion);
    return this.questionService.update(api)
      .then(result => result);
  }

  getQuestions(): Promise<SurveyItem[]> {
    return this.questionService.list()
      .then(apiQuestions => {
        return apiQuestions;
      });
  }

  getReportingById(questionId: string): Promise<ReportingData> {
    return this.questionService.getReportById(questionId)
      .then(x => {
        return {
          variable_name: x.variableName,
          report_category: x.reportCategory,
          constructName: x.constructName
        };
      });
  }

  updatedReporting(id: string, values: ReportingData): Promise<boolean> {
    let converted: SurveyItemReporting = {
      variableName: values.variable_name,
      reportCategory: values.report_category,
      constructName: values.constructName
    };

    return this.questionService.updateReporting(id, converted);
  }

  getConstructsList(): Promise<SurveyItemConstruct[]> {
    return this.questionService.getConstructsList()
      .then(apiQuestion => {
        return apiQuestion.sort((a, b) => (a.name > b.name) ? 1 : -1);
      });
  }

  manageQuestionState(state: QuestionState, action: QuestionReducerAction): QuestionState {
    if (action === "loading") return { ...state, isLoading: true };
    if (action === "ready") return { ...state, isLoading: false, isSubmitting: false };
    if (action === "submitting") return { ...state, isSubmitting: true };
    switch (action.type) {
      case "error": return { ...state, errors: { ...state.errors, [action.field]: action.message } };
      case "setQuestion": return { ...state, question: action.payload, originalQuestion: action.payload };
      case "setReportingData": return { ...state, reportingData: action.payload, originalReportingData: action.payload };
      case "setSurveyConstructs": return { ...state, constructs: action.payload };
      case "setSurveyItemDefinition": return { ...state, surveyItemDefinition: action.payload };
      case "setSupportingDatums": return { ...state, supportingDatums: action.payload };
      case "setQuestionField":
        let question = { ...state.question, [action.field]: action.payload };
        let errors = { ...state.errors };
        try {
          QuestionValidator.validateQuestionField(action.field, question);
          delete errors[action.field];
        }
        catch (e) {
          errors = { ...errors, [e.path]: e.message };
        }
        let actuallyDifferent = question[action.field] !== state.originalQuestion[action.field];
        return { ...state, question: question, touched: { ...state.touched, [action.field]: actuallyDifferent }, errors: errors };
      case "setReportingDataField":
        let reportingData = { ...state.reportingData, [action.field]: action.payload };
        let actuallyDifferentRDF = reportingData[action.field] !== state.originalReportingData[action.field];

        return { ...state, reportingData: reportingData, touched: { ...state.touched, [action.field]: actuallyDifferentRDF } };
      default: return state;
    }
  }
}
