import React from "react";
import {Form} from "react-bootstrap";
import {ChangedFlag} from "models/changedFlag";
import {QuestionReducerContext} from "../../questionReducerContext";

const MultipleChoiceResponseSetType = (props) => {
  const {store, dispatch} = React.useContext(QuestionReducerContext);
  const types=store.surveyItemDefinition.responseSetTypes;
  const style = { display: props.display ? 'block' : 'none' };

  let templates = types.filter(x => x.id === store.question.responseSet);

  if(templates.length > 0) {
    templates = templates[0].templates;
  } else {
    templates = [];
  }

  const handleChange = (event) => {
    // Set the choices in the form to be the choices from the template
    let newChoices = templates.filter(x => x.id === parseInt(event.target.value));

    if (typeof(newChoices[0]) !== 'undefined') {
      newChoices = newChoices[0].choices;
    }

    let newChoiceObj = [];
    newChoices.forEach(x => newChoiceObj.push({
      "id": 0,
      "text": x.text,
      "value": x.value,
      "position": x.position,
      "changedFlag": ChangedFlag.New
    }));

    let  index = event.nativeEvent.target.selectedIndex;
    dispatch({type:'setQuestionField', field:'responseSetTemplateId', payload: event.nativeEvent.target[index].value})
    dispatch({type:'setQuestionField', field:'responseSetTemplateDescription', payload: event.nativeEvent.target[index].text})
    dispatch({type:'setQuestionField', field:'mass_choice_change', payload: true})
    dispatch({type:'setQuestionField', field:'choices', payload: newChoiceObj})
  };

  return (
    <Form.Group style={style}>
      <Form.Label>Template: </Form.Label>
      <Form.Control as='select'
        name={"responseSetDescription"}
        isInvalid={store.errors.choices}
        onChange={handleChange}>
        <option value={0}>Select</option>
        {
          templates && templates.map((template, index) => {
          return <option key={index} value={template.id}>{template.description}</option>
          })
        }
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {store.errors.choices}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default MultipleChoiceResponseSetType;
