const states = [
    {label:"Select",value:""},
    {label:"Alabama",value:"AL"},
    {label:"Alaska",value:"AK"},
    {label:"Alberta",value:"AB"},
    {label:"American Samoa",value:"AS"},
    {label:"Arizona",value:"AZ"},
    {label:"Arkansas",value:"AR"},
    {label:"Armed Forces (AE)",value:"AE"},
    {label:"Armed Forces Americas",value:"AA"},
    {label:"Armed Forces Pacific",value:"AP"},
    {label:"British Columbia",value:"BC"},
    {label:"California",value:"CA"},
    {label:"Colorado",value:"CO"},
    {label:"Connecticut",value:"CT"},
    {label:"Delaware",value:"DE"},
    {label:"District Of Columbia",value:"DC"},
    {label:"Florida",value:"FL"},
    {label:"Georgia",value:"GA"},
    {label:"Guam",value:"GU"},
    {label:"Hawaii",value:"HI"},
    {label:"Idaho",value:"ID"},
    {label:"Illinois",value:"IL"},
    {label:"Indiana",value:"IN"},
    {label:"Iowa",value:"IA"},
    {label:"Kansas",value:"KS"},
    {label:"Kentucky",value:"KY"},
    {label:"Louisiana",value:"LA"},
    {label:"Maine",value:"ME"},
    {label:"Manitoba",value:"MB"},
    {label:"Maryland",value:"MD"},
    {label:"Massachusetts",value:"MA"},
    {label:"Michigan",value:"MI"},
    {label:"Minnesota",value:"MN"},
    {label:"Mississippi",value:"MS"},
    {label:"Missouri",value:"MO"},
    {label:"Montana",value:"MT"},
    {label:"Nebraska",value:"NE"},
    {label:"Nevada",value:"NV"},
    {label:"New Brunswick",value:"NB"},
    {label:"New Hampshire",value:"NH"},
    {label:"New Jersey",value:"NJ"},
    {label:"New Mexico",value:"NM"},
    {label:"New York",value:"NY"},
    {label:"Newfoundland",value:"NF"},
    {label:"North Carolina",value:"NC"},
    {label:"North Dakota",value:"ND"},
    {label:"Northwest Territories",value:"NT"},
    {label:"Nova Scotia",value:"NS"},
    {label:"Nunavut",value:"NU"},
    {label:"Ohio",value:"OH"},
    {label:"Oklahoma",value:"OK"},
    {label:"Ontario",value:"ON"},
    {label:"Oregon",value:"OR"},
    {label:"Pennsylvania",value:"PA"},
    {label:"Prince Edward Island",value:"PE"},
    {label:"Puerto Rico",value:"PR"},
    {label:"Quebec",value:"PQ"},
    {label:"Rhode Island",value:"RI"},
    {label:"Saskatchewan",value:"SK"},
    {label:"South Carolina",value:"SC"},
    {label:"South Dakota",value:"SD"},
    {label:"Tennessee",value:"TN"},
    {label:"Texas",value:"TX"},
    {label:"Utah",value:"UT"},
    {label:"Vermont",value:"VT"},
    {label:"Virgin Islands",value:"VI"},
    {label:"Virginia",value:"VA"},
    {label:"Washington",value:"WA"},
    {label:"West Virginia",value:"WV"},
    {label:"Wisconsin",value:"WI"},
    {label:"Wyoming",value:"WY"},
    {label:"Yukon Territory",value:"YT"}]

    export default states;