import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import ClientUser from "models/clientUser";

export default class ClientUserService {
  private client: AxiosInstance;

  constructor(token: string) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_BASEURL,
      headers: {
        "content-type": "application/json",
        "Authorization": "bearer " + token
      }
    };

    this.client = axios.create(config);
  }

  getById(id: number) {

    return this.client.get<ClientUser>("api/v2/client-portal/client-user/" + id)
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }

  getClientUserList() {
    return this.client.get<ClientUser[]>("api/v2/client-portal/client-user/")
      .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }


  insertClientUser(clientUser: ClientUser): Promise<any> {
    return this.client
      .post("api/v2/client-portal/client-user", clientUser)
      .then((x: any) => x)
      .catch((er: any) => console.log(er));
  }

  updateClientUser(id: string, clientUser: ClientUser): Promise<any> {
    return this.client
      .put(`api/v2/client-portal/client-user/${id}`, clientUser)
      .then((x: AxiosResponse<any>) => x)
      .catch((er: any) => console.log(er));
  }

  sendInvitation(id: number) {
    return this.client
      .post(`api/v2/client-portal/client-user/${id}/send-invitation`)
      .then((x: any) => x)
      .catch((er: any) => console.log(er));
  }

}
