import SurveyService from "services/survey_definition/survey";
import ClientService from "services/clientService";
import { getSurveyTypes } from "utils";
import { ChangedFlag } from "models/changedFlag";
import {
  ClientSurveyItem,
  ClientSurveyTemplate,
  ClientSurveyItemReporting, 
  ClientSurvey
} from "models/clientSurvey";
import { Section, SurveyTemplate, SectionItems } from "./models";
import SurveyList from "components/survey_definition/survey/SurveyList";

export default class SurveyManager {
  private surveyService: SurveyService;
  private clientService: ClientService;

  constructor(jwt: string) {
    this.surveyService = new SurveyService(jwt);
    this.clientService = new ClientService(jwt);
  }

  update(surveyId: string, survey: { data: Section[] }): Promise<any> {
    let apiSurvey = this.convertFormToApi(surveyId, survey);
    return this.surveyService.updateContent(surveyId, apiSurvey).then((x) => {
      return x;
    });
  }

  getById(surveyId: string): Promise<{ data: Section[] }> {
    return this.surveyService.getContentById(surveyId).then((apiSurvey) => {
      return this.convertApiToForm(apiSurvey.data);
    });
  }

  getSurveyInfoById(surveyId: string): Promise<{ data: ClientSurvey }> {
    return this.surveyService.getSurveyInfoById(surveyId).then((surveyInfo) => {
      return surveyInfo;
    });
  }
 

  getReportingById(id: string): Promise<ClientSurveyItemReporting> {
    return this.surveyService.getReportingById(id).then((apiSurvey) => {
      return apiSurvey.data;
    });
  }

  getSurveys(): Promise<any> {

    let surveys = this.surveyService.list().then((surveyList) => {return surveyList});
    let types = getSurveyTypes().then((surveyTypes) => {return surveyTypes});
    let clients = this.clientService.getList().then((clientList) => {return clientList});
    
    return Promise.all([surveys,types,clients]).then((values)=>{
        let surveys = values[0];
        let types = values[1];
        let clients = values[2];

        surveys.forEach((x) => {
          let startDate = new Date(x.startDate);
          let endDate = new Date(x.endDate);
          let today = new Date();
          x.effective = false;
          if(today >= startDate && today < endDate){
            x.effective = true;
          }

          x.surveyTypeText = types[x.surveyTypeId];
          if (clients.filter(client => client.id === x.clientId)[0]){
            x.clientName = clients.filter(client => client.id === x.clientId)[0].name;
          }
          else{
            x.clientName = "#Bad Data#"
          }
        });

        surveys.sort((a,b) => {
            const item1 = parseInt(a.id);
            const item2 = parseInt(b.id);
          
            let comparison = 0;
            if (item1 < item2) {
              comparison = 1;
            } else if (item1 > item2) {
              comparison = -1;
            }
            return comparison;
          });
        return surveys;
    });
  }

  getSurveyTemplates(): Promise<any> {

    let clients = this.clientService.getList().then((clientList) => {return clientList});
    let templates = this.surveyService.getSurveyTemplates().then((templates) => {return templates});

    return Promise.all([clients,templates]).then((values)=>{
      let clients = values[0];
      let templates = values[1].data;

      templates.forEach((item) => {
        if (clients.filter(client => client.id === item.clientId)[0]){
          item.clientName = clients.filter(client => client.id === item.clientId)[0].name;
        }
        else{
          item.clientName = "#Bad Data#"
        }
        item.template_name =  item.clientName + ": " + item.title + " - " + item.participantTypeText;
      });

    return templates;
    });
  }

  private convertApiToForm(apiSurvey: ClientSurveyItem[]): { data: Section[] } {
    let formSurvey = SurveyService.getBlankSurvey();

    apiSurvey.sort((a, b) => a.position - b.position);

    apiSurvey.forEach((question: ClientSurveyItem, index: number) => {
      let section = formSurvey.data.filter((x) => x.name === question.section);
      if (section.length === 0) {
        // Create the section
        formSurvey.data.push({
          name: question.section,
          changedFlag: ChangedFlag.Unknown,
          contents: [
            {
              id: question.id,
              changedFlag: ChangedFlag.Unknown,
              surveyItemId: question.surveyItemId,
              surveyItemWordingId: question.surveyItemWordingId,
              position: question.position,
              variableName: question.reporting.variableName,
              reportCategory: question.reporting.reportCategory,
            },
          ],
        });
      } else {
        section[0].contents.push({
          id: question.id,
          changedFlag: ChangedFlag.Unknown,
          surveyItemId: question.surveyItemId,
          surveyItemWordingId: question.surveyItemWordingId,
          position: question.position,
          variableName: question.reporting.variableName,
          reportCategory: question.reporting.reportCategory,
        });
      }
    });

    return formSurvey;
  }

  private convertFormToApi(
    surveyId: string,
    survey: any
  ): ClientSurveyItem[] {
    let apiSurvey: ClientSurveyItem[] = [];

    survey.forEach((section: Section, index: number) => {
      section.contents.forEach((question: SectionItems, qIndex: number) => {
        
        if (question.id === undefined){
          question.id = 0;
        }

        apiSurvey.push({
          id: question.id,
          clientSurveyId: parseInt(surveyId),
          surveyItemId: question.surveyItemId,
          surveyItemWordingId: question.surveyItemWordingId,
          section: section.name,
          position: question.position,
          changedFlag: 
            parseInt(question.id.toString()) === 0
              ? ChangedFlag.New
              : question.changedFlag,
          reporting: {
            variableName: question.variableName,
            reportCategory: question.reportCategory,
            constructName: "",
          },
        });
      });
    });
    return apiSurvey;
  }
}
