import React, {useState, useEffect} from "react";
import LoadingOverlayWrapper from "components/LoadingOverlayWrapper";
import User from "models/user";
import {Container} from "react-bootstrap";
import DataWarehouseManager from "managers/survey_response/dataWarehouseManager";
import {PipelineStatus} from "managers/survey_response/dataWarehouseModels";
import { PipelineRunState } from "./PipelineRunState";
import PipelineStateWarning from "./pipelineStateWarning";
import PipelineDataTable from "./pipelineDataTable";

const DataWarehouseUpdate: React.FC<DataWarehouseUpdateComponentParameters> = ({currentuser}) => {
  const manager = new DataWarehouseManager(currentuser.token);
  const [interval, setTimeoutInterval] = useState<NodeJS.Timer>(null);
  const [pipelineStatus, setPipelineStatus] = useState<PipelineStatus[]>([]);
  const [listPipelinesError, setListPipelinesError] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [pipelineRunState, setPipelineRunState] = useState<PipelineRunState>({variant:'info'});

  const setUp = () => {
    if(manager){
      intervalCallback();
      setTimeoutInterval(setInterval(intervalCallback, 5000));
    }
    return tearDown;
  };

  const tearDown = () => {
    clearInterval(interval);
  };

  const intervalCallback = async () => {
    if (!manager) {
      return;
    }
    setLoading(true);
    let status = await manager.listPipelineStatus();
    setPipelineStatus(status.data);
    setListPipelinesError(status.error);
    setLoading(false);
  };

  const pipelineDataTableFailure = (errorMessage: string) => {
    setPipelineRunState({variant:'danger', message: errorMessage, title:"Error"});
    setShow(true);
  };

  useEffect(setUp, []);

  return (
    <Container fluid={true}>
      <h1>Data Warehouse Update</h1>
      <LoadingOverlayWrapper loading={loading}>
        <PipelineDataTable currentuser={currentuser} listPipelinesError={listPipelinesError} pipelineStatus={pipelineStatus} onFail={pipelineDataTableFailure} />
      </LoadingOverlayWrapper>
      <PipelineStateWarning show={show} pipelineRunState={pipelineRunState} onClose={()=> setShow(false)}/>
      <p>* The status of the last run applies can only look back 2 days. If it hasn't been ran in the past 2 days then this status will be blank.</p>
      <p>Due to restrictions on our server we are limiting the number of pipelines being run at same time to 1. </p>
    </Container>
  );
};
interface DataWarehouseUpdateComponentParameters{
  currentuser: User;
}
export default DataWarehouseUpdate;
