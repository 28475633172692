import ClientUserService from "services/client_portal/clientUserService";
import ClientUser from "models/clientUser";

export default class ClientUserManager {
  private clientUserService: ClientUserService;

  constructor(token: string) {
    this.clientUserService = new ClientUserService(token);
  }

  getById(id: number): Promise<ClientUser> {
    return this.clientUserService.getById(id).then((x: ClientUser) => x)
  }

  getClientUserList(): Promise<ClientUser[]> {
    return this.clientUserService.getClientUserList()
      .then((x: ClientUser[]) => x);
  }

  insert(clientUser: ClientUser): Promise<boolean> {
    return this.clientUserService.insertClientUser(clientUser)
      .then(result => result);
  }

  update(id: string, clientUser: ClientUser): Promise<boolean> {
    return this.clientUserService.updateClientUser(id, clientUser).then((result: boolean) => result)
  }

  sendInvitation(id: number) {
    return this.clientUserService.sendInvitation(id).then((result: boolean) => result);
  }

  static blankObject(): ClientUser {
    return {
      id: 0,
      client: [""],
      clients: [],
      defaultPowerBiReportId: "",
      lastName: "",
      firstName: "",
      emailAddress: "",
      powerBiReportId: [],
      active: false
    };
  }
}
