import DefinitionService from "./services/survey_definition/definitions";
import User from "models/user";

const axios = require('axios');

export function getLastItemFromUrl() {
    return window.location.href.split('/').slice(-1)[0];
}

export function retrieveToken() {
    console.log("Warning: retrieveToken has been deprecated and will be removed in a future release");
    let user = new User();
    return user.smToken;
}

export function getSurveyTypes() {

    let token = retrieveToken();

    let definition = new DefinitionService(token);

    let x = definition.retrieve()
        .then(x => {
            let retVal = [];
            x.surveyItem.surveyTypes.forEach(y => retVal[y.id] = y.name)
            return retVal;
        });

    return x;

}

/**
 * Gets the Linux epoch for the given date object
 * @param {Date} datetime 
 */
 export function linuxEpoch(datetime) {
    return Math.round(datetime.getTime() / 1000);
}

/** 
  * Gets the Linux epoch for the current time
  */
export function currentLinuxEpoch() {
    return Math.round(Date.now() / 1000);
}

/**
 * Parses a query string into a class with all of its variables
 * @param {string} queryString The query string to parse
 */
export function parseQueryString(queryString) {
    
    let variables = {};

    if(queryString.substring(0, 1) === "#") {
        queryString = queryString.substring(1, queryString.length);
    }

    queryString.split('&').forEach(variableString => {
        let variableSplit = variableString.split('=');

        variables[variableSplit[0]] = variableSplit[1]
    });

    return variables;
}