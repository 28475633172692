import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {CreateRunResponse, ListPipelinesResponse, PipelineStatusEntity} from "models/surveyResponse/dataWarehouse";

export default class DataWarehouseService{
  private client: AxiosInstance;
  constructor(token: string) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_BASEURL + 'api/v2/survey-response/data-factory-pipeline',
      headers: {
        "content-type": "application/json",
        "Authorization": "bearer " + token
      }
    };
    this.client = axios.create(config);
  }

  async callCreateRun(id: number): Promise<CreateRunResponse> {
    try {
      await this.client.put(`/${id}/run`);
      return {isSuccess: true};
    }
    catch (e) {
      return {isSuccess: false, error: e.response.data.message};
    }
  }

  async listPipelineStatus(): Promise<ListPipelinesResponse> {
    try{
      let response = await this.client.get<PipelineStatusEntity[]>('/');
      return {data: response.data};
    }
    catch (e) {
      let message = e.message;
      if(e.response && e.response.data && e.response.data.message && e.response.data.message.length > 0){
        message = e.response.data.message;
      }
      return {data: [], error: message};
    }
  }
}
