import {ItemTypesBySurveyTypesForm} from "./itemTypesBySurveyTypesForm";
import React, {FC} from "react";
import "components/survey_definition/question/question_form/item_type/itemType.css";
import {QuestionReducerContext} from "components/survey_definition/question/questionReducerContext";

const ItemTypeForm : FC<ItemTypeFormParameters>= (props: ItemTypeFormParameters) => {
  const {store, dispatch} = React.useContext(QuestionReducerContext);
  const handleItemTypeSelected = (itemTypeId: number, index: number) => {
    store.question.itemTypesBySurveyTypes[index].itemTypeId = itemTypeId;
    dispatch({
      type:"setQuestionField",
      field:"itemTypesBySurveyTypes",
      payload:store.question.itemTypesBySurveyTypes
    });

  };
  return (
    <>
      <legend className="w-auto">Question Types</legend>
      <ItemTypesBySurveyTypesForm
        itemTypes={store.surveyItemDefinition.itemTypes}
        itemTypesBySurveyTypes={store.question.itemTypesBySurveyTypes}
        onItemTypeChanged={handleItemTypeSelected}
      />
    </>
  );
};
export type ItemTypeSelectedCallback = (itemTypeId: number, index: number) => void;
interface ItemTypeFormParameters {
}

export {ItemTypeForm};
