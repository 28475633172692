import * as Yup from 'yup';

export default class QuestionValidator{
  private static readonly QuestionSchema = Yup.object().shape({
    questionNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),

    wordings: Yup.array()
      .min(1, 'At least 1 is required'),

    responseSet: Yup.number()
      .required('Required')
      .positive('Required'),

    choices: Yup.array()
      .when("responseSet",
        {
          is: 2,
          then: Yup.array().min(2, 'You need at least 2 options to have a choice')
        })
      .when("responseSet",
        {
          is: 3,
          then: Yup.array().min(2, 'You need at least 2 options to have a choice')
        }),

    matrixType: Yup.number()
      .required('Required')
      .positive('Required'),

    matrixItems: Yup.array()
      .when("matrixType",
        {
          is: 2,
          then: Yup.array().min(1, 'You need at least 1 matrix item')
        }),

    supportingDataId: Yup.number()
      .when("matrixType", {
        is: 3,
        then: Yup.number().required('Required')
      }),
  });

  static validateQuestionField(field:string, value: any): any{
    return QuestionValidator.QuestionSchema.validateSyncAt(field, value);
  }
}

