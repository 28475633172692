import React from "react";
import { Alert } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import DataWarehouseManager from "managers/survey_response/dataWarehouseManager";
import { PipelineStatus } from "managers/survey_response/dataWarehouseModels";
import PipelineRunButton from "./pipelineRunButton";
import User from "models/user";

interface PipelineDataTableParameters{
    currentuser: User;
    listPipelinesError: string;
    pipelineStatus: PipelineStatus[];
    onFail(errorMessage: string);
}

const PipelineDataTable : React.FC<PipelineDataTableParameters> = ({currentuser, listPipelinesError, pipelineStatus, onFail}) => {
const manager = new DataWarehouseManager(currentuser.token);
const runPipelineFormat = (cell, row: PipelineStatus, rowIndex: number) => {
    const clickHandler = (id: number) => {
    manager.callCreateRun(id)
    .then(value => {
        if (!value.isSuccess) {
        onFail(value.error);
        }
    });
    };
    return (
    <PipelineRunButton status={row.status} id={row.id} onClick={clickHandler}/>
    );
};
const lastRunFormat = (cell, row:PipelineStatus, rowIndex: number) => {
    if (cell === 'Succeeded'){
    return `${cell}. Ran for ${row.lastRunMeta.duration} on ${row.lastRunMeta.runEnd.toLocaleString()}`;
    }
    else if (cell === 'Failed'){
    return (<Alert style={{margin:0}} variant='danger'>{cell}</Alert>);
    }
    else {
    return cell;
    }
};
const columns : IColumnProps[]= [
    {text: "Pipeline Name", dataField:"name", headerStyle: () => ({ width: '10%' }) },
    {text: "Description", dataField:"description" },
    {text: "Status", dataField:"status", headerStyle: () => ({ width: '1%' }) },
    {
    text: "",
    isDummyField:true,
    formatter: runPipelineFormat,
    dataField:"_",
    headerStyle: () => ({ width: '1%' })
    },
    {text: "Last/Current Run*", dataField:"lastRunMeta.status", formatter: lastRunFormat, headerStyle: () => ({ width: '15%'})}
];
return <>
{
    !listPipelinesError ?
    <BootstrapTable data={pipelineStatus} keyField="id" columns={columns} striped={true}/> :
    <Alert variant={'danger'}>
        <Alert.Heading>Error Loading Pipelines</Alert.Heading>
        <p>{listPipelinesError}</p>
    </Alert>
}
</>;
}

export default PipelineDataTable;