import React from 'react';
import { parseQueryString } from 'utils';
import { UserAgentApplication } from 'msal';
import User from 'models/user';

const AzureAdCallback = (props: any) => {

    let hasErrors = false;
    let errorMessage = "";

    const hash: string = props.location.hash;

    const variables = parseQueryString(hash);

    if(variables.id_token !== undefined && variables.id_token !== null) {
        User.buildUserFromAzureAdToken(variables.id_token).then(() => {
            window.location.href = '/';
        });
    }

    if(hasErrors) {
        return (<div>Error: {errorMessage}</div>)
    } else {
        return (<div>Please wait....</div>);
    }

}

export default AzureAdCallback;