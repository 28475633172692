import React, {useState, useEffect} from "react";
import {Breadcrumb, Col, Container, Row, Button, Alert} from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import SurveyManager from "managers/survey_definition/surveyManager";
import SectionBuilder from "./builder/sectionBuilder";
import BottomPin from "./builder/bottomPin";
import QuestionManager from "managers/survey_definition/questionManager";
import AddSectionModal from "components/survey_definition/survey/builder/addSectionModal";
import AddQuestionModal from "components/survey_definition/survey/builder/addQuestionModal";
import EditQuestionModal from "components/survey_definition/survey/builder/editQuestionModal";
import {ChangedFlag} from "models/changedFlag";
import "./SurveyBuilder.css";

import {Helmet} from 'react-helmet'


const reducer = (store, action) => {
  switch (action.type) {
    case 'setData':
      return {...store, [action.field]: action.payload}
    case 'addQuestion':
      let tempAdd = store.surveyData;
      action.payload.questions && action.payload.questions.map((question) => {
        question.position = tempAdd[action.payload.id].contents.length;
        tempAdd[action.payload.id].contents.push(question);
      });

      tempAdd[action.payload.id].contents.forEach(function (q, i) {
        q.position = i + 1;
      });

      return {...store, surveyData: tempAdd}

    case 'editQuestion':
      let tempEdit = store.surveyData;
      var index = tempEdit[action.payload.id].contents.findIndex(item => item.surveyItemId === action.payload.originalId)
      tempEdit[action.payload.id].contents.splice(index, 1, action.payload.questions[0]);
      tempEdit[action.payload.id].contents.forEach(function (q, i) {
        q.position = i + 1;
      });

      return {...store, surveyData: tempEdit}
    case 'moveQuestionUp':
      let tempMove = store.surveyData;
      var index = tempMove[action.payload.id].contents.findIndex(item => item.surveyItemWordingId === action.payload.item.surveyItemWordingId)
      if (tempMove[action.payload.id].contents[index] && tempMove[action.payload.id].contents[index - 1]) {
        let currentPosition = tempMove[action.payload.id].contents[index].position;
        let oldItem = tempMove[action.payload.id].contents[index - 1];
        tempMove[action.payload.id].contents[index - 1] = action.payload.item;
        tempMove[action.payload.id].contents[index - 1].position = oldItem.position;
        tempMove[action.payload.id].contents[index] = oldItem;
        tempMove[action.payload.id].contents[index].position = currentPosition;
      }
      return {...store, surveyData: tempMove}
    case 'moveQuestionDown':
      let tempMoveDown = store.surveyData;
      var index = tempMoveDown[action.payload.id].contents.findIndex(item => item.surveyItemWordingId === action.payload.item.surveyItemWordingId)
      if (tempMoveDown[action.payload.id].contents[index] && tempMoveDown[action.payload.id].contents[index + 1]) {
        let currentPosition = tempMoveDown[action.payload.id].contents[index].position;
        let oldItem = tempMoveDown[action.payload.id].contents[index + 1];
        tempMoveDown[action.payload.id].contents[index + 1] = action.payload.item;
        tempMoveDown[action.payload.id].contents[index + 1].position = oldItem.position;
        tempMoveDown[action.payload.id].contents[index] = oldItem;
        tempMoveDown[action.payload.id].contents[index].position = currentPosition;
      }
      return {...store, surveyData: tempMoveDown}
    case 'moveSectionUp':
      let tempSectionMoveUp = store.surveyData;
      var contentToMove = tempSectionMoveUp[action.payload.id];
      tempSectionMoveUp[action.payload.id] = tempSectionMoveUp[action.payload.id - 1]
      tempSectionMoveUp[action.payload.id - 1] = contentToMove;
      return {...store, surveyData: tempSectionMoveUp}
    case 'moveSectionDown':
      let tempSectionMoveDown = store.surveyData;
      var contentToMove = tempSectionMoveDown[action.payload.id];
      tempSectionMoveDown[action.payload.id] = tempSectionMoveDown[action.payload.id + 1]
      tempSectionMoveDown[action.payload.id + 1] = contentToMove;
      return {...store, surveyData: tempSectionMoveDown}

    case 'editSectionName':
      let editSectionName = store.surveyData;
      editSectionName[action.payload.id].name = action.payload.sectionName;
      return {...store, surveyData: editSectionName}
    case 'moveQuestion':
      let temp = store.surveyData;
      action.payload.questions && action.payload.questions.map((question) => {
        //if this is a new question, and it's moved, it's still new! Else, you need to update it.
        if (question.changedFlag != ChangedFlag.New) {
          question.changedFlag = ChangedFlag.Update;
        }
        question.position = temp[action.payload.id].contents.length;
        temp[action.payload.id].contents.push(question);
        temp[question.currentSection].contents = temp[question.currentSection].contents.filter(item => item.id !== question.id)
      });
      return {...store, surveyData: temp}
    case 'deleteQuestion':
      let tempSurveyData = store.surveyData;
      action.payload.questions && action.payload.questions.map((question) => {
        if (question.changedFlag === ChangedFlag.New) {
          //just remmove them out of the list, they never really have been added.
          tempSurveyData[question.currentSection].contents = tempSurveyData[question.currentSection].contents.filter(item => item.id !== question.id)
        } else if (question.changedFlag === ChangedFlag.Deleted) {
          tempSurveyData[question.currentSection].contents.find(item => item.id === question.id).changedFlag = ChangedFlag.NoChange;
        } else {
          //they have been added, and need a changedflag to be marked as deleted
          tempSurveyData[question.currentSection].contents.find(item => item.id === question.id).changedFlag = ChangedFlag.Deleted;
        }

      });
      return {...store, surveyData: tempSurveyData}
    case 'addSection':
      return {...store, surveyData: [...store.surveyData, action.payload]}
    case 'addSectionName':
      return {...store, sectionNames: [...store.sectionNames, action.payload]}
    case 'editSectionNameList':
      let tempNames = store.sectionNames;
      tempNames[action.payload.id].name = action.payload.sectionName
      return {...store, sectionNames: [...store.sectionNames, tempNames]}

    case 'addQuestionsUsed':
      let addQuestion = store.questionsUsed;

      action.payload && action.payload.map((question) => {
        addQuestion.push(question.surveyItemId);
      });

      return {...store, questionsUsed: addQuestion}

    case 'deleteQuestionsUsed':
      let deleteQuestion = store.questionsUsed;
      action.payload && action.payload.map((questionId) => {
        deleteQuestion = deleteQuestion.filter(item => item !== questionId.surveyItemId);
      });
      return {...store, questionsUsed: deleteQuestion}
    default :
      return initialState;
  }
}

const initialState = {
  surveyId: '',
  surveyInfo: {},
  surveyData: [],
  sectionNames: [],
  selectedSection: "",
  selectedQuestion: {},
  questions: [],
  newSectionName: '',
  questionsUsed: [],
  options: {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: true,
    timeZone: 'America/New_York'
  },
  errors: {},
  lastItemChecked: {},
}

export default function SurveyBuilder2(props) {
  let surveyManager = new SurveyManager(props.currentuser.smToken);
  let questionManager = new QuestionManager(props.currentuser.smToken);

  let [loading, setLoading] = useState(false);
  let [showErrors, setShowErrors] = useState(false);
  let [modalQuestionShow, setModalQuestionShow] = useState(false);
  let [modalQuestionEditShow, setModalQuestionEditShow] = useState(false);
  let [modalSectionShow, setModalSectionShow] = useState(false);
  let [questions, setQuestions] = useState([]);

  const [store, dispatch] = React.useReducer(reducer, initialState);

  useEffect(() => {
    setLoading(true);
    dispatch({type: 'setData', field: 'surveyId', payload: props.match.params.surveyId});
    surveyManager.getById(props.match.params.surveyId).then(x => {
      surveyManager.getSurveyInfoById(props.match.params.surveyId).then(si => {
        dispatch({type: 'setData', field: 'surveyInfo', payload: si.data});
      });

      dispatch({type: 'setData', field: 'surveyData', payload: x.data});

      let sectionsCombined = [];
      x.data.forEach(section => {
        section.contents.forEach(contents => {
          sectionsCombined.push(contents.surveyItemId);
        });
      });
      dispatch({type: 'setData', field: 'questionsUsed', payload: sectionsCombined});

      let tempSections = [];
      x.data.forEach((section, i) => {
        tempSections.push({name: section.name, value: i});
      });
      dispatch({type: 'setData', field: 'sectionNames', payload: tempSections});

      questionManager.getQuestions().then(x => {
        let question_options = [];
        if (x.length > 0) {
          x.forEach(item => {
            item.wordings.forEach(q => {
              question_options[q.id] = {
                surveyItemWordingId: q.id,
                value: q.id,
                question_number: item.questionNumber,
                label: item.questionNumber,
                text: q.text,
                fullText: item.questionNumber + ": " + q.text,
                default: q.default,
                surveyItemId: item.id,
                responseSetDescription: item.responseSetDescription,
                choices: item.choices,
                reportCategory: item.reporting?.reportCategory,
                variableName: item.reporting?.variableName,
              };
            });
          });
          setQuestions(question_options.filter(v => v));
          setLoading(false);
        }
      });
    });
  }, []);

  const saveSurvey = exit => {
    dispatch({type: 'setData', field: 'errors', payload: {}});
    setShowErrors(false);

    if (true) {
      setLoading(true);
      let survey = store.surveyData;
      let count = 1;
      survey.forEach(section => {
        section.contents.forEach(questions => {
          if (questions.changedFlag != ChangedFlag.Deleted) {
            if (questions.changedFlag != ChangedFlag.New)
              questions.changedFlag = ChangedFlag.Update;

            questions.position = count;
            count++;
          }
        });
      });

      surveyManager.update(props.match.params.surveyId, survey).then((reply) => {

        if (reply.data.errors.length === 0) {
          if (exit) {
            window.location.href = "/survey-definition/survey";
          } else {
            setLoading(true);
            surveyManager.getById(props.match.params.surveyId).then(x => {
              dispatch({type: 'setData', field: 'surveyData', payload: x.data});
            });
            setLoading(false);
          }
        } else {
          dispatch({type: 'setData', field: 'errors', payload: reply.data.errors});
          setLoading(true);
          setShowErrors(true);
          surveyManager.getById(props.match.params.surveyId).then(x => {
            dispatch({type: 'setData', field: 'surveyData', payload: x.data});
          });
          setLoading(false);
        }
      });
    }
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner={<BounceLoader/>}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(91, 134, 96, 0.5)",
        }),
        content: (base) => ({
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }),
      }}
      text="Loading...">
      <Container fluid={true} className="surveryBuilder">
        <Helmet><title>StayMetrics Admin - Survey Builder</title></Helmet>
        <Row>
          <Col lg={12}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/survey-definition/survey">Survey</Breadcrumb.Item>
              <Breadcrumb.Item href="/survey-definition/survey">Survey Builder</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div>
          {showErrors ?
            <Col lg={12}>
              <Alert variant="danger" onClose={() => setShowErrors(false)} dismissible>
                <Alert.Heading>Successful: However, we have some errors!</Alert.Heading>
                <p>These operations couldn't not be performed</p>
                {store.errors && store.errors.map((error, i) => {
                  return (
                    <p key={i}>In section "{error.section}": Survey Item: {error.surveyItemId} could not be deleted
                      because existing survey response data is linked to it.</p>)
                })
                }
              </Alert>
            </Col>
            : ''}
          <Col lg={12} className="sectionTop">
            <div>
              <span className="surveyTitleInfo">{store.surveyInfo.surveyTitle}</span>
              <Button className="surveyNewSectionButton" variant="danger" onClick={() => {
                setModalSectionShow(true)
              }} size="sm" type="submit">Add Section</Button>
            </div>
          </Col>
          <Col lg={12}>
            {store.surveyData && store.surveyData.map((section, index) => {
              return (
                <SectionBuilder
                  questions={questions}
                  questionsUsed={store.questionsUsed}

                  showAddQuestionModal={(e) => {
                    dispatch({type: 'setData', field: 'selectedSection', payload: e});
                    setModalQuestionShow(true);

                  }
                  }
                  showAddQuestionEditModal={(e) => {
                    dispatch({type: 'setData', field: 'selectedSection', payload: e.currentSection});
                    dispatch({type: 'setData', field: 'selectedQuestion', payload: e});
                    setModalQuestionEditShow(true);

                  }
                  }
                  hideAddQuestionModal={(e) => {
                    setModalQuestionShow(false)
                  }}

                  handleMoveQuestion={(e) => {
                    dispatch({type: 'moveQuestion', payload: e})
                  }}
                  handleMoveQuestionUp={(e) => {
                    dispatch({type: 'moveQuestionUp', payload: e});
                  }}
                  handleMoveQuestionDown={(e) => {
                    dispatch({type: 'moveQuestionDown', payload: e});
                  }}

                  handleMoveSectionUp={(e) => {
                    dispatch({type: 'moveSectionUp', payload: e});
                  }}
                  handleMoveSectionDown={(e) => {
                    dispatch({type: 'moveSectionDown', payload: e});
                  }}

                  handleEditSection={(e) => {
                    dispatch({type: 'editSectionName', payload: e});
                    dispatch({type: 'editSectionNameList', payload: e})
                  }}

                  handleEditQuestion={(e) => {
                    dispatch({type: 'editQuestion', payload: e})
                  }}

                  handleAddQuestion={(e) => {
                    dispatch({type: 'addQuestion', payload: e});
                  }}
                  handleDeleteQuestion={(e) => {
                    dispatch({type: 'deleteQuestion', payload: e})
                  }}

                  handleAddQuestionUsed={(e) => {
                    dispatch({type: 'addQuestionsUsed', payload: e.questions})
                  }}
                  handleDeleteQuestionUsed={(e) => {
                    dispatch({type: 'deleteQuestionsUsed', payload: e})
                  }}

                  key={index} section={section} sectionNames={store.sectionNames} id={index}
                  first={index === 0}
                  last={store.surveyData.length - 1 === index}
                />
              )
            })
            }
          </Col>
          <Col lg={12} className="sectionTop">
            <p>&nbsp;</p>
          </Col>
        </div>
        <BottomPin handleSave={(e) => {
          saveSurvey(e)
        }} surveyInfo={store.surveyInfo}/>
      </Container>

      <AddSectionModal show={modalSectionShow}
                       newSectionName={(e) => {
                         dispatch({type: 'addSection', payload: e})
                         dispatch({type: 'addSectionName', payload: e})
                       }}
                       onHide={() => {
                         setModalSectionShow(false);
                       }}
      />
      <AddQuestionModal show={modalQuestionShow}
                        questions={questions}
                        sectionId={store.selectedSection}
                        questionsUsed={store.questionsUsed}
                        addQuestions={(e) => {
                          dispatch({type: 'addQuestion', payload: e})
                          dispatch({type: 'addQuestionsUsed', payload: e.questions})
                          dispatch({type: 'setData', field: 'selectedSection', payload: ""});
                          setModalQuestionShow(false);
                        }}
                        onHide={() => {
                          setModalQuestionShow(false);
                        }}
      />
      <EditQuestionModal show={modalQuestionEditShow}
                         questions={questions}
                         sectionId={store.selectedSection}
                         questionsUsed={store.questionsUsed}
                         selectedQuestion={store.selectedQuestion}
                         editQuestion={(e) => {
                           dispatch({type: 'editQuestion', payload: e})
                           dispatch({type: 'addQuestionsUsed', payload: e.questions})
                           dispatch({type: 'deleteQuestionsUsed', payload: [{surveyItemId: e.originalId}]})
                           dispatch({type: 'setData', field: 'selectedSection', payload: ""});
                           dispatch({type: 'setData', field: 'selectedQuestion', payload: ""});
                           setModalQuestionEditShow(false);
                         }}
                         onHide={() => {
                           setModalQuestionEditShow(false);
                         }}
      />
    </LoadingOverlay>
  )

}
