import React, {FC} from "react";
import {ItemTypeBySurveyType} from "managers/survey_definition/models";
import ItemType from "models/itemType";
import {ItemTypesBySurveyTypesRow} from "./itemTypesBySurveyTypesRow";
import {ItemTypeSelectedCallback} from "./index";

export const ItemTypesBySurveyTypesForm: FC<ItemTypesBySurveyTypesFormParameters> = ({itemTypesBySurveyTypes, itemTypes, onItemTypeChanged}) => {
  const itemsSort = (a: ItemTypeBySurveyType, b: ItemTypeBySurveyType): number => {
    return a.group.localeCompare(b.group) || a.name.localeCompare(b.name);
  };
  return (
    <>
      {itemTypesBySurveyTypes.sort(itemsSort).map((value, index) => {
        return <ItemTypesBySurveyTypesRow
          itemTypeBySurveyType={value}
          key={index}
          index={index}
          itemTypes={itemTypes}
          onItemTypeChanged={onItemTypeChanged}
        />;
      })}
    </>
  );
};

interface ItemTypesBySurveyTypesFormParameters {
  itemTypesBySurveyTypes: ItemTypeBySurveyType[];
  itemTypes: ItemType[];
  onItemTypeChanged: ItemTypeSelectedCallback;
}
