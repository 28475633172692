import React from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import BootstrapForm from "react-bootstrap/Form";
import {QuestionReducerContext} from "../questionReducerContext";

const QuestionReportingData = (props) => {
  const {store, dispatch} = React.useContext(QuestionReducerContext);

  return( store.isLoading ? <h1>Loading</h1> :
    <Container fluid={true}>
      <Row>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Variable Name</Form.Label>
            <Form.Control
              type='text'
              defaultValue={store.reportingData.variable_name}
              onBlur={e => dispatch({
                type: 'setReportingDataField',
                field: 'variable_name',
                payload: e.currentTarget.value
              })}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Report Category</Form.Label>
            <Form.Control
              type='text'
              defaultValue={store.reportingData.report_category}
              onBlur={e => dispatch({
                type: 'setReportingDataField',
                field: 'report_category',
                payload: e.currentTarget.value
              })}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Select Construct</Form.Label>
            <Form.Control
              as='select'
              defaultValue={store.reportingData.constructName}
              onChange={e => dispatch({
                type: 'setReportingDataField',
                field: 'constructName',
                payload: e.currentTarget.value
              })}
            >
              <option value={0}>Constructs</option>
                {store?.constructs.map(construct => {
                  return <option key={construct.id} value={construct.name}>
                    {construct.name}
                  </option>
                })}
            </Form.Control>
            <BootstrapForm.Control.Feedback type="invalid">
            </BootstrapForm.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );

};

export default QuestionReportingData;
