import React, { useState, useEffect, useReducer } from "react";
import { Breadcrumb, Col, Container, Row, Alert, Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import DistributionManager from "managers/survey_distribution/surveyDistributionManager";
import { Helmet } from 'react-helmet'
import {Trophy} from "react-bootstrap-icons";
import Select from "react-select";

const reducer = (store, action) =>{
    switch(action.type){
        case 'setData':
            return {...store, [action.field]: action.payload}        
        default :
            return initialState;
    }
}

  const initialState = {
    id: 0,
    name: "",
    surveyUrl: "",
    subject: "",
    subjectFinalNotice: "",
    message: "",
    messageFinalNotice: "", 
  }

export default function MessagePlan(props) {
    let [loading, setLoading] = useState(false);
    let [saved, setSaved] = useState(false);
    let [selected, setSelected] = useState("");
    let [usage, setUsage] = useState([]);
    let distributionManager = new DistributionManager(props.currentuser.smToken);

    const [store, dispatch] = React.useReducer(reducer, initialState);
    
    useEffect(() => {
        if (props.match.params.messagePlanId){
            setLoading(true);
            distributionManager.getMessagePlansById(props.match.params.messagePlanId).then((plan) => {
                console.log("message plan", plan);   
       
                dispatch({type: 'setData', field: 'id', payload: plan.id});
                dispatch({type: 'setData', field: 'name', payload: plan.name});
                dispatch({type: 'setData', field: 'surveyUrl', payload: plan.surveyUrl});
                dispatch({type: 'setData', field: 'subject', payload: plan.subject });
                dispatch({type: 'setData', field: 'subjectFinalNotice', payload: plan.subjectFinalNotice});
                dispatch({type: 'setData', field: 'message', payload: plan.message});
                dispatch({type: 'setData', field: 'messageFinalNotice', payload: plan.messageFinalNotice});

                distributionManager.getMessagePlansUsage(props.match.params.messagePlanId).then((usage) => {
                    setUsage(usage);
                    console.log("usage", usage);
                    setLoading(false);
                });
            });
        }
    },[]);

    const onShowAlert = () => {
        window.setTimeout(()=>{
            setSaved(false);
            window.location.href = "/survey-distribution/messages";
        },1000)
    }
    
    const saveMessagePlan = plan => {
        console.log("saving contact plan", plan);
        distributionManager.saveMessagePlans(plan).then(()=>{
            setSaved(true);
            onShowAlert();
        });
    }

    const updateMessagePlan = plan => {
        console.log("saving contact plan", plan);
        distributionManager.updateMessagePlans(store.id, plan).then(()=>{
            setSaved(true);
            onShowAlert();
        });
    }
    const ApiObjectBuilder = (mode) => {

        console.log("ApiObjectBuilder", store);
        if (mode === "new"){
            saveMessagePlan(store);
        }
        else{
            updateMessagePlan(store);
        }
    };
    const insertAtCaret = (element, text) => {

        if (element === null){
            return;
        }
        else if (document.selection) {
          element.focus();
          var sel = document.selection.createRange();
          sel.text = text;
          element.focus();
        } else if (element.selectionStart || element.selectionStart === 0) {
          var startPos = element.selectionStart;
          var endPos = element.selectionEnd;
          var scrollTop = element.scrollTop;
          element.value = element.value.substring(0, startPos) +
            text + element.value.substring(endPos, element.value.length);
          element.focus();
          element.selectionStart = startPos + text.length;
          element.selectionEnd = startPos + text.length;
          element.scrollTop = scrollTop;
        } else {
          element.value += text;
          element.focus();
        }
      }
    return(          
        <Container fluid={true}>
            <LoadingOverlay text="Loading..." active={loading} spinner={<BounceLoader />} styles={{
            overlay: (base) => ({
                ...base,
                background: "rgba(91, 134, 96, 0.5)",
            }),
            content: (base) => ({
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
            }),
            }}> 
        <Helmet><title>StayMetrics Admin - Survey List</title></Helmet>
        <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/survey-distribution">Survey Distribution</Breadcrumb.Item>
            { props.match.params.contactPlanId > 0 
            ? <Breadcrumb.Item>Edit Contact Plan</Breadcrumb.Item>
            : <Breadcrumb.Item href="contact/new/">New Contact Plan</Breadcrumb.Item>}
          </Breadcrumb>
        </Col>
      </Row>
        <Row className="mainRow">
                    <Col lg={3}><h3>Message Plan</h3></Col>
        </Row>
        <Row>
        <Col lg={12}>
            <Alert color="info" variant='success' show={saved} >Success</Alert>
        </Col>
        </Row>
        <Row>
            <Col lg={4} className="messageName">
                <label>Message Plan Name</label>
                <input
                    className="form-control" name="name"
                    onChange={ (e) => dispatch({type: 'setData', field: 'name', payload: e.currentTarget.value})}
                    value={store.name}
                />
            </Col>
            <Col lg={4} className="messageName">
                <label>Survey Url</label>
                <input
                    className="form-control" name="surveyUrl"
                    onChange={ (e) => dispatch({type: 'setData', field: 'surveyUrl', payload: e.currentTarget.value})}
                    value={store.surveyUrl}
                />
            </Col>           
        </Row>
        
        <Row>
            <Col lg={12} className="messageName">
                <Button size="sm" className="controlText" variant="success" onClick={(e)=>{ insertAtCaret(document.getElementById(selected),"###client_name###") }}>Client Name</Button>
                <Button size="sm" className="controlText" variant="success" onClick={(e)=>{ insertAtCaret(document.getElementById(selected),"###driver_name###")}}>Driver First Name</Button>
                <Button size="sm" className="controlText" variant="success" onClick={(e)=>{ insertAtCaret(document.getElementById(selected),"###fleet_name###")}}>Fleet Name</Button>
                <Button size="sm" className="controlText" variant="success" onClick={(e)=>{ insertAtCaret(document.getElementById(selected),"###division_name###")}}>Division Name</Button>
                <Button size="sm" className="controlText" variant="success" onClick={(e)=>{ insertAtCaret(document.getElementById(selected),"###terminal_name###")}}>Terminal Name</Button>
                <Button size="sm" className="controlText" variant="success" onClick={(e)=>{ insertAtCaret(document.getElementById(selected),"###manager_name###")}}>Manager Name</Button>
            </Col>
        </Row>
        <Row>
            <Col lg={4}>
            <label>Subject</label>
            <input
                className="form-control" name="subject"
                onChange={ (e) => dispatch({type: 'setData', field: 'subject', payload: e.currentTarget.value})}
                onBlur={(e) => dispatch({type: 'setData', field: 'subject', payload: e.currentTarget.value})}
                value={store.subject}
                id="subject"
                onClick={(e) => {
                    setSelected("subject");
                    dispatch({type: 'setData', field: 'subject', payload: e.currentTarget.value});
                }}
            />
            <label>Message</label>
            <textarea
                className="form-control" name="message"
                rows={5}
                onChange={ (e) => dispatch({type: 'setData', field: 'message', payload: e.currentTarget.value})}
                onBlur={(e) => dispatch({type: 'setData', field: 'message', payload: e.currentTarget.value})}
                value={store.message}
                id="message"
                onClick={(e) => {
                    setSelected("message");
                    dispatch({type: 'setData', field: 'message', payload: e.currentTarget.value});
                }}
            />            
            </Col>
            <Col lg={1}></Col>
            <Col lg={4}>
            <label>Final Notice Subject</label>
            <input
                className="form-control" name="subjectFinalNotice"
                id="subjectFinalNotice"
                onChange={ (e) => dispatch({type: 'setData', field: 'subjectFinalNotice', payload: e.currentTarget.value})}
                onBlur={(e) => dispatch({type: 'setData', field: 'subjectFinalNotice', payload: e.currentTarget.value})}
                value={store.subjectFinalNotice}
                onClick={(e) => {
                    setSelected("subjectFinalNotice");
                    dispatch({type: 'setData', field: 'subjectFinalNotice', payload: e.currentTarget.value});
            }}
            />
            <label>Final Notice Message</label>
            <textarea
                className="form-control" name="messageFinalNotice"
                id="messageFinalNotice"
                rows={5}
                onClick={(e) => {
                    setSelected("messageFinalNotice");
                    dispatch({type: 'setData', field: 'messageFinalNotice', payload: e.currentTarget.value});
                }}
                onChange={ (e) => dispatch({type: 'setData', field: 'messageFinalNotice', payload: e.currentTarget.value})}
                onBlur={(e) => dispatch({type: 'setData', field: 'messageFinalNotice', payload: e.currentTarget.value})}
                value={store.messageFinalNotice}
            />
            </Col>            
        </Row>
        {usage?.length > 0 ?
            <div className="lowerLine">
                        <Col lg={12}> 
                            <h3>Warning</h3>
                            <p>You are also making changes to the following surveys</p>
                            {usage && usage.map ((item,i) => {
                                return (<div key={i}>
                                    <span className="block">{item.surveyDetails.clientName +  ': "' + item.surveyDetails.title + '"'}</span> 
                                    <span className="block">{"End Date: " + item.surveyDetails.endDate }</span>
                                    <span className="block">&nbsp;</span>
                                </div>)})}                                 
                        </Col>
            </div> : ''}
            <div className="lowerLine">                               
                {store.name  && props.match.params.messagePlanId === undefined ? <Button className="buttonMove" variant="success" onClick={(e)=>{ ApiObjectBuilder("new"); }}>Save</Button>: ''}
                {store.name  && props.match.params.messagePlanId > 0 ? <Button className="buttonMove" variant="success" onClick={(e)=>{ ApiObjectBuilder("update"); }}>Update</Button>: ''}
            </div>
        </LoadingOverlay>
        </Container>
        )

}