import React, {useState} from "react";
import Button from 'react-bootstrap/Button'
import {Col, Container, Form, Row} from "react-bootstrap";
import {ChangedFlag} from "models/changedFlag";
import {QuestionReducerContext} from "../questionReducerContext";

const QuestionWordings = (props) =>{
  const {store, dispatch} = React.useContext(QuestionReducerContext);
  let [newWording, setNewWording] = useState("");

  const handleDefaultChanged = (clickedIndex) => {
    store.question.wordings.forEach((x, index) => {
      if(x.changedFlag !== 1) {
        x.changedFlag = ChangedFlag.Update;
      }

      if(index === parseInt(clickedIndex)) {
        x.default = true;
      } else {
        x.default = false;
      }
    });
    dispatch({
      type:"setQuestionField",
      field:"wordings",
      payload:store.question.wordings
    });
  };

  const handleDeleteWording = (index) => {
    if (store.question.wordings.length > 1)
    {
      let deletedWording = store.question.wordings[index];
      let newWordings = store.question.wordings.filter(x => x.id !== deletedWording.id || x.wording !== deletedWording.wording);
      dispatch({
        type:"setQuestionField",
        field:"deleted_wordings",
        payload:[
          ...store.question.deleted_wordings,
          {
            "id":deletedWording.id,
            "wording":deletedWording.wording,
            "default": deletedWording.default,
            "changedFlag": ChangedFlag.Deleted
          }
        ]});

      if (deletedWording.default === true) {
        newWordings[0].default = true;
        newWordings[0].changedFlag = ChangedFlag.Update;
      }
      dispatch({
        type:"setQuestionField",
        field:"wordings",
        payload:newWordings
      });
    }
  };

  const handleAddNewAlternativeWording = () => {
    if(newWording.length == 0) return;
    dispatch({
      type:'setQuestionField',
      field: 'wordings',
      payload: [...store.question.wordings, {
        id: 0,
        wording: newWording,
        changedFlag: ChangedFlag.New,
        default: store.question.wordings.length === 0
      }]
    });

    setNewWording("");
  };

  return (
    <fieldset className="border p-2">
      <legend className="w-auto">Question Wordings</legend>

      <Container>
        <Row>
          <Col lg={9}>
            <Form.Control
              isInvalid={store.touched.wordings && store.errors.wordings}
              onChange={e => setNewWording(e.currentTarget.value)}
              value={newWording}
              placeholder="Add additional wording"
            />

            <Form.Control.Feedback type="invalid">
              {store.errors.wordings}
            </Form.Control.Feedback>
          </Col>
          <Col lg={3}>
            <Button variant="outline-success" className={"btn-block"} onClick={handleAddNewAlternativeWording}>
              Add Wording
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={2}>
            Default
          </Col>
          <Col lg={8}>Wording</Col>
          <Col lg={1}>&nbsp;</Col>
        </Row>
        {store.question.wordings.map((wording, index) => {
          return <SurveyItemWording
            key={index}
            wording={wording}
            index={index}
            onDefaultWordingChanged={handleDefaultChanged}
            onDeleteWording={handleDeleteWording}
          />
        })}

      </Container>
    </fieldset>
  );
};
const SurveyItemWording = ({wording, index, onDefaultWordingChanged, onDeleteWording}) => {
  return(
    <Row>
      <Col lg={2}>
        <Form.Group>
          <Form.Check
             type="checkbox"
             checked={wording.default}
             onChange={_ => onDefaultWordingChanged(index)}
          />
        </Form.Group>
      </Col>
      <Col lg={8}>
        <Form.Group>
          {wording.wording}
        </Form.Group>
      </Col>
      <Col lg={2}>
        <Form.Group>
          <Button
            variant="outline-danger"
            size="sm"
            onClick={_ => onDeleteWording(index)}
          >
            Delete
          </Button>
        </Form.Group>
      </Col>
    </Row>);
};
export default QuestionWordings;
