import React from "react";
import Form from "react-bootstrap/Form";
import {RatingType} from "models/ratingType";
import {Col, Row} from "react-bootstrap";
import {QuestionReducerContext} from "../questionReducerContext";

const QuestionMetaData = (props) => {
  const {store, dispatch} = React.useContext(QuestionReducerContext);
  return (
    <fieldset className="border p-2">
      <legend className="w-auto">Question MetaData</legend>
      <Row>
        <Col sm={3}>
          <div className="form-group">
            <label>Rating Type: </label>
            <Form.Control
              as="select"
              name="ratingType"
              defaultValue={store.question.ratingType}
              onChange={e => dispatch({
                type: 'setQuestionField',
                field: 'ratingType',
                payload: Number(e.currentTarget.value)
              })}
            >
              <option value={RatingType.NonRating}>Non-Rating</option>
              <option value={RatingType.Likert}>Likert</option>
              <option value={RatingType.LikertNa}>Likert with N/A</option>
              <option value={RatingType.Nps}>NPS</option>
              <option value={RatingType.YesNo}>Yes / No</option>
            </Form.Control>
          </div>
        </Col>
        <Col sm={3}>
          <Form.Check
            inline
            label="Reverse Coded"
            type="switch"
            id="reverseCoded"
            checked={store.question.reverseCoded}
            onChange={e => dispatch({
              type: 'setQuestionField',
              field: 'reverseCoded',
              payload: e.currentTarget.checked
            })}
          />
        </Col>
      </Row>
    </fieldset>
  );
};

export default QuestionMetaData;
