import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
    SurveyDistribution,SelectionPlan, ContactPlan, MessagePlan
  } from "models/survey_distribution/surveyDistributions";

export default class SurveyDistributionService {
  private surveyDistribution: AxiosInstance;

  constructor(token: string) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_BASEURL,
      headers: {
        "content-type": "application/json",
        "Authorization": "bearer " + token
      }
    };

    this.surveyDistribution = axios.create(config);
  }

  getSelectionPlansById(id: number) {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/selection-plan/" + id)
    .then(function (definitions: { data: any; }) {
      console.log("definitions.data",definitions.data);
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  getContactPlansById(id: number) {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/contact-plan/" + id)
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  getMessagePlansById(id: number) {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/message-template/" + id)
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  
  updateSelectionPlans(id, plan): Promise<any> {
    return this.surveyDistribution
      .put("api/v2/survey-distribution/selection-plan/" + id , plan)
      .then((x: any) => x.data.id)
      .catch((er: any) => console.log(er));
  }
  saveSelectionPlans(plan): Promise<any> {
    return this.surveyDistribution
      .post("api/v2/survey-distribution/selection-plan", plan)
      .then((x: any) => x.data.id)
      .catch((er: any) => console.log(er));
  }

  getSelectionPlanMethod(): Promise<any> {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/contact-plan/methods")
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  getSelectionPlans(): Promise<any> {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/selection-plan")
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  getSelectionPlansUsage(id): Promise<any> {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/selection-plan/" + id + "/usage")
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  getContactPlanMethods(): Promise<any> {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/contact-plan/methods")
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }  
  saveContactPlans(plan): Promise<any> {
    return this.surveyDistribution
      .post("api/v2/survey-distribution/contact-plan", plan)
      .then((x: any) => x.data.id)
      .catch((er: any) => console.log(er));
  }
  updateContactPlans(id, plan): Promise<any> {
    return this.surveyDistribution
      .put("api/v2/survey-distribution/contact-plan/" + id , plan)
      .then((x: any) => x.data.id)
      .catch((er: any) => console.log(er));
  }
  
  updateDistribution(id, plan): Promise<any> {
    return this.surveyDistribution
      .put("/api/v2/survey-distribution/plan/" + id , plan)
      .then((x: any) => x.data.id)
      .catch((er: any) => console.log(er));
  }

  getContactPlans(): Promise<any> {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/contact-plan")
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  getContactPlansUsage(id): Promise<any> {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/contact-plan/"+ id + "/usage")
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  getMessagePlans(): Promise<any> {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/message-template")
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  getMessagePlansUsage(id): Promise<any> {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/message-template/"+ id +"/usage")
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  saveMessagePlans(plan): Promise<any> {
    return this.surveyDistribution
      .post("api/v2/survey-distribution/message-template", plan)
      .then((x: any) => x.data.id)
      .catch((er: any) => console.log(er));
  }
  updateMessagePlans(id, plan): Promise<any> {
    return this.surveyDistribution
      .put("api/v2/survey-distribution/message-template/" + id , plan)
      .then((x: any) => x.data.id)
      .catch((er: any) => console.log(er));
  }

  getValuesUrlData(url: string, clientId: string): Promise<any> {
    url = url.replace("{client_id}", clientId);
    return this.surveyDistribution.get<any>(url)
    .then(function (definitions: { data: any; }) {
      return definitions.data;
    })
    .catch((er: any) => console.log(er));
  }

  getSelectionCriteria(): Promise<any>{
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/specification/")
    .then(function (definitions: { data: any; }) {
      return definitions.data;
    })
    .catch((er: any) => console.log(er));
  }

  saveDistribution(plan): Promise<any> {
    return this.surveyDistribution
      .post("api/v2/survey-distribution/plan", plan)
      .then((x: any) => x.data.id)
      .catch((er: any) => console.log(er));
  }
  getDistributions(): Promise<any>{
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/plan")
    .then(function (definitions: { data: any; }) {
      return definitions.data;
    })
    .catch((er: any) => console.log(er));
  }

  getDistributionsById(id: number) {
    return this.surveyDistribution.get<any>("api/v2/survey-distribution/plan/" + id)
    .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }

}
