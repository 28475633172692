import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";

const PipelineRunButton : React.FC<IPipelineRunButton> = ({onClick, id, status}) => {
  const [isAvailable, setIsAvailable] = useState<boolean>(status === "Available");
  useEffect(() => setIsAvailable(status === 'Available'), [status]);
  const handleButtonClicked = () => {
    setIsAvailable(false);
    onClick(id)
  };
  return (
    <Button variant={isAvailable ? "primary" : "secondary"}
            disabled={!isAvailable}
            onClick={handleButtonClicked}>
      Run
    </Button>
  );
}

interface IPipelineRunButton {
  status: string;
  id: number;
  onClick(id: number);
}

export default PipelineRunButton;
