import PowerBiReportService from "services/powerBiReportService";
import PowerBiReport from "models/powerBiReport";
import PowerBiWorkspace from "models/powerBiWorkspace";

export default class PowerBiReportManager {
  private powerBiReportService: PowerBiReportService;

  constructor(token: string) {
    this.powerBiReportService = new PowerBiReportService(token);
  }

  getList(): Promise<PowerBiReport[]>{
    return this.powerBiReportService.getList().then(x => x);
  }

  getReportsByClientId(id: number): Promise<PowerBiReport[]> {
    return this.powerBiReportService.getReportsByClientId(id).then((x: PowerBiReport[]) => x)
  }

  getWorkspaces():Promise<PowerBiWorkspace[]> {
    return this.powerBiReportService.getWorkspaces().then(x => x);
  }

}
