import React from "react";
import {ClientSurveyTemplate} from "models/clientSurvey";

let ClientSurveyOptions = (props: ClientSurveyOptionsParameters) => {
  const getClientSurveyDisplayList = (surveys: ClientSurveyTemplate[]) : ClientSurveyTemplate[] => {
    return surveys.sort(((a, b) => a.title > b.title ? 1 : -1));
  }
  return (
<>
    <option value={0} disabled>Select Survey</option>
    {
      getClientSurveyDisplayList(props.surveys)
        .map((item, index) => (
          <ClientSurveyOption
            survey={item}
            key={index}
          />
      ))
    }
</>
  )
}

let ClientSurveyOption = (props: ClientSurveyOptionParameters) => {
  const formatter = new Intl.DateTimeFormat("en-US", {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
  const {survey} = props;

  let startDate = formatter.format(Date.parse(survey.startDate));
  let endDate = formatter.format(Date.parse(survey.endDate));
  return (
    <option value={survey.id}>
      {survey.title + '(' + startDate + ' -> ' + endDate}
    </option>
  );
}

interface ClientSurveyOptionsParameters {
  surveys: ClientSurveyTemplate[];
}

interface ClientSurveyOptionParameters {
  survey: ClientSurveyTemplate;
}

export default ClientSurveyOptions;
