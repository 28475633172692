import React, { useState, useEffect, useReducer } from "react";
import {Col, Container, Row, Button, InputGroup, Form } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Select from "react-select";
import DistributionManager from "managers/survey_distribution/surveyDistributionManager";
import ClientManager from "managers/clientManager"

const reducer = (store, action) =>{
    switch(action.type){
        case 'setData':
            return {...store, [action.field]: action.payload}
        default :
            return initialState;
    }
}

const initialState = {
    id: 0,
    clientId: 0,
    isBreakout: false,
    multipleValues: [],
    multipleValuesLabels: [],
    operation: '',
    type: '',
    singleValue: '',
    selectionPlanId: 0,
}


export default function RenderSelection(props) {
    let [loading, setLoading] = useState(false);
    let [objectData, setObjectData] = useState("");
    let [valueSelected, setValueSelected] = useState("");
    let [operatorSelected, setOperatorSelected] = useState("");
    let [show, setShow] = useState({});
    let [showClient, setShowClient] = useState(false);
    let [client, setClient] = useState(null);
    let [clientList, setClientList] = useState([]);

    const [store, dispatch] = React.useReducer(reducer, initialState);
    let distributionManager = new DistributionManager(props.smToken);
    let clientManager = new ClientManager(props.smToken);

    useEffect(() => {
        setLoading(true);
        setShow(props.show);
        console.log("render props", props);

        if (props.show.valuesUrl !== null){
            setLoading(true);  
            clientManager.getListNew().then((clientList) => {
                setClientList(clientList);
                setShowClient(true);
                setLoading(false);
            });

            if (client !== null){
                distributionManager.getValuesUrlData(props.show.valuesUrl, client.value).then((data) => {
                    setObjectData(data);
                    setLoading(false);
                });
            }
        } 
        else{
            setShowClient(false);
            setLoading(false);  
        }

        dispatch({type: 'setData', field: 'type', payload: props.show.variable});
        dispatch({type: 'setData', field: 'multipleValuesLabels', payload: []});
        dispatch({type: 'setData', field: 'clientId', payload: 0});
        dispatch({type: 'setData', field: 'selectionPlanId', payload: props.selectionPlanId});


    }, [props.show]);
    return(
        <Row>
            {showClient ?
            <Col className="centerItem" lg={12}>
                <InputGroup>
                <label className="distroLabel">Client</label>
                <Select
                    className="surveySelect"
                    options={clientList.map(item => ({ label: item.clientName, value: item.id }))}
                    isMulti={false}
                    value={client}
                    onChange={ (e) => {
                        setClient(e);
                        distributionManager.getValuesUrlData(props.show.valuesUrl, e.value).then((data) => {
                            dispatch({type: 'setData', field: 'clientId', payload: e.value});
                            setObjectData(data);
                            setLoading(false);
                        });
                    }}
                />
            </InputGroup> 
            </Col> : ''}
            <Col className="centerItem" lg={12}>
                {show.operators && show.operators.length > 1 && objectData !== "" ?
                    <Select 
                        className="operationSelect" 
                        options={show.operators} 
                        value={operatorSelected}
                        onChange={ (e) => {
                            dispatch({type: 'setData', field: 'operation', payload: e.value});
                            setOperatorSelected(e);
                        }}/>
                : ''}
            </Col>
            <Col className="centerItem" lg={12}>
            {show.numberOfValues === 'many' && objectData !== "" ?
                <Select 
                    className="surveySelect" 
                    options={objectData.map(item => ({ label: item.value, value: item.key }))}
                    isMulti
                    value={valueSelected}
                    onChange={ (e) => {
                        console.log("eeeeeeee", e);
                        dispatch({type: 'setData', field: 'multipleValues', payload: e});
                        dispatch({type: 'setData', field: 'multipleValuesLabels', payload: e});
                        setValueSelected(e);
                    }}
                />
        
            : ''}
            </Col>
            <Col className="centerItem" lg={12}>
            {parseInt(show.numberOfValues) === 1 && show.expectedDataType === 'bool' ?
                <Form.Check onClick={ (e)=>{
                    if (e.currentTarget.value === 'on'){
                        dispatch({type: 'setData', field: 'singleValue', payload: "true"});
                    }
                    else{
                        dispatch({type: 'setData', field: 'singleValue', payload: "false"});
                        dispatch({type: 'setData', field: 'operation', payload: ''});
                    }
                    dispatch({type: 'setData', field: 'operation', payload: "is"});

                    }} inline label={show.name} /> 
            : ''}  
            </Col>
            <Col className="centerItem" lg={3}>
            {parseInt(show.numberOfValues) === 1 && show.expectedDataType === 'number' ?
                <Form.Control type="input"
                    onChange={ (e) => {
                                dispatch({type: 'setData', field: 'singleValue', payload: e.currentTarget.value});
                                dispatch({type: 'setData', field: 'operation', payload: "is"});
                                setValueSelected(e.currentTarget.value);
                    }} placeholder={show.expectedDataType} />
            : ''}  
            </Col>
            <Col className="centerItem" lg={12}>
            {parseInt(show.numberOfValues) > 1 ?
                <Form.Group as={Row}>
                    {show.valueLabels && show.valueLabels.map((item,i) => {
                        return (
                            <Col lg={2} key={i}>
                            <Form.Label column sm="4">{item}</Form.Label>
                                <Form.Control onChange={ (e) => {
                                        let x = store.multipleValues;
                                        x[i] = {label: e.currentTarget.value, value:  e.currentTarget.value };
                                        dispatch({type: 'setData', field: 'multipleValues', payload: x});
                                        dispatch({type: 'setData', field: 'multipleValuesLabels', payload: x});
                                        dispatch({type: 'setData', field: 'operation', payload: "between"});
                                        setValueSelected(e.currentTarget.value);
                                    }} type="input" placeholder={show.expectedDataType} />
                                
                                </Col>
                            )
                    })}
                </Form.Group>
            : ''}   
            </Col>   
            <Col className="centerItem" lg={12}>
            {store.operation && (store.singleValue || store.multipleValues) ?
                <Button className="buttonSelect" 
                    onClick={(e)=> {
                        props.select(store);
                        setShowClient(false);
                        dispatch({type: 'setData', field: 'operation', payload: ''});
                        dispatch({type: 'setData', field: 'singleValue', payload: ''});
                        dispatch({type: 'setData', field: 'multipleValues', payload: []});
                        dispatch({type: 'setData', field: 'clientId', payload: 0});
                        setShow({});
                        setOperatorSelected("");
                        setObjectData("");
                        setValueSelected("");
                        setClient(null);
                    }} variant="success" >Add</Button>
            : ''}
            </Col>
        </Row>
    )
}