import React, { useEffect, useReducer } from "react";
import { Nav, Container, Alert, Button, Row, Tab, Col, FormControl, InputGroup } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import * as moment from 'moment'
import Select from "react-select";
import Switch from "react-switch";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "../ClientPortal.css";
import states from "./states";
import RewardsPane from "./RewardsPane";
import RecapsPane from "./RecapsPane";
import SmsSettingsPane from "./SmsSettingsPane";
import ServicesPane from "./ServicesPane";
import FtpPane from "./FtpPane";

const reducer = (store, action) =>{
    switch(action.type){
        case 'setData':
            return {...store, clientInfo: {...store.clientInfo, [action.field]: action.payload}}
        case 'setObject':
            return {...store, clientInfo: action.payload}
        case 'setDataObject':
            return {...store, [action.field]: action.payload}
        case 'setDisableThreshold':
            let cb = store.clientInfo.interventionThreshold;
            let index = cb.findIndex(x => x.interventionTypeId === action.payload.id);

            if (cb[index].isEnabled){
                cb[index].isEnabled = 0;
                cb[index].thresholdValue = 0;
            }
            else{
                cb[index].thresholdValue = cb[index].defaultThresholdValue;
                cb[index].isEnabled = 1;
            }
            return {...store, clientInfo: {...store.clientInfo, interventionThreshold: cb}}
        case 'setThreshold':
            let st = store.clientInfo.interventionThreshold;
            let stIndex = st.findIndex(x => x.interventionTypeId === action.payload.id);
            st[stIndex].thresholdValue = action.payload.newValue;

            return {...store, clientInfo: {...store.clientInfo, interventionThreshold: st}}
        case 'setMetaData':
            let newVal = [];
            let current = store.clientInfo.metaTypes;
            if(action.payload != null) {
                for (const [key, value] of Object.entries(action.payload)) {
                    newVal.push({clientMetaTypeId: action.field, id: value.value, name: value.label})
                }
            }
            current.find(meta => meta.id === action.field).values = newVal;
            return {...store, clientInfo: {...store.clientInfo, metaTypes: current}}
        default :
            return initialState;
    }
}

const initialState = {
    clientInfo: {},
    clientsData: [],
    statusData: [],
    metaData:{},
    workspaceData:{},
    telnyxData:{},
    userData: {},
    thresholdCats: [],
    billingCountryData: [{label: "Select", value: ""},{label: "USA", value: "USA"}, {label: "Canada", value: "CAN"}],
    ongoingSurveyDeliveryData: [
        {label: "Rewards Website Link", value: 1},{label: "Email/SMS", value: 2},
        {label: "Rewards Email/SMS", value: 3},{label: "Link via StayMetrics API", value: 4}]
}

export default function ClientForm(props) {
    const [store, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();

    useEffect(() => {
        dispatch({type: 'setObject', payload: {
            id: props.clientData.id || 0,
            accountCode: props.clientData.accountCode || "",
            tenstreetCid: props.clientData.tenstreetCid || 0,
            salesForceId: props.clientData.salesForceId || 0,
            clientName: props.clientData.clientName || "",
            legacyId: props.clientData.legacyId || 0,
            parentId: props.clientData.parentId || 0,
            clientStatus: props.clientData.clientStatus || 0,
            friendlyName: props.clientData.friendlyName || "",
            driveCountStart: props.clientData.driveCountStart || 0,
            telnyxMessageProfileId: props.clientData.telnyxMessageProfileId || 0,
            dotNumber: props.clientData.dotNumber || "",
            smsAllowed: props.clientData.smsAllowed || false,
            emailAllowed: props.clientData.emailAllowed || false,
            billingCity: props.clientData.billingCity || "",
            billingCountry: props.clientData.billingCountry || "",
            billingState: props.clientData.billingState || "",
            billingStreet: props.clientData.billingStreet || "",
            billingPostalCode: props.clientData.billingPostalCode || "",
            billingPhoneNumber: props.clientData.billingPhoneNumber || "",

            fleetSizeTrucks: props.clientData.fleetSizeTrucks || 0,
            fleetSizeDrivers: props.clientData.fleetSizeDrivers || 0,

            ftpHomeDirectory: props.clientData.ftpHomeDirectory || "",
            ftpUsername: props.clientData.ftpUsername || "",
            ftpPassword: props.clientData.ftpPassword || "",
            ftpFileType: props.clientData.ftpFileType || "",
            ftpAutomation: props.clientData.ftpAutomation || false,
            ftpClientUploadTransfer: props.clientData.ftpClientUploadTransfer || false,
            ftpFileName: props.clientData.ftpFileName || "",

            powerBiDesignerUserId: props.clientData.powerBiDesignerUserId || 0,
            surveyDesignerUserId: props.clientData.surveyDesignerUserId || 0,
            clientSolutionAnalystUserId: props.clientData.clientSolutionAnalystUserId || 0,
            crmAssignedUserId: props.clientData.crmAssignedUserId || 0,
            salesPersonUserId: props.clientData.salesPersonUserId || 0,
            ongoingSurveyFrequency: props.clientData.ongoingSurveyFrequency || 0,

            rewardsUrl: props.clientData.rewardsUrl || "",
            rewardsApiUrl: props.clientData.rewardsApiUrl || "",
            ongoingSurveyPoints: props.clientData.ongoingSurveyPoints || 0,

            dispatcherTerm: props.clientData.dispatcherTerm || "",
            rewardsApiKey: props.clientData.rewardsApiKey || "",
            rewardsApiGuid: props.clientData.rewardsApiGuid || "",

            rewardsPlatformId: props.clientData.rewardsPlatformId || null,
            rewardsSendHireDate: props.clientData.rewardsSendHireDate || false,
            removePointsOnRehire: props.clientData.removePointsOnRehire || false,
            useOrgCode: props.clientData.useOrgCode || false,

            serviceStartDate: props.clientData.serviceStartDate != null ? moment(props.clientData.serviceStartDate).toDate() : null,
            serviceEndDate: props.clientData.serviceEndDate != null ? moment(props.clientData.serviceEndDate).toDate() : null,
            wellnessTraining: props.clientData.wellnessTraining || false,
            safetyTraining: props.clientData.safetyTraining || false,
            firstImpressionSurvey: props.clientData.firstImpressionSurvey || false,
            earlyExperienceSurvey: props.clientData.earlyExperienceSurvey || false,
            ninetyDaySurvey: props.clientData.ninetyDaySurvey || false,
            exitSurvey: props.clientData.exitSurvey || false,
            ongoingSurvey: props.clientData.ongoingSurvey || false,
            driveFirst: props.clientData.driveFirst || false,
            mobileApp: props.clientData.mobileApp || false,
            rewardsProgram: props.clientData.rewardsProgram || false,
            twegos: props.clientData.twegos || false,
            predictiveIndex: props.clientData.predictiveIndex || false,

            powerBiWorkspaceId: props.clientData.powerBiWorkspaceId || 0,

            enableCallStatsAutomation: props.clientData.enableCallStatsAutomation || false,
            callStatsSendNoResponse: props.clientData.callStatsSendNoResponse || false,
            callStatsDay0: props.clientData.callStatsDay0 || 0,
            callStatsDay1: props.clientData.callStatsDay1 || 0,
            callStatsDay2: props.clientData.callStatsDay2 || 0,
            ongoingDaysAfterHire: props.clientData.ongoingDaysAfterHire || 0,
            fourtyFiveDayCount: props.clientData.fourtyFiveDayCount || 0,
            sevenDayCount: props.clientData.sevenDayCount || 0,

            excludeInvoluntary: props.clientData.excludeInvoluntary,

            interventionThreshold: props.clientData.interventionThreshold || [],
            metaTypes: props.clientData.metaTypes || props.metaData && props.metaData.map(item => ({id: item.id, name: item.name, values: []})),
            smsHoursStartMonday: props.clientData.smsHoursStartMonday || null,
            smsHoursStopMonday: props.clientData.smsHoursStopMonday || null,
            smsHoursStartTuesday: props.clientData.smsHoursStartTuesday || null,
            smsHoursStopTuesday: props.clientData.smsHoursStopTuesday || null,
            smsHoursStartWednesday: props.clientData.smsHoursStartWednesday || null,
            smsHoursStopWednesday: props.clientData.smsHoursStopWednesday || null,
            smsHoursStartThursday: props.clientData.smsHoursStartThursday || null,
            smsHoursStopThursday: props.clientData.smsHoursStopThursday || null,
            smsHoursStartFriday: props.clientData.smsHoursStartFriday || null,
            smsHoursStopFriday: props.clientData.smsHoursStopFriday || null,
            smsHoursStartSaturday: props.clientData.smsHoursStartSaturday || null,
            smsHoursStopSaturday: props.clientData.smsHoursStopSaturday || null,
            smsHoursStartSunday: props.clientData.smsHoursStartSunday || null,
            smsHoursStopSunday: props.clientData.smsHoursStopSunday || null,
            callStatReportType: props.clientData.callStatReportType || 0,
        }
    });

    if (props.clientData.billingState){
        let obj = states.find(item => item.value === props.clientData.billingState) || {label: "", value: ""};
        dispatch({type: 'setData', field: 'billingState', payload: obj})
    }

    if (props.clientData && store.ongoingSurveyDeliveryData){
        let obj = store.ongoingSurveyDeliveryData.find(item => item.value === props.clientData.ongoingSurveyDeliveryMethodId) || {label: "", value: 0};
        dispatch({type: 'setData', field: 'ongoingSurveyDeliveryMethodId', payload: obj})
    }

    if (props.clientData && store.billingCountryData){
        let obj = store.billingCountryData.find(item => item.value === props.clientData.billingCountry) || {label: "", value: ""};
        dispatch({type: 'setData', field: 'billingCountry', payload: obj})
    }

    if (props.clientsData){
        dispatch({type: 'setDataObject',field:'clientsData', payload: props.clientsData})
        let obj = props.clientsData.find(item => item.value === props.clientData.parentId) || {label: "", value: 0};
        dispatch({type: 'setData', field: 'parentId', payload: obj})
    }
    if (props.statusData){
        dispatch({type: 'setDataObject',field:'statusData', payload: props.statusData})
        let obj = props.statusData.find(item => item.value === props.clientData.clientStatus) || {label: "", value: 0};
        dispatch({type: 'setData', field: 'clientStatus', payload: obj})
    }
    if (props.workspaceData){
        dispatch({type: 'setDataObject',field:'workspaceData', payload: props.workspaceData})
        let obj = props.workspaceData.find(item => item.value === props.clientData.powerBiWorkspaceId) || {label: "", value: ""};
        dispatch({type: 'setData', field: 'powerBiWorkspaceId', payload: obj});
    }
    if (props.userData){
        let obj = {};

        dispatch({type: 'setDataObject', field:'userData', payload: props.userData});

        obj = props.userData.find(item => item.value === props.clientData.powerBiDesignerUserId) || {label: "", value: 0};
            dispatch({type: 'setData', field: 'powerBiDesignerUserId', payload: obj});
        obj = props.userData.find(item => item.value === props.clientData.surveyDesignerUserId) || {label: "", value: 0};
            dispatch({type: 'setData', field: 'surveyDesignerUserId', payload: obj});
        obj = props.userData.find(item => item.value === props.clientData.clientSolutionAnalystUserId) || {label: "", value: 0};
            dispatch({type: 'setData', field: 'clientSolutionAnalystUserId', payload: obj});
        obj = props.userData.find(item => item.value === props.clientData.crmAssignedUserId) || {label: "", value: 0};
            dispatch({type: 'setData', field: 'crmAssignedUserId', payload: obj});
        obj = props.userData.find(item => item.value === props.clientData.salesPersonUserId) || {label: "", value: 0};
            dispatch({type: 'setData', field: 'salesPersonUserId', payload: obj});
    }

    if (props.metaData){
        dispatch({type: 'setDataObject', field: 'metaData', payload: {
            metaArea: props.metaData.find(meta => meta.id === 1).values.map(item => ({ label: item.name, value: item.id })),
            metaCap: props.metaData.find(meta => meta.id === 2).values.map(item => ({ label: item.name, value: item.id })),
            metaCargo: props.metaData.find(meta => meta.id === 3).values.map(item => ({ label: item.name, value: item.id })),
            metaDriver: props.metaData.find(meta => meta.id === 4).values.map(item => ({ label: item.name, value: item.id })),
            metaFleet: props.metaData.find(meta => meta.id === 5).values.map(item => ({ label: item.name, value: item.id })),
            metaFreight: props.metaData.find(meta => meta.id === 6).values.map(item => ({ label: item.name, value: item.id })),
            metaHaulage: props.metaData.find(meta => meta.id === 7).values.map(item => ({ label: item.name, value: item.id })),
            metaLoad: props.metaData.find(meta => meta.id === 8).values.map(item => ({ label: item.name, value: item.id })),
            metaRoutes: props.metaData.find(meta => meta.id === 9).values.map(item => ({ label: item.name, value: item.id })),
            metaTrailer: props.metaData.find(meta => meta.id === 10).values.map(item => ({ label: item.name, value: item.id })),
            metaVehicle: props.metaData.find(meta => meta.id === 11).values.map(item => ({ label: item.name, value: item.id })),
        }});
    }

    if (props.telnyxData){
        dispatch({type: 'setDataObject', field:'telnyxData', payload: props.telnyxData});
        let obj = props.telnyxData.find(item => parseInt(item.value) === props.clientData.telnyxMessageProfileId) || {label: "", value: 0};
        dispatch({type: 'setData', field: 'telnyxMessageProfileId', payload: obj});
    }

    if (props.clientData.interventionThreshold){
        dispatch({type: 'setDataObject', field: 'thresholdCats', payload: [...new Set(props.clientData.interventionThreshold.map((x) => x.categoryName))]})
    }

}, [props.clientData, props.metaData, props.saved, props.telnyxData, props.userData]);

    return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="1">
    <Row>
    <Col sm={2}>
        <Nav id="clientTab" variant="pills" tabclassname="tabText" className="flex-column">
            <Nav.Item>
                <Nav.Link eventKey="1">Client Data</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="2">Assigned Users</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="3">Firmographics</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="4">Services</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="5">FTP</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="6">Rewards & Surveys</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="7">Recaps</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="8">PowerBI Info</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="9">Intervention Thresholds</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="10">SMS hours</Nav.Link>
            </Nav.Item>
        </Nav>
        <hr></hr>
        <Button block variant="danger" size="sm" onClick={(e)=> {history.goBack()}} type="submit">Cancel</Button>
        {store.clientInfo.clientName
        ? <Button block variant="success" size="sm" onClick={(e)=> {props.clientUpdate(store.clientInfo)}} type="submit">Save</Button>
        : ''}
    </Col>
    <Col sm={9}>
    <Alert color="info" variant='success' show={props.saved} >Client Successfully Updated</Alert>
    <Tab.Content>
        <Tab.Pane eventKey="1">
            <Container>
                <Row className="serviceRow">
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">Client Name</span>
                        <input
                            value={store.clientInfo.clientName || ""}
                            onChange={(e)=>{dispatch({type: 'setData', field: 'clientName', payload: e.currentTarget.value});}}
                            className="form-control"
                            name="clientName"
                        />
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">Parent Client</span>
                        <Select
                            onChange={(e)=>{dispatch({type: 'setData', field: 'parentId', payload: e});}}
                            value={store.clientInfo.parentId}
                            options={store.clientsData}
                            isMulti={false}
                        />
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">Legacy ID</span>
                        <input
                            readOnly
                            value={store.clientInfo.legacyId || ""}
                            className="form-control"
                            name="firstName"
                        />
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">Tenstreet CID</span>
                        <input
                            readOnly
                            value={store.clientInfo.tenstreetCid || ""}
                            className="form-control"
                            name="tenstreetCid"
                        />
                    </Col>
                </Row>
                <Row className="serviceRow">
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">Driver Count Start</span>
                        <input
                            value={store.clientInfo.driveCountStart || 0}
                            onChange={(e)=>{dispatch({type: 'setData', field: 'driveCountStart', payload: e.currentTarget.value});}}
                            type="number"
                            className="form-control"
                            name="driveCountStart"
                        />
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">Telnyx Message Profile ID</span>
                        <Select
                                onChange={(e)=>{dispatch({type: 'setData', field: 'telnyxMessageProfileId', payload: e});}}
                                value={store.clientInfo.telnyxMessageProfileId}
                                options={store.telnyxData}
                                isMulti={false}
                            />
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">Salesforce ID</span>
                        <input
                            readOnly
                            value={store.clientInfo.salesForceId || ""}
                            className="form-control"
                            name="salesforceid"
                        />
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">Client Status</span>
                        <Select
                            onChange={(e)=>{dispatch({type: 'setData', field: 'clientStatus', payload: e});}}
                            value={store.clientInfo.clientStatus}
                            options={store.statusData}
                            isMulti={false}
                        />
                    </Col>
                </Row>
                <Row className="serviceRow">
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">Friendly Name</span>
                        <input
                            value={store.clientInfo.friendlyName || ""}
                            onChange={(e)=>{dispatch({type: 'setData', field: 'friendlyName', payload: e.currentTarget.value});}}
                            className="form-control"
                            name="firstName"
                        />
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">DOT Number</span>
                        <input
                            value={store.clientInfo.dotNumber || ""}
                            onChange={(e)=>{dispatch({type: 'setData', field: 'dotNumber', payload: e.currentTarget.value});}}
                            className="form-control"
                            name="dotNumber"
                        />
                    </Col>
                </Row>
                <Row className="serviceRow">
        <Col className="centerColumn" lg={3}>
            <span className="serviceSpan">Billing Phone Number</span>
            <input
                value={store.clientInfo.billingPhoneNumber || ""}
                onChange={(e)=>{dispatch({type: 'setData', field: 'billingPhoneNumber', payload: e.currentTarget.value});}}
                className="form-control"
                name="billingPhoneNumber"
            />
        </Col>
        <Col className="centerColumn" lg={3}>
            <span className="serviceSpan">Billing Street</span>
            <input
                value={store.clientInfo.billingStreet || ""}
                onChange={(e)=>{dispatch({type: 'setData', field: 'billingStreet', payload: e.currentTarget.value});}}
                className="form-control"
                name="billingStreet"
            />
        </Col>
        <Col className="centerColumn" lg={3}>
            <span className="serviceSpan">Billing City</span>
            <input
                value={store.clientInfo.billingCity || ""}
                onChange={(e)=>{dispatch({type: 'setData', field: 'billingCity', payload: e.currentTarget.value});}}
                className="form-control"
                name="billingCity"
            />
        </Col>
        <Col className="centerColumn" lg={3}>
            <span className="serviceSpan">Billing State</span>
            <Select
                        onChange={(e)=>{dispatch({type: 'setData', field: 'billingState', payload: e});}}
                        options={states}
                        value={store.clientInfo.billingState || ""}
                        isMulti={false}
                    />
        </Col>
        <Col className="centerColumn" lg={3}>
            <span className="serviceSpan">Billing Postal Code</span>
            <input
                value={store.clientInfo.billingPostalCode || ""}
                onChange={(e)=>{dispatch({type: 'setData', field: 'billingPostalCode', payload: e.currentTarget.value});}}
                className="form-control"
                name="billingPostalCode"
            />
        </Col>
        <Col className="centerColumn" lg={3}>
            <span className="serviceSpan">Billing Country</span>
            <Select
                        onChange={(e)=>{dispatch({type: 'setData', field: 'billingCountry', payload: e});}}
                        options={store.billingCountryData}
                        value={store.clientInfo.billingCountry || ""}
                        isMulti={false}
                    />
        </Col>
    </Row>
            </Container>
        </Tab.Pane>
        <Tab.Pane eventKey="2">
        <Container>
        <Row className="serviceRow">
            <Col className="centerColumn" lg={3}>
                <label>
                    <span className="serviceSpan">Client Solution Analyst</span>
                    <Select
                        onChange={(e)=>{dispatch({type: 'setData', field: 'clientSolutionAnalystUserId', payload: e});}}
                        options={store.userData}
                        value={store.clientInfo.clientSolutionAnalystUserId}
                        isMulti={false}
                    />
                </label>
            </Col>
            <Col className="centerColumn" lg={3}>
                <label>
                    <span className="serviceSpan">CRM Assigned</span>
                    <Select
                        onChange={(e)=>{dispatch({type: 'setData', field: 'crmAssignedUserId', payload: e});}}
                        options={store.userData}
                        value={store.clientInfo.crmAssignedUserId}
                        isMulti={false}
                    />
                </label>
            </Col>
            <Col className="centerColumn" lg={3}>
                <label>
                    <span className="serviceSpan">Sales Person</span>
                    <Select
                        onChange={(e)=>{dispatch({type: 'setData', field: 'salesPersonUserId', payload: e});}}
                        options={store.userData}
                        value={store.clientInfo.salesPersonUserId}
                        isMulti={false}
                    />
                </label>
            </Col>
        </Row>
        <Row className="serviceRow">
            <Col className="centerColumn" lg={3}>
                <label>
                    <span className="serviceSpan">Power BI Designer</span>
                    <Select
                        onChange={(e)=>{dispatch({type: 'setData', field: 'powerBiDesignerUserId', payload: e});}}
                        options={store.userData}
                        value={store.clientInfo.powerBiDesignerUserId}
                        isMulti={false}
                    />
                </label>
            </Col>
            <Col className="centerColumn" lg={3}>
                <label>
                    <span className="serviceSpan">Survey Designer</span>
                    <Select
                        onChange={(e)=>{dispatch({type: 'setData', field: 'surveyDesignerUserId', payload: e});}}
                        options={store.userData}
                        value={store.clientInfo.surveyDesignerUserId}
                        isMulti={false}
                    />
                </label>
            </Col>
        </Row>
        </Container>
        </Tab.Pane>
        <Tab.Pane eventKey="3">
            <Container>
                <Row className="serviceRow">
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">Fleet Size (Drivers)</span>
                        <input
                            type="number"
                            className="form-control"
                            value={store.clientInfo.fleetSizeDrivers || ""}
                            onChange={(e)=>{dispatch({type: 'setData', field: 'fleetSizeDrivers', payload: e.currentTarget.value});}}
                        />
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <span className="serviceSpan">Fleet Size (Trucks)</span>
                        <input
                            type="number"
                            className="form-control"
                            value={store.clientInfo.fleetSizeTrucks || ""}
                            onChange={(e)=>{dispatch({type: 'setData', field: 'fleetSizeTrucks', payload: e.currentTarget.value});}}
                        />
                    </Col>
                </Row>
                <Row className="serviceRow">
                    <Col className="centerColumn" lg={3}>
                        <label>
                            <span className="serviceSpan">Area</span>
                            <Select className="selectWidth"
                                onChange={(e)=>{dispatch({type: 'setMetaData', field: 1, payload: e});}}
                                value={store.clientInfo.metaTypes && store.clientInfo.metaTypes.find(meta => meta.id === 1).values.map(item => ({ label: item.name, value: item.id }))}
                                options={store.metaData.metaArea}
                                isMulti={true}
                            />
                        </label>
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <label>
                            <span className="serviceSpan">Cab Type</span>
                            <Select className="selectWidth"
                                onChange={(e)=>{dispatch({type: 'setMetaData', field: 2, payload: e});}}
                                value={store.clientInfo.metaTypes &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 2) &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 2).values.map(item => ({ label: item.name, value: item.id }))}
                                options={store.metaData.metaCap}
                                isMulti={true}
                            />
                        </label>
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <label>
                            <span className="serviceSpan">Cargo Carried</span>
                            <Select className="selectWidth"
                            onChange={(e)=>{dispatch({type: 'setMetaData', field: 3, payload: e});}}
                            value={store.clientInfo.metaTypes &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 3) &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 3).values.map(item => ({ label: item.name, value: item.id }))}
                                isMulti={true}
                                options={store.metaData.metaCargo}
                            />
                        </label>
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <label>
                            <span className="serviceSpan">Driver Type</span>
                            <Select className="selectWidth"
                            onChange={(e)=>{dispatch({type: 'setMetaData', field: 4, payload: e});}}
                            value={store.clientInfo.metaTypes &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 4) &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 4).values.map(item => ({ label: item.name, value: item.id }))}
                            isMulti={true}
                            options={store.metaData.metaDriver}
                            />
                        </label>
                    </Col>
                </Row>
                <Row className="serviceRow">
                    <Col className="centerColumn" lg={3}>
                        <label>
                            <span className="serviceSpan">Fleet Type</span>
                            <Select className="selectWidth"
                            onChange={(e)=>{dispatch({type: 'setMetaData', field: 5, payload: e});}}
                            value={store.clientInfo.metaTypes &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 5) &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 5).values.map(item => ({ label: item.name, value: item.id }))}
                            isMulti={true}
                            options={store.metaData.metaFleet}
                            />
                        </label>
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <label>
                            <span className="serviceSpan">Freight Type</span>
                            <Select className="selectWidth"
                            onChange={(e)=>{dispatch({type: 'setMetaData', field: 6, payload: e});}}
                            value={store.clientInfo.metaTypes &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 6) &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 6).values.map(item => ({ label: item.name, value: item.id }))}
                            isMulti={true}
                            options={store.metaData.metaFreight}
                            />
                        </label>
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <label>
                            <span className="serviceSpan">Haulage Type</span>
                            <Select className="selectWidth"
                            onChange={(e)=>{dispatch({type: 'setMetaData', field: 7, payload: e});}}
                            value={store.clientInfo.metaTypes &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 7) &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 7).values.map(item => ({ label: item.name, value: item.id }))}
                            isMulti={true}
                            options={store.metaData.metaHaulage}
                            />
                            </label>
                    </Col>
                    <Col className="centerColumn" lg={3}>
                        <label>
                            <span className="serviceSpan">Load Type</span>
                            <Select className="selectWidth"
                            onChange={(e)=>{dispatch({type: 'setMetaData', field: 8, payload: e});}}
                            value={store.clientInfo.metaTypes &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 8) &&
                                    store.clientInfo.metaTypes.find(meta => meta.id === 8).values.map(item => ({ label: item.name, value: item.id }))}
                            isMulti={true}
                            options={store.metaData.metaLoad}
                            />
                        </label>
                    </Col>
                </Row>
                <Row className="serviceRow">
        <Col className="centerColumn" lg={3}>
            <label>
                <span className="serviceSpan">Routes</span>
                <Select className="selectWidth"
                onChange={(e)=>{dispatch({type: 'setMetaData', field: 9, payload: e});}}
                value={store.clientInfo.metaTypes &&
                        store.clientInfo.metaTypes.find(meta => meta.id === 9) &&
                        store.clientInfo.metaTypes.find(meta => meta.id === 9).values.map(item => ({ label: item.name, value: item.id }))}
                isMulti={true}
                options={store.metaData.metaRoutes}
                />
            </label>
        </Col>
        <Col className="centerColumn" lg={3}>
            <label>
                <span className="serviceSpan">Trailer Type</span>
                <Select className="selectWidth"
                onChange={(e)=>{dispatch({type: 'setMetaData', field: 10, payload: e});}}
                value={store.clientInfo.metaTypes &&
                        store.clientInfo.metaTypes.find(meta => meta.id === 10) &&
                        store.clientInfo.metaTypes.find(meta => meta.id === 10).values.map(item => ({ label: item.name, value: item.id }))}
                isMulti={true}
                options={store.metaData.metaTrailer}
                />
            </label>
        </Col>
        <Col className="centerColumn" lg={3}>
            <label>
                <span className="serviceSpan">Vehicle Type</span>
                <Select className="selectWidth"
                onChange={(e)=>{dispatch({type: 'setMetaData', field: 11, payload: e});}}
                value={store.clientInfo.metaTypes &&
                        store.clientInfo.metaTypes.find(meta => meta.id === 11) &&
                        store.clientInfo.metaTypes.find(meta => meta.id === 11).values.map(item => ({ label: item.name, value: item.id }))}
                isMulti={true}
                options={store.metaData.metaVehicle}
                />
            </label>
        </Col>
    </Row>
            </Container>
        </Tab.Pane>
        <Tab.Pane eventKey="4">
          <ServicesPane store={store} dispatch={dispatch}/>
        </Tab.Pane>
        <Tab.Pane eventKey="5">
          <FtpPane store={store} dispatch={dispatch}/>
        </Tab.Pane>
        <Tab.Pane eventKey="6">
          <RewardsPane store={store} dispatch={dispatch} />
        </Tab.Pane>
        <Tab.Pane eventKey="7">
          <RecapsPane store={store} dispatch={dispatch}/>
        </Tab.Pane>
        <Tab.Pane eventKey="8">
            <Container>
            <Row className="serviceRow">
            <Col className="centerColumn" lg={3}>
                <label>
                    <span className="serviceSpan">Power BI Assigned Workspace</span>
                    <Select
                        onChange={(e)=>{dispatch({type: 'setData', field: 'powerBiWorkspaceId', payload: e});}}
                        options={store.workspaceData}
                        value={store.clientInfo.powerBiWorkspaceId}
                        isMulti={false}
                    />
                </label>
            </Col>
            </Row>
            </Container>
            </Tab.Pane>
        <Tab.Pane eventKey="9">
            <Container>
            {store.thresholdCats && store.thresholdCats.map((value, key) => {
                const th = store.clientInfo.interventionThreshold.filter((x) => x.categoryName === value);
                return (<div key={key}>
                    <h4>{value}</h4>
                    <Row key={key}>
                    {th && th.map((value, key) => {
                        return (
                            <Col key={key} lg={4}>
                            <label className="thresholdLabel">{value.shortDesc}</label>
                            <InputGroup key={key} className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Checkbox key={key} defaultChecked={value.isEnabled}
                                        onClick={(e)=>{
                                        dispatch({type: 'setDisableThreshold',
                                                payload: {id: value.interventionTypeId, event: e}});
                                        }}
                                    />
                                </InputGroup.Prepend>
                                {value.isEnabled ?
                                    <FormControl as="input" key={key} type="number" defaultValue={value.thresholdValue}
                                        onChange={(event) =>{
                                            dispatch({type: 'setThreshold', payload: {id: value.interventionTypeId, newValue: event.target.value}});
                                        }}
                                    />
                                : null}
                            </InputGroup>
                            </Col>
                        );
                        })}
                    </Row>
                </div>
                );
            })}
            </Container>
        </Tab.Pane>
        <Tab.Pane eventKey="10">
          { store?.clientInfo &&
            <SmsSettingsPane store={store} dispatch={dispatch}/>
          }
        </Tab.Pane>
    </Tab.Content>
    </Col>
</Row>
</Tab.Container>
);
}
