import React, { useState, useEffect, useReducer } from "react";
import { Nav, Container, Breadcrumb, Row, Tab, Col, FormControl, InputGroup } from "react-bootstrap";
import ClientForm from "../client_portal/client_components/ClientForm"
import { Helmet } from 'react-helmet'
import ClientManager from "managers/clientManager";
import MetaManager from "managers/metaManager";
import PowerBiReportManager from "managers/powerBiReportManager";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

export default function EditClient(props) {

    let [clients, setClients] = useState(false);
    let [clientData, setClientData] = useState(false);
    let [clientStatusData, setClientStatusData] = useState(false);
    let [workspaces, setWorkspaces] = useState(false);
    let [metaData, setMetaData] = useState(false);
    let [userData, setUserData] = useState(false);
    let [telnyxData, setTelnyxData] = useState(false);
    let [loading, setLoading] = useState(false);
    let [saved, setSaved] = useState(false);

    let clientManager = new ClientManager(props.currentuser.smToken);
    let metaManager = new MetaManager(props.currentuser.smToken);
    let powerBiReportManager = new PowerBiReportManager(props.currentuser.smToken);
    let clientId = props.match.params.clientId;

    useEffect(() => {
        setLoading(true);

        let telnyx = metaManager.getTelnyxList().then(telnyx => {return telnyx});
        let meta = metaManager.getList().then(meta => {return meta});
        let client = clientManager.getClientById(clientId).then(client => {return client});
        let users = clientManager.getUsersList().then(users => {return users});
        let workspaces = powerBiReportManager.getWorkspaces().then(workspaces => {return workspaces});
        let status = clientManager.getStatusList().then(status => {return status});
        let clients = clientManager.getParentClientList().then(clients => {return clients});
        let thresholds = clientManager.getThresholdsList().then(thresholds => {return thresholds});

        Promise.all([telnyx,meta,client, users, workspaces, status, clients, thresholds]).then((values)=>{

            values[4] = values[4].map(item => ({ label: item.name, value: item.id }));
            values[6] = values[6].map(item => ({ label: item.clientName, value: item.id }));
            values[0] = values[0].map(item => ({ label: item.name, value: item.id }));

            let tempClientData = values[2];

            if (tempClientData && tempClientData.interventionThreshold === undefined || tempClientData.interventionThreshold.length == 0) {
                tempClientData.interventionThreshold = values[7];
            }

            let tempData = values[6];
            tempData.unshift({label:"No Parent", value: 0});

            setClientData(tempClientData);
            setClients(tempData.filter(item => item.value !== tempClientData.id));
            setClientStatusData(values[5]);
            setWorkspaces(values[4]);
            setUserData(values[3]);
            setMetaData(values[1]);
            setTelnyxData(values[0]);
            setLoading(false);
        });

    }, []);

    const onShowAlert = ()=>{
        window.setTimeout(()=>{
            setSaved(false);
        },2000)
    }
    const handleUpdate = (clientId, client) => {
        setLoading(true);
        //turn those key/value pair objects from select boxes into values for api
        client.telnyxMessageProfileId = client.telnyxMessageProfileId.value;
        client.powerBiDesignerUserId = client.powerBiDesignerUserId.value;
        client.surveyDesignerUserId = client.surveyDesignerUserId.value;
        client.clientSolutionAnalystUserId = client.clientSolutionAnalystUserId.value;
        client.crmAssignedUserId = client.crmAssignedUserId.value;
        client.salesPersonUserId = client.salesPersonUserId.value;
        client.powerBiWorkspaceId = client.powerBiWorkspaceId.value;
        client.clientStatus = client.clientStatus.value;
        client.parentId = client.parentId.value;
        client.ongoingSurveyDeliveryMethodId = client.ongoingSurveyDeliveryMethodId.value;
        client.billingCountry = client.billingCountry.value;
        client.billingState = client.billingState.value;

        console.log("SAVING", clientId, client);
        clientManager.update(clientId, client).then((x) => {
            console.log("SAVING SUCCESSFUL");
            clientManager.getClientById(clientId).then(client => {
                console.log("RELOADING CLIENT", client);
                setClientData(client);
                metaManager.getList().then(meta => {
                    console.log("ALL DONE");
                    setMetaData(meta);
                    setSaved(true);
                    onShowAlert();
                    setLoading(false);
                });
            });
        });
    };

    return (
        <LoadingOverlay active={loading} spinner={<BounceLoader />} styles={{
            overlay: base => ({...base, background: "rgba(91, 134, 96, 0.5)"}),
            content: base => ({marginLeft: "auto",marginRight: "auto",textAlign: "center" })
        }} text="Processing...">
        <Container fluid={true}>
        <Helmet><title>StayMetrics Admin - Edit Client</title></Helmet>
        <Row>
            <Col lg={12}>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/client-portal/clients/list">Client List</Breadcrumb.Item>
                    <Breadcrumb.Item href="/client-portal/clients/edit">Edit Client</Breadcrumb.Item>
                    <Breadcrumb.Item >{clientData.clientName}</Breadcrumb.Item>

                </Breadcrumb>
            </Col>
        </Row>
            <h2>Edit Client</h2>
        <ClientForm saved={saved}
                    clientsData = {clients}
                    statusData = {clientStatusData}
                    workspaceData={workspaces}
                    userData={userData}
                    telnyxData={telnyxData}
                    metaData={metaData}
                    clientData={clientData}
                    clientUpdate={(e)=> handleUpdate(clientId, e) }
        />
</Container></LoadingOverlay>);
}
