import Meta from "models/meta";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export default class ClientService {
  private client: AxiosInstance;

  constructor(token: string) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_BASEURL,
      headers: {
        "content-type": "application/json",
        "Authorization": "bearer " + token
      }
    };

    this.client = axios.create(config);
  }

  getList(): Promise<Meta[]> {
    return this.client.get<Meta[]>('api/v2/clientmetatype')
      .then(x => x.data)
      .catch(e => {
        return [];
      })
  }

  getTelnyxList(): Promise<any[]> {
    return this.client.get<Meta[]>('api/v2/telnyxmessageprofile')
      .then(x => x.data)
      .catch(e => {
        return [];
      })
  }

}
