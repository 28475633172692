import React from "react";
import { Col, Container, Row} from "react-bootstrap";
import QuestionInfo from "components/survey_definition/question/question_form/info";
import {ItemTypeForm} from "components/survey_definition/question/question_form/item_type/index";
import MatrixType from "./matrixType";
import QuestionNotes from "./notes";
import ResponseSet from "./responseSet";
import QuestionWordings from "./wording";
import QuestionMetaData from "./metaData";
import ResponseSetChoices from "./choices";
import { Helmet } from 'react-helmet'

const QuestionForm : React.FC<QuestionFormParameters>= ({formType, isLoading}) => {
  return ( isLoading ? <h1>Loading</h1> :
    <Container fluid={true}>
      { formType === 'new'
      ? <Helmet><title>StayMetrics Admin - Add Question</title></Helmet>
      : <Helmet><title>StayMetrics Admin - Edit Question</title></Helmet>}

      <Row>
        <Col lg={4}>
          <QuestionInfo formType={formType} />
          <QuestionWordings />
        </Col>
        <Col lg={4}>
          <fieldset className="border p-2">
            <legend className="w-auto">Question Response Set</legend>
            <ResponseSet />
            <ResponseSetChoices />
          </fieldset>

        </Col>
        <Col lg={4}>
          <fieldset className="border p-2">
            <MatrixType />
          </fieldset>
          <fieldset className="border p-2">
            <ItemTypeForm />
          </fieldset>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <QuestionMetaData />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <QuestionNotes />
        </Col>
      </Row>

    </Container>
  );
};
interface QuestionFormParameters{
  formType: 'new' | 'existing';
  isLoading: boolean;
}
export default QuestionForm;
