import React, { useState, useEffect} from "react";
import { Nav, Container, Breadcrumb, Row, Tab, Col, FormControl, InputGroup } from "react-bootstrap";
import ClientForm from "../client_portal/client_components/ClientForm"
import { Helmet } from 'react-helmet'
import ClientManager from "managers/clientManager";
import MetaManager from "managers/metaManager";
import PowerBiReportManager from "managers/powerBiReportManager";

export default function NewClient(props) {
    let [clients, setClients] = useState(false);
    let [clientData, setClientData] = useState(false);
    let [clientStatusData, setClientStatusData] = useState(false);
    let [workspaces, setWorkspaces] = useState(false);
    let [metaData, setMetaData] = useState(false);
    let [userData, setUserData] = useState(false);
    let [telnyxData, setTelnyxData] = useState(false);
    let [loading, setLoading] = useState(false);
    let [saved, setSaved] = useState(false);

    let clientManager = new ClientManager(props.currentuser.smToken);
    let metaManager = new MetaManager(props.currentuser.smToken);
    let powerBiReportManager = new PowerBiReportManager(props.currentuser.smToken);

    useEffect(() => {
        setLoading(true);
        let telnyx = metaManager.getTelnyxList().then(telnyx => {return telnyx});
        let meta = metaManager.getList().then(meta => {return meta});
        let users = clientManager.getUsersList().then(users => {return users});
        let workspaces = powerBiReportManager.getWorkspaces().then(workspaces => {return workspaces});
        let status = clientManager.getStatusList().then(status => {return status});
        let clients = clientManager.getParentClientList().then(clients => {return clients});
        let thresholds = clientManager.getThresholdsList().then(thresholds => {return thresholds});

        Promise.all([telnyx,meta,users, workspaces, status, clients,thresholds]).then((values)=>{
            values[3] = values[3].map(item => ({ label: item.name, value: item.id }));
            values[5] = values[5].map(item => ({ label: item.clientName, value: item.id }));
            values[0] = values[0].map(item => ({ label: item.name, value: item.id }));

            let tempClientData = {};
            tempClientData.interventionThreshold = values[6];

            let tempData = values[5];
            tempData.unshift({label:"No Parent", value: 0});

            setClientData(tempClientData);
            setClients(tempData);
            setClientStatusData(values[4]);
            setWorkspaces(values[3]);
            setUserData(values[2]);
            setMetaData(values[1]);
            setTelnyxData(values[0]);
            setLoading(false);
        });
    }, []);

    const onShowAlert = ()=>{
        window.setTimeout(()=>{
            setSaved(false);
        },2000)
    }
    const handleSave = (client) => {
        console.log("SAVING",client);
        setLoading(true);

        //turn those key/value pair objects from select boxes into values for api
        client.telnyxMessageProfileId = client.telnyxMessageProfileId.value;
        client.powerBiDesignerUserId = client.powerBiDesignerUserId.value;
        client.surveyDesignerUserId = client.surveyDesignerUserId.value;
        client.clientSolutionAnalystUserId = client.clientSolutionAnalystUserId.value;
        client.crmAssignedUserId = client.crmAssignedUserId.value;
        client.salesPersonUserId = client.salesPersonUserId.value;
        client.powerBiWorkspaceId = client.powerBiWorkspaceId.value;
        client.clientStatus = client.clientStatus.value;
        client.parentId = client.parentId.value;
        client.ongoingSurveyDeliveryMethodId = client.ongoingSurveyDeliveryMethodId.value;
        client.billingCountry = client.billingCountry.value;
        client.billingState = client.billingState.value;

        clientManager.insert(client).then((x) => {
            console.log("SAVING SUCCESSFUL");
                setSaved(true);
                onShowAlert();
                setLoading(false);

                document.location = "/client-portal/clients/list"
        });
    };
    return (
        <Container fluid={true}>
        <Helmet><title>StayMetrics Admin - New Client</title></Helmet>
        <Row>
            <Col lg={12}>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/client-portal/clients/list">Client List</Breadcrumb.Item>
                    <Breadcrumb.Item href="/client-portal/clients/new">New Client</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
        </Row>

            <h2>New Client</h2>
            <ClientForm
                    saved={saved}
                    clientData={clientData}
                    clientsData = {clients}
                    statusData = {clientStatusData}
                    workspaceData={workspaces}
                    userData={userData}
                    telnyxData={telnyxData}
                    metaData={metaData}
                    clientUpdate={(e)=> handleSave(e) }/>
</Container>);
}
