import React from 'react';
import {useLocation} from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const DisplayFormikState = props =>{
  let display = useQuery().get("debug");
  let controlStyle = {
    margin: '1rem 0',
    display: display ? 'block' : 'none'
  };
  let codeStyle = {
    background: '#f6f8fa',
    fontSize: '.65rem',
    padding: '.5rem',
  };
  return (
  <div style={controlStyle}>
    <pre
      style={codeStyle}
    >
      <strong>props</strong> ={' '}
      {JSON.stringify(props, null, 2)}
    </pre>
  </div>);
}
