import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, } from "axios";
import { SurveyResponse } from "models/surveyResponse/surveyResponse";
import { DownloadResponseRequestDocument } from "models/surveyResponse/downloadResponseRequestEntity";

export default class SurveyResponseService {
  private client: AxiosInstance;

  constructor(token: string) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_BASEURL,
      headers: {
        "content-type": "application/json",
        "Authorization": "bearer " + token
      }
    };

    this.client = axios.create(config);
  }

  getSurveyResponses(filter_problems: boolean, problem_ids: Array<number>, search: string): Promise<Array<SurveyResponse>> {
    let url = filter_problems ? 'api/v2/survey-response/problems' : 'api/v2/survey-response';
    return this.client.get<SurveyResponse[]>(url)
      .then(x => {
        return x.data;
      })
      .catch(e => {
        console.log(e);
        return [];
      });
  }
  fixResponseAction(items: Array<number>): Promise<boolean> {
    let params = this.getIdsUrl(items);
    return this.client.put(`api/v2/survey-response?${params}`)
      .then(x => {
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  }
  deleteResponseAction(items: Array<number>): Promise<boolean> {
    let params = this.getIdsUrl(items);
    return this.client.delete(`api/v2/survey-response?${params}`)
      .then(x => true)
      .catch(e => {
        console.log(e);
        return false;
      });
  }
  revalidateResponseAction(items: Array<number>): Promise<boolean> {
    let params = this.getIdsUrl(items);
    return this.client.post(`api/v2/survey-response/validate?${params}`)
    .then (_ => true)
    .catch (e => {
      console.log("error revalidating response Action", e);
      return false;
    });
  }
  redownloadResponseAction(items: Array<number>): Promise<boolean> {
    let params = this.getIdsUrl(items);
    return this.client.post(`api/v2/survey-response?${params}`)
    .then (_ => true)
    .catch (e => {
      console.log("error redownloading response Action", e);
      return false;
    });
  }
  downloadResponses(surveyIds: Array<number>, startDate: string, endDate: string): Promise<boolean> {
    let params = this.getIdsUrl(surveyIds);
    return this.client.post(`api/v2/survey-response/download/multiple?${params}&startDate=${(startDate)}&endDate=${(endDate)}`)
      .then (_ => true)
      .catch (e => {
        console.log("error downloading responses", e);
        return false;
      });
  }

  downloadSingleResponse(clientSurveyId: number, qualtricsProviderId: string): Promise<boolean> {
    return this.client.post(`api/v2/survey-response/download/single?surveyId=${clientSurveyId}&providerId=${qualtricsProviderId}`)
      .then (_ => true)
      .catch (e => {
        console.log("error downloading single response", e);
        return false;
      });
  }

  listDownloadResponseRequests(): Promise<DownloadResponseRequestDocument[]> {
    return this.client
      .get<DownloadResponseRequestDocument[]>("api/v2/survey-response/requests")
      .then(({ data }: AxiosResponse<DownloadResponseRequestDocument[]>) => {
        return data;
      })
      .catch((e: AxiosError) => {
        console.log("error listing download response requests", e);
        throw e;
      });
  }

  private getIdsUrl(items: Array<number>): String {
    return items.map(item => `ids=${item}`).join('&');
  }

  resubmitDownloadRequest(downloadRequest: any): Promise<boolean> {
    return this.client.post("api/v2/survey-response/requests", downloadRequest)
      .then(_ => true)
      .catch((e: AxiosError) => {
        console.log("error resubmit download request", e.response.data);
        return false;
      });
  }
}
