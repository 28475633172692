import React, {FC} from "react"
import {Col, Container, Row} from "react-bootstrap";
import Switch from "react-switch";
import DatePicker from "react-datepicker";

interface IServicesPane {
  store: any;
  dispatch: DispatchCallback;
}

const ServicesPane: FC<IServicesPane> = ({store, dispatch}) =>
  <Container>
    <Row className="serviceRow">
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Service Start Date</span>
          <DatePicker
            className="serviceDate"
            dateFormat="MM/dd/yyyy"
            selected={store.clientInfo.serviceStartDate}
            onChange={(e) => dispatch({type: 'setData', field: 'serviceStartDate', payload: e})}
          />
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Service End Date</span>
          <DatePicker
            className="serviceDate"
            dateFormat="MM/dd/yyyy"
            selected={store.clientInfo.serviceEndDate}
            onChange={(e) => dispatch({type: 'setData', field: 'serviceEndDate', payload: e})}
          />
        </label>
      </Col>
    </Row>
    <Row className="serviceRow">
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">First Impressions</span>
          <Switch checked={store.clientInfo.firstImpressionSurvey || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'firstImpressionSurvey', payload: e})}/>
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">7 Day Count</span>
          <input
            value={store.clientInfo.sevenDayCount || 0}
            disabled={!store.clientInfo.firstImpressionSurvey}
            type="number"
            max="0"
            className="form-control"
            onChange={(e) => dispatch({
              type: 'setData',
              field: 'sevenDayCount',
              payload: -Math.abs(Number(e.currentTarget.value))
            })}
            name="firstName"
          />
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Early Experience</span>
          <Switch checked={store.clientInfo.earlyExperienceSurvey || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'earlyExperienceSurvey', payload: e})}/>
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">45 Day Count</span>
          <input
            type="number"
            max="0"
            disabled={!store.clientInfo.earlyExperienceSurvey}
            value={store.clientInfo.fourtyFiveDayCount || 0}
            onChange={(e) => dispatch({
              type: 'setData',
              field: 'fourtyFiveDayCount',
              payload: -Math.abs(Number(e.currentTarget.value))
            })}
            className="form-control"
            name="firstName"
          />
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">90 / 120 Day Survey</span>
          <Switch checked={store.clientInfo.ninetyDaySurvey || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'ninetyDaySurvey', payload: e})}/>
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Exit Survey</span>
          <Switch checked={store.clientInfo.exitSurvey || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'exitSurvey', payload: e})}/>
        </label>
      </Col>
    </Row>
    <Row className="serviceRow">
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Ongoing Survey</span>
          <Switch checked={store.clientInfo.ongoingSurvey || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'ongoingSurvey', payload: e})}/>
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Wellness Training</span>
          <Switch checked={store.clientInfo.wellnessTraining || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'wellnessTraining', payload: e})}/>
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Safety Training</span>
          <Switch checked={store.clientInfo.safetyTraining || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'safetyTraining', payload: e})}/>
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Drive First</span>
          <Switch checked={store.clientInfo.driveFirst || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'driveFirst', payload: e})}/>
        </label>
      </Col>
    </Row>
    <Row className="serviceRow">
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Mobile App</span>
          <Switch checked={store.clientInfo.mobileApp || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'mobileApp', payload: e})}/>
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Rewards Program</span>
          <Switch checked={store.clientInfo.rewardsProgram || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'rewardsProgram', payload: e})}/>
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Twegos</span>
          <Switch checked={store.clientInfo.twegos || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'twegos', payload: e})}/>
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Predictive Index</span>
          <Switch checked={store.clientInfo.predictiveIndex || false}
                  onChange={(e) => dispatch({type: 'setData', field: 'predictiveIndex', payload: e})}/>
        </label>
      </Col>
    </Row>
    <Row className="serviceRow">

    </Row>
  </Container>

export default ServicesPane;
