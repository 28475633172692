import React, {useEffect, useState} from 'react';
import {Button, Container, Tab, Tabs} from "react-bootstrap";
import QuestionForm from "components/survey_definition/question/question_form";
import QuestionReportingData from "components/survey_definition/question/question_reporting_data";
import QuestionManager from "managers/survey_definition/questionManager";
import DefinitionService from "services/survey_definition/definitions";
import SupportingDataService from "services/survey_definition/supportingData";
import {DisplayFormikState} from "../../../helpers";
import {QuestionReducerContext} from "../questionReducerContext";

const QuestionFormTabs = (props) => {
  const [currentTab, setCurrentTab] = useState('main');
  const token = props.currentuser.smToken;
  const manager = new QuestionManager(token);
  const definitionService = new DefinitionService(token);
  const supportingDataService = new SupportingDataService(token);
  const questionId = props.match.params.questionId;
  const formType = questionId === undefined ? 'new' : 'existing';
  const [store, dispatch] = React.useReducer(manager.manageQuestionState, {isLoading: true, isSubmitting: false, touched: {}, errors: {}});
  const submitForm = () => {
    dispatch('submitting');
    if(formType === 'new'){
      manager.insert(store.question)
        .then(success => {
          afterSave(success);
        });
    }
    else{
      Promise.all([
        manager.update(questionId, store.question, null),
        manager.updatedReporting(questionId, store.reportingData)
      ])
        .then(results => {
          afterSave(results[0] && results[1])
        });
    }
  }

  const afterSave = (success: boolean) => {
    dispatch('ready')
    if (success) {
      window.location.href = '/survey-definition/question';
    } else {
      alert("Saving Failed");
    }
  }

  const getAllData = async () => {
    const [def, constructs, supportingDatums] = await Promise.all([
      definitionService.retrieve(),
      manager.getConstructsList(),
      supportingDataService.retrieve()
    ]);

    dispatch({type:'setSurveyItemDefinition', payload:def.surveyItem});
    dispatch({type:'setSurveyConstructs', payload:constructs});
    dispatch({type:'setSupportingDatums', payload:supportingDatums});
    if (formType === 'new') {
      let q = QuestionManager.blankModel();
      def.surveyItem.surveyTypes.forEach(d => {
        q.itemTypesBySurveyTypes.push({group: d.group, changedFlag: 1, surveyTypeId: d.id, isActive: true, name: d.name})
      });
      dispatch({type: 'setQuestion', payload: q});
      dispatch({type: 'setReportingData', payload: {variable_name:"", constructName: "", report_category: ""}})
    }
    else {
      const [question, reportingData] = await Promise.all([manager.getById(questionId), manager.getReportingById(questionId)]) ;
      dispatch({type: 'setQuestion', payload: question});
      dispatch({type: 'setReportingData', payload: reportingData});
    }
  };
  const initialComponentLoad = () => {
    getAllData().then(() => dispatch('ready'));
  };

  useEffect(initialComponentLoad, []);

  return(
    <QuestionReducerContext.Provider value={{store, dispatch}}>
      <Container fluid={true}>
        <Tabs id="question-tabs" activeKey={currentTab} onSelect={k => setCurrentTab(k)}>
          <Tab eventKey="main" title="Main Data">
            <QuestionForm formType={formType} isLoading={store.isLoading} />
          </Tab>
          {formType === 'existing'
            ?
            <Tab eventKey="other" title="Reporting Data">
              <QuestionReportingData />
            </Tab>
            :
            ''
          }

        </Tabs>
        <Button disabled={store.isSubmitting} variant="success" size="lg" type="submit" onClick={submitForm}>Submit</Button>
        <DisplayFormikState question={store.question} reportingData={store.reportingData} />
      </Container>
    </QuestionReducerContext.Provider>
  );

};

export default QuestionFormTabs;
