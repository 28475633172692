import ClientService from "services/clientService";
import Client from "models/client";

export default class ClientManager {
  private clientService: ClientService;

  constructor(token: string) {
    this.clientService = new ClientService(token);
  }

  //depreciated.... use getNewList
  getList(): Promise<Client[]> {
    return this.clientService.getList()
      .then(x => {
        x.sort((a, b) => (a.name > b.name) ? 1 : -1);
        return x
      });
  }

  getParentClientList(): Promise<Client[]> {
    return this.clientService.getListNew()
      .then(x => {
        x.sort((a, b) => (a.name > b.name) ? 1 : -1);
        return x
      });
  }

  getUsersList(): Promise<any> {
    return this.clientService.getUsersList().then(x => {
        x.sort((a, b) => (a.lastNname > b.lastName) ? 1 : -1);
        return x.map(item => ({ label: item.firstName + " " + item.lastName, value: item.id }));
    });
  }

  getThresholdsList(): Promise<any> {
    return this.clientService.getThresholdsList().then(x => {
        return x;
    });
  }

  getStatusList(): Promise<any> {
    return this.clientService.getStatusList().then(x => {
        return x.map(item => ({ label: item.name, value: item.id}));;
    });
  }

  getListNew(): Promise<any[]> {
    return this.clientService.getListNew()
      .then(x => {
        x.sort((a, b) => (a.clientName > b.clientName) ? 1 : -1)
        return x
      });
  }
  getClientById(id: number): Promise<Client> {
    return this.clientService.getClientById(id).then((x: Client) => this.transformClientFromApi(x))
  }

  insert(client: Client): Promise<boolean> {
    let document = this.transformClientForApi(client);
    return this.clientService.insertClient(document)
      .then(result => result);
  }

  private transformClientForApi(client: Client) : Client{
    return {
      ...client
    }
  }

  private transformClientFromApi(client: Client) : Client{
    return {
      ...client
    }
  }

  update(id: string, client: Client): Promise<boolean> {
    let document = this.transformClientForApi(client);
    return this.clientService.updateClient(id, document).then((result: boolean) => result)
  }

  static blankObject(): Client {
    return {
      id: "",
      name: "",
      powerBiWorkspaceId: "",
      stayMetricsId: 0,
      clientName: "",
      status: "",
      interventionThreshold: [
        {
          categoryName: "",
          clientSalesforceId: "",
          defaultIsEnabled: false,
          defaultThresholdValue: 0,
          interventionTypeId: 0,
          isEnabled: 0,
          longDesc: "",
          shortDesc: "",
          sortWithinCategory: 0,
          thresholdValue: 0,
        }
      ],
      smsHoursStartMonday: null,
      smsHoursStopMonday: null,
      smsHoursStartTuesday: null,
      smsHoursStopTuesday: null,
      smsHoursStartWednesday: null,
      smsHoursStopWednesday: null,
      smsHoursStartThursday: null,
      smsHoursStopThursday: null,
      smsHoursStartFriday: null,
      smsHoursStopFriday: null,
      smsHoursStartSaturday: null,
      smsHoursStopSaturday: null,
      smsHoursStartSunday: null,
      smsHoursStopSunday: null,
    };
  }

}
