import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { Helmet } from 'react-helmet'
import ClientManager from "managers/clientManager";
import PowerBiReportManager from "managers/powerBiReportManager";

export default function ListClients(props) {

    let [loading, setLoading] = useState(false);
    let [clientList, setClientList] = useState([]);

    let clientManager = new ClientManager(props.currentuser.smToken);
    let powerBiReportManager = new PowerBiReportManager(props.currentuser.smToken);

    useEffect(() => {
        console.log("sdfffsdfdsfsdfsdfsdfs");
        setLoading(true);
        let users = clientManager.getUsersList().then(users => {return users});
        let workspaces = powerBiReportManager.getWorkspaces().then(workspaces => {return workspaces});
        let status = clientManager.getStatusList().then(status => {return status});
        let clients = clientManager.getListNew().then(clients => {return clients});

        Promise.all([users, workspaces, status, clients]).then((values)=>{
            console.log("clients", values[3])
            let temp = [];

            for (const [key, value] of Object.entries(values[3])) {
                
                 let crm = values[0].find(user => user.value === value.crmAssignedUserId);
                 let status = values[2].find(status => status.value === value.clientStatus);
                 let ws = values[1].find(ws => ws.id === value.powerBiWorkspaceId);
                 
                 value.crm = crm && crm.label || "";
                 value.status = status && status.label || "";
                 value.ws = ws && ws.name || "";

                temp.push(value);
              }

            setClientList(temp);
           
            setLoading(false);
        });

    }, []);

    const { SearchBar } = Search;
    
    const columns = [
    {
        dataField: "id",
        text: "Id",
        filter: textFilter(),
        sort: true,
        headerStyle: () => {
            return { width: '5%' };
        },
    },
    {
        dataField: "clientName",
        text: "Client Name",
        filter: textFilter(),
        sort: true,
        headerStyle: () => {
            return { width: '12%' };
        },
    },
    {
        dataField: "legacyId",
        text: "Legacy Id",
        filter: textFilter(),
        sort: true,
        headerStyle: () => {
            return { width: '5%' };
        },
    },
    {
        dataField: "driveCountStart",
        text: "Driver Start Count",
        sort: true,
        headerStyle: () => {
            return { width: '5%' };
        },
    },  
    {
        dataField: "crm",
        text: "CRM",
        sort: true,
        filter: textFilter(),
        headerStyle: () => {
            return { width: '10%' };
        },
    },   
    {
        dataField: "status",
        text: "Client Status",
        sort: true,
        filter: textFilter(),
        headerStyle: () => {
            return { width: '5%' };
        },
    },
    {
        dataField: "ws",
        text: "PowerBI Workspace",
        sort: true,
        filter: textFilter(),
        headerStyle: () => {
            return { width: '5%' };
        },
    },    
    {
        text: "",
        dataField: "",
        formatter:actionFormatter,
        headerStyle: () => {
            return { width: "10%" };
        },
    },
    ];

    function actionFormatter(cell, row) {
        return (<>
            {/* <Link className="btn btn-sm btn-secondary" to={"/client-portal/clients/view/" + row.id}>View</Link>
            &nbsp; */}
            <Link className="btn btn-sm btn-primary" to={"/client-portal/clients/edit/" + row.id}>Edit</Link>
        </>);
    }

    return (
    <Container fluid={true}>
    <Helmet><title>StayMetrics Admin - Client List</title></Helmet>
    <Row>
        <Col lg={12}>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Clients</Breadcrumb.Item>
            <Breadcrumb.Item href="#">List</Breadcrumb.Item>
        </Breadcrumb>
        </Col>
    </Row>

    <Row>
        <Col lg={6}><h2>Client Listings</h2></Col>
        <Col lg={6} className="text-right">
            <Link className="btn btn-primary" to="/client-portal/clients/new">New Client</Link>
        </Col>
    </Row>
    <LoadingOverlay
        active={loading}
        spinner={<BounceLoader />}
        styles={{
            overlay: (base) => ({
            ...base,
            background: "rgba(91, 134, 96, 0.5)",
            }),
            content: (base) => ({
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            }),
        }}
        text="Loading...">
        <>
            <Row>
            <Col lg={12}>
            <ToolkitProvider
                keyField="id"
                data={clientList}
                columns={columns}>
                {(props) => (
                    <>
                        <Row>
                            <Col lg={12}>
                                    <BootstrapTable
                                    {...props.baseProps}
                                    filter={ filterFactory() }
                                    pagination={paginationFactory()}
                                    />
                            </Col>
                        </Row>
                    </>
                )}
            </ToolkitProvider>
            </Col>
        </Row>
        </>
    </LoadingOverlay>
    </Container>
    );
}

