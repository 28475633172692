import React from "react";
import { Alert } from "react-bootstrap";
import { PipelineRunState } from "./PipelineRunState";

const PipelineStateWarning : React.FC<PipelineStateWarningParameters> = ({show, pipelineRunState, onClose}) => {
  return (
    <>
    {show &&
    <Alert variant={pipelineRunState.variant} onClose={onClose} dismissible>
      <Alert.Heading>{pipelineRunState.title}</Alert.Heading>
      <p>{pipelineRunState.message}</p>
    </Alert>
    }
    </>
  );
};
interface PipelineStateWarningParameters {
  show: boolean;
  onClose();
  pipelineRunState: PipelineRunState;
}

export default PipelineStateWarning;