import React, { useState, useEffect } from "react";
import { Breadcrumb, Col, Container, Row, Toast } from "react-bootstrap";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import DownloadSingleQualtricsResponse from "./downloadSingleQualtricsResponse";
import DownloadMultipleQualtricsResponses from "./downloadMultipleQualtricsResponses";
import DownloadResponseRequests from "./downloadResponseRequests";
import SurveyResponseManager from "managers/survey_response/surveyResponseManager";
import SurveyService from "services/survey_definition/survey";
import {DownloadResponseRequestDocument} from "models/surveyResponse/downloadResponseRequestEntity";
import {ClientSurveyTemplate} from "models/clientSurvey";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {CloudDownload} from 'react-bootstrap-icons';

let DownloadQualtricsResponses = ({currentuser}) => {
  const [clientSurveys, setClientSurveys] = useState<ClientSurveyTemplate[]>([]);
  const [loading, setLoading] = useState(false);
  const [downloadRequests, setDownloadRequests] = useState<DownloadResponseRequestDocument[]>([]);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  
  const token = currentuser.smToken;
  let surveyService = new SurveyService(token);
  let surveyResponseManager = new SurveyResponseManager(token);

  const getDownloadRequests = () => {
    surveyResponseManager.listDownloadResponseRequests().then((data) =>{
      setDownloadRequests(data);
    });
  }
  
  useEffect(() => {
    setLoading(true);
    getDownloadRequests();
    setInterval(getDownloadRequests, 10000); // runs every 10 seconds.
    surveyService.list().then((surveys) => {
      setClientSurveys(surveys);
      setLoading(false);
    });
  }, []);

  return (
    <LoadingOverlay
    active={loading}
    spinner={<BounceLoader />}
    styles={{
      overlay: (base) => ({
        ...base,
        background: 'rgba(91, 134, 96, 0.5)',
      }),
      content: (base) => ({
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center'
      }),
    }}
    text='Loading...'
  >    
    <Container fluid={true}>
        <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Qualtrics Download</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
      <div className="menuFilterHolder">
          <CloudDownload onClick={(e)=>{setIsPaneOpen(true);}} className="iconStyle" size={48}/>
          <span>Download Surveys</span>
      </div>
      <SlidingPane
            className="slidePaneMenu"
            overlayClassName="some-custom-overlay-class"
            isOpen={isPaneOpen}
            from="left"
            width="700px"
            title="Exit"
            onRequestClose={() => {
              setIsPaneOpen(false);
            }}
          >
      <Col lg={12}>
        <DownloadMultipleQualtricsResponses handleClose={e=>setIsPaneOpen(false)} handleToast={e=>{setShowToast(e)}} smToken={token} surveys={clientSurveys} />
        <DownloadSingleQualtricsResponse handleClose={e=>setIsPaneOpen(false)} handleToast={e=>{setShowToast(e)}} smToken={token} surveys={clientSurveys} />
      </Col>
      </SlidingPane>
      <Col lg={12}>
        <DownloadResponseRequests smToken={token} downloadRequests={downloadRequests} handleToast={setShowToast} />
      </Col>
      </Row>
      <Toast className="successToast" onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Header>
                  <strong className="mr-auto">Processing</strong>
                  <small>Just Now</small>
          </Toast.Header>
          <Toast.Body>Woohoo, your request has been queued.</Toast.Body>
      </Toast>
    </Container>
    </LoadingOverlay>
  );
};

export default DownloadQualtricsResponses;
