import React, { useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Breadcrumb, Col, Container, Row, Button, Alert } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import SurveyManager from "managers/survey_definition/surveyManager";

import DistributionManager from "managers/survey_distribution/surveyDistributionManager";
import { Helmet } from 'react-helmet'
import {People,TextCenter, Phone} from "react-bootstrap-icons";
import Select from "react-select";

import RenderSelection from "./renderSelection";

const reducer = (store, action) =>{
    switch(action.type){
        case 'setData':
            return {...store, [action.field]: action.payload}
        case 'selectionPlanAdd':
            return {...store, selectionPlan: [...store.selectionPlan, action.payload]}
        case 'selectionPlanDelete':
            let temp = store.selectionPlan;
                temp.splice(action.payload, 1);
            return {...store, selectionPlan: temp}
        default :
            return initialState;
    }
}

    const initialState = {
        selectionComponents: "", // master list of components
        selectionOptions: "",    // a list of components mapped from selectionComponents (for the drop down)
        selectedOption: "",      // the selected value of dropdown
        loadComponent: "",       // the component "Render" is to load
        clientList: [],
        client: "",
        clientId: 0,
        surveyId: "",
        selectionCriteria: [],
        selectionPlan: [],
        selectionPlanName: "",
        selectionPlanId: 0
    }

export default function SelectionPlan(props) {
    let distributionManager = new DistributionManager(props.currentuser.smToken);
 
    let [loading, setLoading] = useState(false);
    const [store, dispatch] = React.useReducer(reducer, initialState);
    let [clientError, setClientError] = useState(false);
    let [usage, setUsage] = useState([]);

    useEffect(()=>{

        distributionManager.getSelectionsCriteria().then((criteria) => {
            let selectionOptions = criteria.map(item => ({ label: item.name, value: item.variable }));
            dispatch({type: 'setData', field: 'selectionComponents', payload: criteria});
            dispatch({type: 'setData', field: 'selectionOptions', payload: selectionOptions});
        }); 
        
        if (props.match.params.selectionPlanId){
            setLoading(true);
            distributionManager.getSelectionPlansById(props.match.params.selectionPlanId).then((selectionPlan) => {
                console.log("selectionPlan", selectionPlan);
                dispatch({type: 'setData', field: 'clientId', payload: selectionPlan.clientId});
                dispatch({type: 'setData', field: 'selectionPlan', payload: selectionPlan.criteria});
                dispatch({type: 'setData', field: 'selectionPlanId', payload: selectionPlan.id});
                dispatch({type: 'setData', field: 'selectionPlanName', payload: selectionPlan.name});


                distributionManager.getSelectionPlansUsage(props.match.params.selectionPlanId).then((usage) => {
                    setUsage(usage);
                    console.log("usage", usage);
                    setLoading(false);
                });
             });
        }
    }, []);

    const saveSelectionPlan = plan => {
        console.log("saving selection plan", plan);
        setLoading(true)
        distributionManager.saveSelectionPlans(plan).then(()=>{
            setLoading(false);
            window.location.href = "/survey-distribution/selections";
        });
    }  
    const updateSelectionPlan = plan => {
        console.log("update selection plan", JSON.stringify(plan));
        setLoading(true)
         distributionManager.updateSelectionPlans(props.match.params.selectionPlanId,plan).then(()=>{
             setLoading(false);
             window.location.href = "/survey-distribution/selections";
        });
    } 
    const ApiObjectBuilder = (mode) => {
        let tempObject = {};
        tempObject.id = store.selectionPlanId;
        tempObject.name = store.selectionPlanName;
        tempObject.criteria = store.selectionPlan;
        tempObject.clientId = store.selectionPlan.filter(obj => {return obj.clientId > 0; }).map(item => item.clientId)[0] || store.clientId;

        tempObject.criteria.forEach(item =>{
            if (Array.isArray(item.multipleValuesLabels)){
                let temp = [];
                item.multipleValuesLabels.forEach(i => {
                    temp.push(i.value);
                })
                item.multipleValues = temp;
            }
            else{
            }

        });
        
        console.log ("tempObject", tempObject);
        if (mode === "new"){
            saveSelectionPlan(tempObject);
        }
        else{
            updateSelectionPlan(tempObject);
        }

    }

    const ErrorCheck = () => {
        console.log("Error Check", store.selectionPlan);
        let ClientIds = store.selectionPlan
        .filter(obj => {return obj.clientId > 0; })
        .map(item => item.clientId);
       
        if(!ClientIds.every(item => item === ClientIds[0])){
            setClientError(true);    
        }
        else if(store.clientId !== 0 && !ClientIds.every(item => item === store.clientId)){
            setClientError(true); 
        }
        else{
            setClientError(false); 
        }
       
        console.log("!ClientIds.every(item => item === store.clientId", !ClientIds.every(item => item === store.clientId));
  
        
        
    }

    return(
        <Container fluid={true}>
        <LoadingOverlay text="Loading..." active={loading} spinner={<BounceLoader />} styles={{
            overlay: (base) => ({
                ...base,
                background: "rgba(91, 134, 96, 0.5)",
            }),
            content: (base) => ({
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
            }),
            }}> 
        <Helmet><title>StayMetrics Admin - Survey List</title></Helmet>
        <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/survey-distribution/">Survey Distribution</Breadcrumb.Item>
            <Breadcrumb.Item href="contact/new/">Selection Plan</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
        <Row className="mainRow">
            <Col lg={3}><h3>Selection Plan</h3></Col>
        </Row>
        <Row>
            <Col lg={12}>
                <Alert color="info" variant='warning' show={clientError} >You are using supporting data from different Clients</Alert>
            </Col>
            <Col lg={4}></Col>
            <Col lg={4}>
                <Row className="selectionPlanRow"> 
                <div className="list-type4">  
                    <div className="form-group">
                    <label>Name Of Plan</label>
                    <input
                        className="form-control"
                        name="selectionPlanName"
                        onChange={ (e) => dispatch({type: 'setData', field: 'selectionPlanName', payload: e.currentTarget.value})}
                        value={store.selectionPlanName}
                    />
                    </div>
                    <div className="form-group"> 
                        <label>Selection</label>
                        <Select
                            className="surveySelect"
                            options={store.selectionOptions}
                            isMulti={false}
                            value={store.selectedOption}
                            onChange={(e) => {
                                    let compData = store.selectionComponents.filter(item => item.variable === e.value)[0];
                                    dispatch({type: 'setData', field: 'loadComponent', payload: compData});
                                    dispatch({type: 'setData', field: 'selectedOption', payload: e})
                                }}
                            />
                            {store.loadComponent ?
                    <RenderSelection clientId={store.clientId} selectionPlanId={store.selectionPlanId} show={store.loadComponent} select={(e)=> {
                            console.log("store sent back", e);
                            let temp = store.selectionOptions.find(x => x.value === e.type);
                            e.typeLabel = temp.label;
                            let selectionPlan = store.selectionPlan;
                            selectionPlan.push(e);
                            dispatch({type: 'setData', field: 'selectionPlan', payload: selectionPlan});
                            dispatch({type: 'setData', field: 'selectedOption', payload:''});

                            ErrorCheck();    
                         }} 
                    /> : ''}
                    </div>
                    <div className="lowerLine">&nbsp;</div>
                    <ol>
                    {store.selectionPlan && store.selectionPlan.map ( (item,i)=> {   
                            if (Array.isArray(item.multipleValuesLabels) && item.multipleValuesLabels.length > 0){
                                console.log("item",item);
                                console.log("item.multipleValuesLabels",item.multipleValuesLabels);
                                                                
                                let mylist = item.multipleValuesLabels.map(x => x.label).join(", ");

                                return(<li key={i}>{item.typeLabel}: {item.operation} {mylist}
                                        <Button className="listItemButton" variant="danger" onClick={(e) => {
                                            dispatch({type: 'selectionPlanDelete', payload: i});
                                            ErrorCheck();
                                        }}>x</Button></li>)
                            }
                            else{
                                    return(<li key={i}>{item.typeLabel}: {item.operation} {item.singleValue}
                                            <Button className="listItemButton" variant="danger" onClick={(e) => {
                                                dispatch({type: 'selectionPlanDelete', payload: i});
                                                ErrorCheck();
                                            }}>x</Button></li>)
                            }
                        })
                    }
                    </ol>
                    {usage?.length > 0 ?
                    <div className="lowerLine">
                        <Col lg={12}> 
                            <h3>Warning</h3>
                            <p>You are also making changes to the following surveys</p>
                            {usage && usage.map ((item,i) => {
                                return (<div key={i}>
                                            <span className="block">{item.surveyDetails.clientName +  ': "' + item.surveyDetails.title + '"'}</span> 
                                            <span className="block">{"End Date: " + item.surveyDetails.endDate }</span>
                                            <span className="block">&nbsp;</span>
                                        </div>)})}                                    
                        </Col>
                    </div> : ''}
                                     
                    <div className="lowerLine">
                            <Col lg={12}>  
                                {!clientError && store.selectionPlanName !== "" && props.match.params.selectionPlanId === undefined
                                    ? <Button className="buttonMove" key="3" variant="success" onClick={(e)=>{
                                            ApiObjectBuilder("new");
                                        }}>Save</Button>
                                    : ''}
                                 {!clientError && store.selectionPlanName !== "" && props.match.params.selectionPlanId > 0
                                    ? <Button className="buttonMove" key="3" variant="success" onClick={(e)=>{
                                            ApiObjectBuilder("update");
                                        }}>Update</Button>
                                    : ''}                                   
                          </Col>
                    </div>
                </div>  
            </Row> 
            </Col>
            <Col lg={4}></Col>
        </Row>        
        </LoadingOverlay>
        </Container>
    )

}