import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { SurveyItem, SurveyItemReporting } from "models/surveyItem";
import {SurveyItemConstruct} from "models/surveyItemConstruct";

class QuestionService {
  private client: AxiosInstance

  constructor(jwt: string) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_BASEURL,
      headers: {
        "content-type": "application/json",
        "Authorization": "bearer " + jwt
      }
    }
    this.client = Axios.create(config);
  }

  list(): Promise<SurveyItem[]> {
    return this.client.get<SurveyItem[]>("api/v2/survey-definition/survey-item")
      .then(({ data }: AxiosResponse<SurveyItem[]>) => data)
      .catch((er: any) => { console.log(er); return []; });
  }

  getById(questionId: string): Promise<SurveyItem> {
    const empty: SurveyItem = {
      approvalStatusId: 0,
      choices: [],
      id: 0,
      ignoreZeroBlank: false,
      includesNa: false,
      isMatrix: false,
      itemTypeId: 0,
      notes: "",
      questionNumber: "",
      ratingType: 0,
      responseSetDescription: "",
      responseSetTypeId: 0,
      reverseCoded: false,
      wordings: [],
      matrix: null,
      itemTypesBySurveyType: []
    }
    return this.client.get<SurveyItem>(`api/v2/survey-definition/survey-item/${questionId}`)
      .then(({ data }: AxiosResponse<SurveyItem>) => data)
      .catch((er: any) => { console.log(er); return empty; });
  }

  insert(question: SurveyItem): Promise<boolean> {
    return this.client.post<boolean>("api/v2/survey-definition/survey-item/", question)
      .then(() => true)
      .catch(() => false);
  }

  update(question: SurveyItem): Promise<boolean> {
    return this.client.put<boolean>(`api/v2/survey-definition/survey-item/${question.id}`, question)
      .then(() => true)
      .catch(() => false);
  }

  getReportById(questionId: string): Promise<SurveyItemReporting> {
    const empty : SurveyItemReporting = {
      constructName: "",
      reportCategory: "",
      variableName: ""
    }
    return this.client.get<SurveyItemReporting>(`api/v2/survey-definition/survey-item/${questionId}/reporting`)
      .then(({data}: AxiosResponse<SurveyItemReporting>) => data)
      .catch(() => empty);
  }

  updateReporting(questionId: string, question: SurveyItemReporting): Promise<boolean> {
    return this.client.put<boolean>(`api/v2/survey-definition/survey-item/${questionId}/reporting`, question)
      .then(() => true)
      .catch(() => false);
  }

  // returns a hard coded list of constructs. These get saved by name in SvDef.SurveyItemReporting:SurveyItemConstruct.
  getConstructsList() : Promise<SurveyItemConstruct[]>{
    return new Promise((resolve) => {
      resolve([
        {
          id: 1,
          name: "Accuracy",
        },
        {
          id: 2,
          name: "Commitment",
        },
        {
          id: 3,
          name: "Confidence in Carrier",
        },
        {
          id: 4,
          name: "Dispatcher",
        },
        {
          id: 5,
          name: "Learning",
        },
        {
          id: 6,
          name: "Life on the Road  ",
        },
        {
          id: 7,
          name: "Loads",
        },
        {
          id: 8,
          name: "Pay Fairness",
        },
        {
          id: 9,
          name: "Pay Processes",
        },
        {
          id: 10,
          name: "People I Work With",
        },
        {
          id: 11,
          name: "Recruiter Relationship",
        },
        {
          id: 12,
          name: "Safety",
        },
        {
          id: 13,
          name: "Work",
        },
        {
          id: 14,
          name: "Work/Life Balance",
        },
        { id: 15, name: "Engagement" },
        { id: 16, name: "Person I Report To" },
        { id: 17, name: "Training" },
        { id: 18, name: "Empowerment" },
        { id: 19, name: "Safety Infrastructure" },
        { id: 20, name: "Safety Enforcement" },
        { id: 21, name: "Safety Working" },
        { id: 22, name: "Equipment" },
      ]);
    });
  }

}
export default QuestionService;
