import React from "react";
import StandardMatrixType from "./matrix_forms/standard";
import SupportingDataMatrixType from "./matrix_forms/supportingData";
import {Form} from "react-bootstrap";
import {QuestionReducerContext} from "../questionReducerContext";

const MatrixType = (props) =>{
  const {store, dispatch} = React.useContext(QuestionReducerContext);
  let matrixTypes = [
    {id:1, description:"No Matrix"},
    {id:2, description:"Standard Matrix"},
    {id:3, description:"Supporting Data Matrix"}
  ];
  return (
    <>
      <legend className="w-auto">Question Matrix Types</legend>
      <Form.Group>
      <label>Select Type:</label>
      <Form.Control
        as='select'
        defaultValue={store.question.matrixType}
        isInvalid={store.touched.matrixType && (store.errors.matrixType || store.errors.matrixItems)}
        onChange={e=> dispatch({type:'setQuestionField', field:'matrixType', payload: Number(e.currentTarget.value)})}
      >
        <option value={0}>Select</option>
        {
          matrixTypes.map((option, index) => {
            return <option key={index} value={option.id}>{option.description}</option>
          })
        }
      </Form.Control>
        <Form.Control.Feedback type="invalid">
          {store.errors.matrixType}
        </Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
          {store.errors.matrixItems}
        </Form.Control.Feedback>
      </Form.Group>

      <StandardMatrixType
        display={store.question.matrixType === 2}
        className={`form-control ${store.errors.matrixItems ? "is-invalid" : ""}`}
      />
      <SupportingDataMatrixType
        display={store.question.matrixType === 3}
      />
    </>
  );
};

export default MatrixType;
