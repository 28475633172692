import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import DistributionManager from "managers/survey_distribution/surveyDistributionManager";
import { Helmet } from 'react-helmet'
import {Trophy} from "react-bootstrap-icons";

import "./surveyDistribution.css";

export default function SelectionList(props) {

  let distributionManager = new DistributionManager(props.currentuser.smToken);
  const [plans, setPlans] = useState([]);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    distributionManager.getSelectionPlans().then((plans) => {
      setPlans(plans);
      setLoading(false);
    }); 
  }, []);


  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: "",
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },  
    {
      dataField: "",
      text: "",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: '8%' };
      },
    }
  ]

  function actionFormatter(cell, row) {
    return (<Link className="btn btn-sm btn-secondary" to={"/survey-distribution/selections/edit/" + row.id} >Edit</Link>);
  }

  return (
    <Container fluid={true}>
      <Helmet><title>StayMetrics Admin - Selection Plan</title></Helmet>
      <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Survey Distribution</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Selection List</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
                      <Col lg={6}>
                      <h2>Selection List</h2>
                      </Col>
                      <Col lg={6} className="text-right">
                        <Link
                          className="btn btn-primary"
                          to="/survey-distribution/selections/new"
                        >
                          New Selection Plan
                        </Link>
                      </Col>
      </Row>
      <LoadingOverlay
        active={loading}
        spinner={<BounceLoader />}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(91, 134, 96, 0.5)",
          }),
          content: (base) => ({
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }),
        }}
        text="Loading...">
        <>
          <Row>
            <Col lg={12}>
              <ToolkitProvider
                keyField="id"
                data={plans}
                columns={columns}
              >
                {(props) => (
                  <>
                    <Row>
                      <Col lg={12}>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={ filterFactory() }
                          pagination={paginationFactory()}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        </>
      </LoadingOverlay>
    </Container>
  );
}
