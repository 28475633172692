import React from "react";
import SurveyService from "services/survey_definition/survey";
import QuestionManager from "managers/survey_definition/questionManager";
import { retrieveToken } from "utils";
import SurveyManager from "managers/survey_definition/surveyManager";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet'

class PrintView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyId: "",
      surveyData: {},
      questions: {},
    };
  }

  componentDidMount() {
    let token = retrieveToken();
    let questionManager = new QuestionManager(token);
    let surveyManager = new SurveyManager(token);
    let surveyData = SurveyService.getBlankSurvey();

    this.setState({ loading: true });
    this.setState({ surveyId: this.props.match.params.surveyId });
    surveyManager.getById(this.props.match.params.surveyId).then(x => {
      this.setState({ surveyData: x });
    });

    questionManager.getQuestions().then(x => {
      let question_options = [];
      if (x.length > 0) {
        x.forEach(item => {
          item.wordings.forEach(q => {
            question_options[q.id] = {
              surveyItemWordingId: q.id,
              value: q.id,
              question_number: item.questionNumber,
              label: q.text,
              default: q.default,
              surveyItemId: item.id,
              choices: item.choices
            };
          });
        });
        this.setState({ questions: question_options });
        this.setState({ loading: false });
      }
    });

  }

  render() {
    return (
      <Container fluid={true}>
       <Helmet><title>StayMetrics Admin - Survey Print</title></Helmet>
        <Row>
          <Col lg={12}>
            {this.state.surveyData.data &&
              this.state.surveyData.data.map((section, index) => {
                return (
                  <Row>
                    <Col lg={4}>
                      <Row>
                        <Col lg={11}>
                          <h3>{section.name}</h3>
                        </Col>
                      </Row>
                      {section.contents &&
                        section.contents.map((item, i) => {
                          let q = "";

                          if (this.state.questions[item.surveyItemWordingId] !== undefined)
                            q = this.state.questions[item.surveyItemWordingId];
                          console.log(q);

                          return (
                            <div key={item.id}>
                              <div>{i + 1}) {q.label}</div>
                              <ul id={item.id}>
                                {q.choices && q.choices.map((choice, index) => {
                                  return (
                                    <li key={index}>
                                      {choice.text}
                                    </li>
                                  );
                                }
                                )}
                                {q.choices && q.choices.length === 0 ? <li>Text Response</li> : null}
                              </ul>
                            </div>
                          )
                        })}

                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PrintView;
