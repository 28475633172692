import React from "react";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

const LoadingOverlayWrapper = (props: LoadingOverlayWrapperProps) => {
    return (
        <LoadingOverlay
        active={props.loading}
        spinner={<BounceLoader />}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(91, 134, 96, 0.5)",
          }),
          content: (base) => ({
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }),
        }}
        text="Loading..."
      >
          {props.children}
    </LoadingOverlay>
    );
};

interface LoadingOverlayWrapperProps{
    loading: boolean;
    children: JSX.Element;
}

export default LoadingOverlayWrapper;