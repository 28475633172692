import React from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {QuestionReducerContext} from "../../questionReducerContext";

const StandardMatrixType = (props) => {
  const {store, dispatch} = React.useContext(QuestionReducerContext);
  const style = {display: props.display ? 'block' : 'none'};
  const handleRemoveMatrixItem = (index) => {
    store.question.matrixItems.splice(index, 1);
    dispatch({
      type: 'setQuestionField',
      field: 'matrixItems',
      payload: store.question.matrixItems
    });
  };
  const handleAddNewMatrixItem = () => {
    dispatch({
      type: 'setQuestionField',
      field: 'matrixItems',
      payload: [...store.question.matrixItems, {
        id: 0,
        text: "",
        sort: "",
      }]
    })
  };
  const handleUpdateMatrixItem = (eventProps) => {
    store.question.matrixItems[eventProps.index].text = eventProps.text;
    store.question.matrixItems[eventProps.index].sort = eventProps.sort;
    dispatch({
      type: 'setQuestionField',
      field: 'matrixItems',
      payload: store.question.matrixItems
    });
  }
  let current = new Date().getTime();
  return (
    <div style={style}>
      {store.question?.matrixItems?.map((item, index) =>
        <MatrixItemRow
          key={index + current}
          index={index}
          item={item}
          onRemoveMatrixItem={handleRemoveMatrixItem}
          onUpdateMatrixItem={handleUpdateMatrixItem}
        />
      )}
      <Button
        variant="outline-success"
        onClick={handleAddNewMatrixItem}>New</Button>
    </div>
  )
};

const MatrixItemRow = (props) => {
  const {index, item, onRemoveMatrixItem, onUpdateMatrixItem} = props;
  const [sort, setSort] = React.useState(item.sort);
  const [text, setText] = React.useState(item.text);
  return <Row>
    <Col xs={2} lg={2}>
      <Form.Group>
        <Form.Label>Sort:</Form.Label>
        <Form.Control
          value={sort}
          onChange={e => setSort(Number(e.target.value))}
          onBlur={_ => onUpdateMatrixItem({index, sort, text})}
          placeholder="#"
          type="number"
          required
        />
      </Form.Group>
    </Col>
    <Col xs={8} lg={8}>
      <Form.Group>
        <Form.Label>Text:</Form.Label>
        <Form.Control
          placeholder="Enter Text"
          value={text}
          onChange={e => setText(e.target.value)}
          onBlur={_ => onUpdateMatrixItem({index, sort, text})}
          required
        />
      </Form.Group>
    </Col>
    <Col xs={2} lg={2}>
      <Form.Group>
        <Form.Label>&nbsp;</Form.Label>
        <Button
          className={"btn-block"}
          variant={"outline-danger"}
          onClick={_ => onRemoveMatrixItem(index)}
        >Delete</Button>
      </Form.Group>
    </Col>
  </Row>
};

export default StandardMatrixType;
