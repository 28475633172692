import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import SurveyDefinition from "models/surveyDefinition";

class DefinitionService {
  private client: AxiosInstance
  constructor(jwt: string) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_BASEURL,
      headers: {
        "content-type": "application/json",
        "Authorization": "bearer " + jwt
      }
    }
    this.client = Axios.create(config);
  }

  retrieve(): Promise<SurveyDefinition> {
    const empty: SurveyDefinition = {
      surveyItem: {
        approvalStatuses: [],
        itemTypes: [],
        responseSetTypes: [],
        surveyTypes: [],
        lastSmqUsed: undefined,
      }
    };

    return this.client.get<SurveyDefinition>("api/v2/survey-definition/definition")
      .then((definitions: AxiosResponse<SurveyDefinition>) => definitions.data)
      .catch((er: any) => { console.log(er); return empty; });
  }
}

export default DefinitionService;
