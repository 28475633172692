import React, {FC} from "react"
import {Col, Container, Row} from "react-bootstrap";
import Switch from "react-switch";
import Select from "react-select";

interface IRewardsPane{
  store: any;
  dispatch: DispatchCallback;
}

const RewardsPane : FC<IRewardsPane> = (props) => {
  const {store, dispatch} = props;
  const rewardsPlatformOptions = [
    {label:'None', value: null},
    {label:'Hinda', value: 1},
    {label:'RewardTrax', value: 2},
  ];
  return (
    <Container>
      <Row className="serviceRow">
        <Col className="centerColumn" lg={6}>
          <label>
            <span className="serviceSpan">Rewards URL</span>
            <input
              className="form-control"
              defaultValue={store.clientInfo.rewardsUrl || ""}
              onBlur={(e)=>{dispatch({type: 'setData', field: 'rewardsUrl', payload:e.currentTarget.value});}}
            />
          </label>
        </Col>
        <Col className="centerColumn" lg={3}>
          <span className="serviceSpan">Allow SMS Contact</span>
          <Switch checked={store.clientInfo.smsAllowed || false}
                  onChange={(e)=>{dispatch({type: 'setData', field: 'smsAllowed', payload: e});}} />
        </Col>
        <Col className="centerColumn" lg={3}>
          <span className="serviceSpan">Allow Email Contact</span>
          <Switch checked={store.clientInfo.emailAllowed || false}
                  onChange={(e)=>{dispatch({type: 'setData', field: 'emailAllowed', payload: e});}} />
        </Col>
      </Row>
      <Row className="serviceRow">
        <Col className="centerColumn" lg={3}>
          <label>
            <span className="serviceSpan">Ongoing Survey Points</span>
            <input
              type="number"
              className="form-control"
              defaultValue={store.clientInfo.ongoingSurveyPoints || ""}
              onBlur={(e)=>{dispatch({type: 'setData', field: 'ongoingSurveyPoints', payload: e.currentTarget.value});}}
            />
          </label>
        </Col>
        <Col className="centerColumn" lg={3}>
          <label>
            <span className="serviceSpan">Ongoing Survey Delivery</span>
            <Select
              onChange={(e)=>{dispatch({type: 'setData', field: 'ongoingSurveyDeliveryMethodId', payload: e});}}
              options={store.ongoingSurveyDeliveryData}
              value={store.clientInfo.ongoingSurveyDeliveryMethodId}
              isMulti={false}
            />
          </label>
        </Col>
        <Col className="centerColumn" lg={3}>
          <label>
            <span className="serviceSpan">Dispatcher Term</span>
            <input
              className="form-control"
              defaultValue={store.clientInfo.dispatcherTerm || ""}
              onBlur={(e)=>{dispatch({type: 'setData', field: 'dispatcherTerm', payload: e.currentTarget.value});}}
            />
          </label>
        </Col>
        <Col className="centerColumn" lg={3}>
          <label>
            <span className="serviceSpan">Ongoing Survey Frequency</span>
            <input
              type="number"
              className="form-control"
              defaultValue={store.clientInfo.ongoingSurveyFrequency || ""}
              onBlur={(e)=>{dispatch({type: 'setData', field: 'ongoingSurveyFrequency', payload: e.currentTarget.value});}}
            />
          </label>
        </Col>
      </Row>
      <Row className="serviceRow">
        <Col className="centerColumn" lg={3}>
          <span className="serviceSpan">Rewards Platform</span>
          <Select
            onChange={(e)=>{dispatch({type: 'setData', field: 'rewardsPlatformId', payload: e.value});}}
            options={rewardsPlatformOptions}
            value={rewardsPlatformOptions.find(option => option.value === store.clientInfo.rewardsPlatformId)}
            isMulti={false}
          />
        </Col>
        <Col className="centerColumn" lg={3}>
          <label>
            <span className="serviceSpan">Rewards API Key</span>
            <input
              className="form-control"
              defaultValue={store.clientInfo.rewardsApiKey || ""}
              onBlur={(e)=>{dispatch({type: 'setData', field: 'rewardsApiKey', payload: e.currentTarget.value});}}
            />
          </label>
        </Col>
        <Col className="centerColumn" lg={3}>
          <label>
            <span className="serviceSpan">Rewards API URL</span>
            <input
              className="form-control"
              defaultValue={store.clientInfo.rewardsApiUrl || ""}
              onBlur={(e)=>{dispatch({type: 'setData', field: 'rewardsApiUrl', payload: e.currentTarget.value});}}
            />
          </label>
        </Col>
        <Col className="centerColumn" lg={3}>
          <label>
            <span className="serviceSpan">Rewards API GUID</span>
            <input
              className="form-control"
              defaultValue={store.clientInfo.rewardsApiGuid || ""}
              onBlur={(e)=>{dispatch({type: 'setData', field: 'rewardsApiGuid', payload: e.currentTarget.value});}}
            />
          </label>
        </Col>
      </Row>
      <Row className="serviceRow">
        <Col className="centerColumn" lg={3}>
          <label>
            <span className="serviceSpan">Account Code</span>
            <input
              className="form-control"
              defaultValue={store.clientInfo.accountCode || ""}
              onBlur={(e)=>{dispatch({type: 'setData', field: 'accountCode', payload:e.currentTarget.value});}}
            />
          </label>
        </Col>
        <Col className="centerColumn" lg={3}>
          <label>
            <span className="serviceSpan">Rewards Send Hire Date</span>
            <Switch checked={store.clientInfo.rewardsSendHireDate || false}
                    onChange={(e)=>{dispatch({type: 'setData', field: 'rewardsSendHireDate', payload: e});}} />
          </label>
        </Col>
        <Col className="centerColumn" lg={3}>
          <label>
            <span className="serviceSpan">Remove Points On Rehire</span>
            <Switch checked={store.clientInfo.removePointsOnRehire || false}
                    onChange={(e)=>{dispatch({type: 'setData', field: 'removePointsOnRehire', payload: e});}} />
          </label>
        </Col>
        <Col className="centerColumn" lg={3}>
          <label>
            <span className="serviceSpan">Use Org Code</span>
            <Switch checked={store.clientInfo.useOrgCode || false}
                    onChange={(e)=>{dispatch({type: 'setData', field: 'useOrgCode', payload: e});}} />
          </label>
        </Col>
      </Row>
    </Container>
  );
}
export default RewardsPane;