import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Breadcrumb, Col, Container, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import SurveyResponseManager from "managers/survey_response/surveyResponseManager";

let SurveysResponses = ({currentuser}) => {
  const surveyResponseManager = new SurveyResponseManager(currentuser.smToken);
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [showProblems, setShowProblems] = useState(true);

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    style: { backgroundColor: "#298459" },
    onSelect: (row, isSelect) => {
      let temp = checkedItems;
      if (isSelect) temp.splice(row.id, 0, row.id);
      else {
        const index = temp.indexOf(row.id);
        if (index > -1) {
          temp.splice(index, 1);
        }
      }
      setCheckedItems(temp);
    },
    onSelectAll: (isSelect, rows) => {
      checkedItems.length = 0;
      let temp = checkedItems;
      if (isSelect) {
        rows.forEach((x) => {
          temp.splice(x.id, 0, x.id);
        });
        setCheckedItems(temp);
      } else {
        setCheckedItems(temp);
      }
    },
  };

  const submitFilter = () => {
    setLoading(true);
    surveyResponseManager
      .getSurveyResponses(showProblems, null, null)
      .then((response) => {
        console.log(response);
        if (response) {
          setSurveys(response);
          setLoading(false);
        } else {
          setLoading(false);
          alert("There was a problem getting the survey responses");
        }
      });
  };

  const submitResponseAction = () => {
    if (action && checkedItems.length !== 0) {
      setLoading(true);
      surveyResponseManager
        .setResponseAction(action, checkedItems)
        .then((response) => {
          window.location.reload();
        });
    }
  };

  const handleAction = (e) => {
    setAction(e.target.value);
  };

  const handleProblemFilterChanged = (event, userSelectedShowProblems) => {
    setShowProblems(userSelectedShowProblems);
  };

  useEffect(() => {
    submitFilter();
  }, []);

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "clientName",
      text: "Client Name",
      filter: textFilter(),
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: "surveyTypeName",
      text: "Survey Type",
      filter: textFilter(),
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: "surveyTitle",
      text: "Survey Title",
      filter: textFilter(),
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: "providerId",
      text: "Response Id",
      filter: textFilter(),
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "responderId",
      text: "Unique Id",
      filter: textFilter(),
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: "recordedDate",
      text: "End Date",
      formatter: dateFormatter,
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: "problems",
      text: "Problems",
      formatter: problemFormatter
    },
  ];

  function dateFormatter(cell, row) {
    return (<>{new Intl.DateTimeFormat('en-US').format(new Date(Date.parse(row.recordedDate)))}</>)
  }
  function problemFormatter(cell, row) {
    return (
      <ul>
        {row.problems && row.problems.map((x) =>{
          return (<li>{x.description}</li>)
        })}
      </ul>
    )
  }

  return (
    <Container fluid={true}>
      <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Surveys Responses</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Surveys</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col>
          <h1>Survey Responses</h1>
        </Col>
      </Row>
      <Row>
      <Col lg={6}></Col>
        <Col lg={4}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Survey List</InputGroup.Text>
            </InputGroup.Prepend>
            <ProblemsFilter
              // key={showProblems}
              id={showProblems}
              value={showProblems}
              onChange={handleProblemFilterChanged}
            />
          </InputGroup>
        </Col>
        <Col lg={2}>
          <Button variant="primary" block onClick={submitFilter}>
            Get Responses
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>&nbsp;</Col>
      </Row>
      <LoadingOverlay
        active={loading}
        spinner={<BounceLoader />}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(91, 134, 96, 0.5)",
          }),
          content: (base) => ({
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }),
        }}
        text="Loading..."
      >
        {surveys && surveys.length !== 0 ? (
          <ToolkitProvider keyField="id" data={surveys} columns={columns}>
            {(props) => (
              <>
                <Row>
                  <Col lg={12}>
                    <BootstrapTable
                      filter={ filterFactory() }
                      selectRow={selectRow}
                      pagination={paginationFactory()}
                      hover
                      condensed
                      {...props.baseProps}
                    />
                  </Col>
                  <Col lg={6}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          With Selected Items
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <select
                        className="form-control"
                        defaultValue=""
                        onChange={handleAction}
                        id="sel1"
                      >
                        <option value="">Select Action</option>
                        <option value="fixed">Mark Responses As Fixed</option>
                        <option value="revalidate">
                          Submit For Validation
                        </option>
                        <option value="delete-download">
                          Remove And Re-Download
                        </option>
                        <option value="delete">Remove Selected</option>
                      </select>
                    </InputGroup>
                  </Col>
                  <Col lg={6}>
                    <Button
                      variant="success"
                      type="submit"
                      onClick={submitResponseAction}
                      block
                    >
                      Submit Response Actions
                    </Button>
                  </Col>{" "}
                </Row>
              </>
            )}
          </ToolkitProvider>
        ) : null}
      </LoadingOverlay>
    </Container>
  );
};

const ProblemsFilter = ({ id, value, onChange }) => {
  const problemFilters = [
    { id: 1, description: "Problem Surveys" },
    { id: 2, description: "Good Surveys" },
  ];
  const problemFilterChanged = (event) => {
    const value = parseInt(event.target.value);
    let userSelectedShowProblems = value === 1;
    setSelectedValue(event.target.value);
    onChange(event, userSelectedShowProblems);
  };
  const [selectedValue, setSelectedValue] = useState(value ? 1 : 2);

  return (
    <select
      className="form-control"
      onChange={problemFilterChanged}
      defaultValue={selectedValue}
    >
      {problemFilters.map((value, index) => (
        <option key={index} value={value.id}>
          {value.description}
        </option>
      ))}
    </select>
  );
};

export default SurveysResponses;
