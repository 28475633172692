import React, {useState} from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row, Modal, Button, Container } from "react-bootstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

let SurveyErrors = (props: SurveyErrorsProps) => {
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const columns : IColumnProps[] = [
    {
      dataField: "recordedDate",
      text: "Date",
      formatter: (cell: any) => cell.toLocaleString(),
    },
    {
      dataField: "surveyId",
      text: "Survey Id",
    },
    {
      dataField: "providerId",
      text: "Response Id",
    },
    {
      dataField: "surveyTitle",
      text: "Survey",
    },
    {
      dataField: "errorMessage",
      text: "Error Message",
    },
  ];

    return (
      <Modal
        show={props.show} onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Survey Errors
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid={true}>
            <ToolkitProvider keyField="id" data={props.erroredSurveys} columns={columns}>
              {(props) => (
                <BootstrapTable
                  condensed
                  {...props.baseProps}
                />
                )
              }
            </ToolkitProvider>
          </Container>
        </Modal.Body>
        </Modal>
  );
};

interface SurveyErrorsProps {
  smToken: string,
  erroredSurveys: SurveyErrorsEntity[],
  show: any,
  onHide: any
}
export interface SurveyErrorsEntity {
  id: number
  recordedDate: Date;
  surveyId: string;
  providerId: string;
  surveyTitle: string;
  errorMessage: string;
}
export default SurveyErrors;
