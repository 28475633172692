import React, { useState, useEffect, useReducer } from "react";
import {Col,Row, Button} from "react-bootstrap";
import "../SurveyBuilder.css";

const reducer = (store, action) =>{
    switch(action.type){
        case 'setData':
            return {...store, [action.field]: action.payload}
        default :
            return initialState;
    }
}

const initialState = {
    options: {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: true,
        timeZone: 'America/New_York'
    }
}

export default function QuestionBuilder(props) {
    const [store, dispatch] = React.useReducer(reducer, initialState);
    
    return ( 
        <Row className="pinBottom">
            <Col lg={4}>
            <div className="surveyEffectiveDateInfo">
                Effective Date:
                {props.surveyInfo.startDate ? 
                    <span>{new Intl.DateTimeFormat('en-US', store.options).format(new Date(Date.parse(props.surveyInfo.startDate)))}</span> 
                : ''}
                <span> to </span> 
                {props.surveyInfo.endDate ? 
                    <span>{new Intl.DateTimeFormat('en-US', store.options).format(new Date(Date.parse(props.surveyInfo.endDate)))}</span> 
                : ''}
                </div>
            </Col>
            <Col lg={4}>
                <Button variant="success" size="sm" type="submit" onClick={() => props.handleSave(0)}>Save & Continue</Button>&nbsp;
                <Button variant="success" size="sm" type="submit" onClick={() => props.handleSave(1)}>Save</Button>
            </Col>
            <Col lg={4}>
                <div className="surveyQualtricsIdInfo">Qualtrics Id: {props.surveyInfo.qualtricsSurveyId}</div>
            </Col>
        </Row>
    )
}