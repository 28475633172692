import React from "react";
import {Col, Row, Form, Button} from "react-bootstrap";
import {ChangedFlag} from "models/changedFlag";
import {QuestionReducerContext} from "../questionReducerContext";

const ResponseSetChoices = (props) =>{
  const {store, dispatch} = React.useContext(QuestionReducerContext);

  const handleSelectingSupportingDatum = (event) => {
    let index = event.nativeEvent.target.selectedIndex;
    let targetElement = event.nativeEvent.target[index];
    console.debug('handleSelectingSupportingDatum', event.target);
    dispatch({type:'setQuestionField', field: 'responseSetTemplateId', payload: targetElement.value});
    dispatch({type:'setQuestionField', field: 'responseSetTemplateDescription', payload: targetElement.text});
  };
  const handleRemoveChoice = (index) => {
    dispatch({type:'setQuestionField', field: 'responseSetTemplateId', payload: 0});
    dispatch({type:'setQuestionField', field: 'responseSetTemplateDescription', payload: "Custom"});
    store.question.choices.splice(index, 1);
    dispatch({
      type: 'setQuestionField',
      field: 'choices',
      payload: store.question.choices
    });
  };
  const handleAddNewChoice = () => {
    dispatch({
      type: 'setQuestionField',
      field: 'choices',
      payload: [...store.question.choices, {
        id: 0,
        text: "",
        value: "",
        position: "",
      }]
    });
    dispatch({
      type: 'setQuestionField',
      field: 'responseSetTemplateDescription',
      payload: 'Custom'
    });
    dispatch({
      type: 'setQuestionField',
      field: 'responseSetTemplateId',
      payload: 0
    });
  };
  const handleUpdateChoice = (eventProps) => {
    console.debug('handleUpdateChoice', eventProps);
    store.question.choices[eventProps.index].changedFlag = ChangedFlag.Update;
    store.question.choices[eventProps.index].text = eventProps.text;
    store.question.choices[eventProps.index].position = eventProps.position;
    store.question.choices[eventProps.index].value = eventProps.value;
    dispatch({
      type: 'setQuestionField',
      field: 'choices',
      payload: store.question.choices
    });
  }
  let current = new Date().getTime();

  const isMultipleChoiceResponseSetType = () => [2,3].indexOf(store.question.responseSet) > -1;
  const isMCSD = () => store.question.responseSet === 6;
  let display = isMultipleChoiceResponseSetType();

  const style = {
    display: display ? 'block' : 'none'
  };

  return (
    <>
    { isMCSD() &&
    <Form.Group>
      <Form.Label>Supporting Data: </Form.Label>
      <Form.Control
        as="select"
        defaultValue={store.question.responseSetTemplateDescription}
        isInvalid={store.errors.choices}
        onChange={handleSelectingSupportingDatum}>
        <option value={0}>Select</option>
        {
          store.supportingDatums && store.supportingDatums.map((template, index) => {
            return <option key={index} value={template.description}>{template.description}</option>
          })
        }
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {store.errors.choices}
      </Form.Control.Feedback>
    </Form.Group>
    }
    <div style={style}>
      {store.question?.choices.map((choice, index) =>
        <ResponseChoiceRow
          key={index + current}
          index={index}
          choice={choice}
          onRemoveChoice={handleRemoveChoice}
          onUpdateChoice={handleUpdateChoice}
        />
      )}
      <Button
        variant="outline-success"
        onClick={handleAddNewChoice}
      >
        New
      </Button>
    </div>
  </>
  )
};

const ResponseChoiceRow = ({index,choice, onRemoveChoice, onUpdateChoice}) => {
  const [text, setText] = React.useState(choice.text);
  const [value, setValue] = React.useState(choice.value);
  const [position, setPosition] = React.useState(choice.position);
  return <Row>
    <Col lg={6}>
      <Form.Group>
        <Form.Label>Text: </Form.Label>
        <Form.Control
          required
          value={text}
          onChange={e => setText(e.currentTarget.value)}
          onBlur={_=> onUpdateChoice({index, text, value, position})}
        />
      </Form.Group>
    </Col>

    <Col lg={2}>
      <Form.Group>
        <Form.Label>Value: </Form.Label>
        <Form.Control
          required
          value={value}
          onChange={e => setValue(e.currentTarget.value)}
          onBlur={_=> onUpdateChoice({index, text, value, position})}
        />
      </Form.Group>
    </Col>

    <Col lg={2}>
      <div className="form-group">
        <Form.Label>Sort: </Form.Label>
        <Form.Control
          required
          type="number"
          value={position}
          onChange={e => setPosition(e.currentTarget.value)}
          onBlur={_=> onUpdateChoice({index, text, value, position})}
        />
      </div>
    </Col>
    <Col lg={1}>
      <div className="form-group">
        <Form.Label>&nbsp;</Form.Label>
        <Button
          variant="outline-danger"
          onClick={() => onRemoveChoice(index)}
        >
          Delete
        </Button>
      </div>
    </Col>
  </Row>
};

export default ResponseSetChoices;
