import { currentLinuxEpoch } from "utils";
import TokenManager from "managers/TokenManager";

export default class User {
  token: string | null;
  authenticated: boolean;
  smToken: string;
  smTokenExpiration: number;

  constructor() {
    // Initial State
    this.token = '';
    this.authenticated = false;
    this.smToken = '';
    this.smTokenExpiration = 0;

    // See what we have in the storage container
    let storageUser = User.getStorageUser();

    // Look for things that should cause us to have to purge the current user
    if (storageUser !== null) {
      const storageUserObject = JSON.parse(storageUser);
      this.token = storageUserObject.token;
      this.authenticated = storageUserObject.token;
      this.smToken = storageUserObject.smToken;
      this.smTokenExpiration = storageUserObject.smTokenExpiration;
    }
  }

  // Determine if the token is valid
  static isValidToken(storageUserObject: User): boolean {
    // If the user is marked as not logged in
    const unAuthenticated = storageUserObject.authenticated === false;
    // The token is listed as expired
    const expiredToken = storageUserObject.smTokenExpiration < currentLinuxEpoch();
    
    if (unAuthenticated || expiredToken) {
      return false;
    } else {
      return true;
    }
  }

  // Determine if the user has a valid SM JWT token
  static isAuthorized(): boolean {
    const storageUser = User.getStorageUser();

    if (storageUser !== null) {
      return User.isValidToken(JSON.parse(storageUser));
    }
    return false;
  }

  static getStorageUser(): string | null {
    return localStorage.getItem('stayMetricsUser');
  }

  static removeStorageUser(): void {
    localStorage.removeItem('stayMetricsUser');
  }

  buildLocalStorageObject(): object {
    return {
      token: this.token,
      authenticated: this.authenticated,
      smToken: this.smToken,
      smTokenExpiration: this.smTokenExpiration,
    };
  }

  static buildUserFromAzureAdToken(token: string)  {
    let user = new User();

    var tokenManager = new TokenManager();
    
    // @ts-ignore
    return tokenManager.createStayMetricsTokenFromAzureAdToken(token)
        .then((token: any) => {
              user.token = token;
              user.authenticated = true;
              user.smToken = token;
              user.smTokenExpiration = currentLinuxEpoch() + (86400 * 180)    // 180 days;
              localStorage.setItem('stayMetricsUser', JSON.stringify(user));
              return true;
        });
  }
}
