import React, { useState, useEffect, useReducer } from "react";
import { Breadcrumb, Col, Container, Row, Button, Alert } from "react-bootstrap";

import { Helmet } from 'react-helmet'

export default function Logout() {

    useEffect(() => {
        localStorage.removeItem('stayMetricsUser');
        
    }, []);

  return (
    <Container fluid={true} className="surveryBuilder">
    <Helmet><title>StayMetrics Admin - Logout</title></Helmet>
    <Row>
        <Col lg={12}>
            <p>You are now logged out</p>
        </Col>
    </Row>
    </Container>);
}
