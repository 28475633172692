import TokenService from "services/TokenService";

export default class TokenManager {
    tokenService: TokenService;

    constructor() {
        this.tokenService = new TokenService();
    }

    createStayMetricsTokenFromAzureAdToken(azureAdToken: string): Promise<string> {
        return this.tokenService.createTokenFromAzureAdToken(azureAdToken)
            .then((response: any) => {
                return response;
            });
    }
}
