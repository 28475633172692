import React from "react";
import { List } from "react-virtualized";

const MenuList = props => {
  const rows = props.children;
  const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
    <div key={key} style={style}>
      {rows[index]}
    </div>
  );

  return (
    <List
      style={{ width: "100%" }}
      width={800}
      height={300}
      rowHeight={70}
      rowCount={rows.length || 0}
      rowRenderer={rowRenderer}
    />
  );
};

export default MenuList;
