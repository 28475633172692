import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Switch from "react-switch";
import Select from "react-select";

interface IRecapsPane{
  store: any;
  dispatch: DispatchCallback;
}

const RecapsPane : React.FC<IRecapsPane> = ({store, dispatch}) => {
  const callStatReportTypeOptions = [
    {label:'No', value: 0},
    {label:'Regular', value: 1},
    {label:'Terminal', value: 2},
    {label:'Region -> Terminal', value: 3},
    {label:'Division -> Terminal', value: 4},
    {label:'Division -> Region', value: 5},
    {label:'First/Early Not Completed', value: 6},
    {label:'Manager', value: 7},
    {label:'Dispatcher', value: 8},
  ];

  return <Container>
    <Row className="serviceRow">
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Day 7</span>
          <input
            type="number"
            defaultValue={store.clientInfo.callStatsDay0 || ""}
            onBlur={(e)=>{dispatch({type: 'setData', field: 'callStatsDay0', payload: e.currentTarget.value});}}
          />
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Day 45</span>
          <input
            type="number"
            defaultValue={store.clientInfo.callStatsDay1 || ""}
            onBlur={(e)=>{dispatch({type: 'setData', field: 'callStatsDay1', payload: e.currentTarget.value});}}
          />
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan"> Day 90</span>
          <input
            type="number"
            defaultValue={store.clientInfo.callStatsDay2 || ""}
            onBlur={(e)=>{dispatch({type: 'setData', field: 'callStatsDay2', payload: e.currentTarget.value});}}
          />
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Ongoing Days After Hire</span>
          <input
            type="number"
            defaultValue={store.clientInfo.ongoingDaysAfterHire || ""}
            onBlur={(e)=>{dispatch({type: 'setData', field: 'ongoingDaysAfterHire', payload: e.currentTarget.value});}}
          />
        </label>
      </Col>
    </Row>
    <Row className="serviceRow">
      <Col className="centerColumn" lg={3}>
        <span className="serviceSpan">Report Type:</span>
        <Select
          onChange={(e) => dispatch({type: 'setData', field: 'callStatReportType', payload: e.value})}
          options={callStatReportTypeOptions}
          value={callStatReportTypeOptions.find(option => option.value === store.clientInfo.callStatReportType)}
          isMulti={false}
        />
      </Col>
    </Row>
    <Row className="serviceRow">
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Enable Call Stats Automation</span>
          <Switch checked={store.clientInfo.enableCallStatsAutomation || false}
                  onChange={(e)=>{dispatch({type: 'setData', field: 'enableCallStatsAutomation', payload: e});}} />
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Call Stats Send No Response</span>
          <Switch checked={store.clientInfo.callStatsSendNoResponse || false}
                  onChange={(e)=>{dispatch({type: 'setData', field: 'callStatsSendNoResponse', payload: e});}} />
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">Exit Calls: Exclude Involuntary</span>
          <Switch checked={store.clientInfo.excludeInvoluntary || false}
                  onChange={(e)=>{dispatch({type: 'setData', field: 'excludeInvoluntary', payload: e});}} />
        </label>
      </Col>
    </Row>
  </Container>
}

export default RecapsPane;
