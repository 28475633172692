import React from "react";
import {QuestionReducerContext} from "../questionReducerContext";
import Form from "react-bootstrap/Form";

const QuestionNotes = (props) =>{
  const {store, dispatch} = React.useContext(QuestionReducerContext);

  return (
    <fieldset className="border p-2">
      <legend className="w-auto">Notes</legend>
      <Form.Control
        as='textarea'
        defaultValue={store.question.notes}
        onBlur={e => dispatch({type:'setQuestionField', field:'notes', payload: e.target.value })}
        rows="10"/>
    </fieldset>
  );
};

export default QuestionNotes;
