import {CreateRunResponse, ListPipelinesResponse, PipelineStatus} from "managers/survey_response/dataWarehouseModels";
import DataWarehouseService from "services/survey_response/dataWarehouseService";

export default class DataWarehouseManager {
  private service: DataWarehouseService;

  constructor(token: string) {
    this.service = new DataWarehouseService(token);
  }

  async listPipelineStatus(): Promise<ListPipelinesResponse>{
    let entity = await this.service.listPipelineStatus();
    if(entity.error)
      return {data:[], error: entity.error};

    let data : PipelineStatus[] = []
    entity.data.forEach(d => {
      data.push({
        ...d,
        lastRunMeta: {
          ...d.lastRunMeta,
          runStart: new Date(d.lastRunMeta.runStart),
          runEnd: new Date(d.lastRunMeta.runEnd),
        }
      });
    });
    return {data: data};
  }

  callCreateRun(id: number) : Promise<CreateRunResponse>{
    return this.service.callCreateRun(id);
  }
}
