import React from "react";
import {Form} from "react-bootstrap";
import {QuestionReducerContext} from "../../questionReducerContext";

const SupportingDataMatrixType = (props) => {
  const style = {display: props.display ? 'block' : 'none'};
  const {store, dispatch} = React.useContext(QuestionReducerContext);

  return (
    <div style={style}>
      <Form.Group>
        <Form.Label>Select Supporting Data Type:</Form.Label>
        <Form.Control
          as="select"
          defaultValue={store.question.supportingDataId}
          onChange={e => dispatch({
            type: 'setQuestionField',
            field: 'supportingDataId',
            payload: Number(e.currentTarget.value)
          })}
          isInvalid={store.touched.supportingDataId && store.errors.supportingDataId}
        >
          <option value={""}>Select Supporting Data</option>
          {
            store.supportingDatums?.map((option, index) => {
              return <option key={index} value={option.supportingDataId}>{option.description}</option>
            })
          }
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {store.errors.supportingDataId}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

export default SupportingDataMatrixType;
