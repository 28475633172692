import React, {useEffect} from "react";
import {Col, Row, Modal, Button, Form} from "react-bootstrap";
import MenuList from "components/survey_definition/survey/MenuList";
import "../SurveyBuilder.css";
import Select from "react-select";
import {ChangedFlag} from "models/changedFlag";

const reducer = (store, action) => {
  switch (action.type) {
    case 'setData':
      return {...store, [action.field]: action.payload}
    case 'addSelectedQuestion':
      return {...store, questionsToAdd: [...store.questionsToAdd, action.payload]}
    case 'deleteSelectedQuestion':
      return {
        ...store,
        questionsToAdd: store.questionsToAdd.filter(item => item.surveyItemWordingId !== action.payload.surveyItemWordingId)
      }
    default :
      return initialState;
  }
}
const initialState = {
  questions: [],
  selectedQuestionNumber: "",
  selectedQuestion: "",
  reportCategory: "",
  variableName: "",
  thingsChanged: false,
  editedQuestion: ""
}

export default function EditQuestionModel(props) {
  const [store, dispatch] = React.useReducer(reducer, initialState);

  useEffect(() => {
    let questions = props.questions;
    //grab original question to edit
    let q = questions.find(item => item.surveyItemWordingId === props.selectedQuestion.surveyItemWordingId);

    if (q) {

      let selectedSMQ = {label: q.question_number, value: q.surveyItemId}
      let selectedWording = {fullText: q.fullText, surveyItemWordingId: q.surveyItemWordingId}

      dispatch({type: 'setData', field: 'editedQuestion', payload: q});
      dispatch({type: 'setData', field: 'selectedQuestionObject', payload: q});
      //set the form up
      dispatch({type: 'setData', field: 'selectedQuestionNumber', payload: selectedSMQ});
      dispatch({type: 'setData', field: 'selectedQuestion', payload: selectedWording});
      dispatch({type: 'setData', field: 'reportCategory', payload: props.selectedQuestion.reportCategory});
      dispatch({type: 'setData', field: 'variableName', payload: props.selectedQuestion.variableName});

      let questionsUsedMinusCurrentEdit = props.questionsUsed.filter(item => item !== q.surveyItemId);
      //filter out all used questions
      questions = questions.filter(ar => !questionsUsedMinusCurrentEdit.find(rm => (rm === ar.surveyItemId)))
      dispatch({type: 'setData', field: 'questions', payload: questions});
    }

  }, [props.selectedQuestion, props.questionsUsed, props.questions]);

  return (<Modal dialogClassName="questionModalEdit" show={props.show} onHide={props.onHide}
                 size="lg"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered>
    {props.children}
    <Modal.Header className="modalBG" closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Edit Question
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="modalBG">
      <Row className="addQuestionStyle">
        <Col lg={12}>
          <ul>
            <li className="addQuestionHeader">
              <Row>
                <Col lg={2}>
                  <label>SMQ #</label>
                  <Select className="addQuestionItem"
                          components={{MenuList}}
                          options={store.questions.map(item => ({
                            label: item.question_number,
                            value: item.surveyItemId,
                            variableName: item.variableName,
                            reportCategory: item.reportCategory
                          }))}
                          value={store.selectedQuestionNumber}
                          onChange={(e) => {
                            dispatch({type: 'setData', field: 'selectedQuestionNumber', payload: e});
                            dispatch({type: 'setData', field: 'thingsChanged', payload: true});
                            let x = store.questions.filter(item => ((item.surveyItemId === e.value) && item.default === true))
                            let defaultWording = store.questions.find(item => (item.surveyItemWordingId === x[0].surveyItemWordingId))
                            dispatch({type: 'setData', field: 'selectedQuestion', payload: defaultWording});
                            dispatch({type: 'setData', field: 'variableName', payload: e.variableName});
                            dispatch({type: 'setData', field: 'reportCategory', payload: e.reportCategory});
                          }}
                          captureMenuScroll={false}
                  />
                </Col>
                <Col lg={5}>

                  {Object.keys(store.selectedQuestionNumber).length !== 0 ?
                    <>
                      <label>Select Wording</label>
                      <Select className="addQuestionItem"
                              components={{MenuList}}
                              getOptionLabel={option => option.fullText}
                              getOptionValue={option => option.surveyItemWordingId}
                              value={store.selectedQuestion}
                              options={store.questions.filter(item => (item.surveyItemId === store.selectedQuestionNumber.value))}
                              onChange={(e) => {
                                dispatch({type: 'setData', field: 'selectedQuestion', payload: e});
                                dispatch({type: 'setData', field: 'thingsChanged', payload: true});
                              }}
                      />

                    </>
                    : ''}
                </Col>
                {Object.keys(store.selectedQuestion).length !== 0 ?
                  <>
                    <Col lg={2}>
                      <label>Variable Name</label>
                      <Form.Control type="input" className="addQuestionItem"
                                    value={store.variableName}
                                    onChange={(e) => {
                                      dispatch({
                                        type: 'setData',
                                        field: 'variableName',
                                        payload: e.currentTarget.value
                                      });
                                      dispatch({type: 'setData', field: 'thingsChanged', payload: true});
                                    }}
                      />
                    </Col>
                    <Col lg={2}>
                      <label>Report Category</label>
                      <Form.Control type="input" className="addQuestionItem"
                                    value={store.reportCategory}
                                    onChange={(e) => {
                                      dispatch({
                                        type: 'setData',
                                        field: 'reportCategory',
                                        payload: e.currentTarget.value
                                      });
                                      dispatch({type: 'setData', field: 'thingsChanged', payload: true});
                                    }}
                      />
                    </Col>
                    <Col lg={1}>
                      <Button className="buttonFormat" variant="success" size="sm" type="submit" onClick={(e) => {
                        let q = [];
                        let temp = store.selectedQuestion;
                        if (store.thingsChanged) {
                          temp.changedFlag = ChangedFlag.Unknown;
                          temp.reportCategory = store.reportCategory;
                          temp.variableName = store.variableName;
                          temp.id = props.selectedQuestion.id;
                          temp.surveyItemId = store.selectedQuestionNumber.value;
                          temp.surveyItemWordingId = store.selectedQuestion.value;
                          if (temp.id) {
                            temp.changedFlag = ChangedFlag.Update;
                          } else {
                            temp.changedFlag = ChangedFlag.New;
                          }

                          q.push(temp);
                          props.editQuestion({
                            id: props.sectionId,
                            originalId: store.editedQuestion.surveyItemId,
                            surveyItemWordingId: props.surveyItemWordingId,
                            questions: q
                          });
                        } else {
                          props.onHide();
                        }
                      }}>Finished</Button>
                    </Col>
                  </> : ''
                }
              </Row>
            </li>
            <li className="addQuestionFooter">
              <Row>
                <Col lg={12}>
                </Col>
              </Row>
            </li>
          </ul>
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer className="modalBG">

    </Modal.Footer>
  </Modal>)
}
