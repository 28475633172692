import React, { useState, useEffect, useReducer } from "react";
import { Col, Row, Modal, Button, Form } from "react-bootstrap";

const reducer = (store, action) =>{
  switch(action.type){
      case 'setData':
          return {...store, [action.field]: action.payload}        
      default :
          return initialState;
  }
}
const initialState = {
  sectionName: ''
}

export default function AddSectionModel(props) {
  const [store, dispatch] = React.useReducer(reducer, initialState);

  return (<Modal show={props.show} onHide={props.onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered >
    {props.children}
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Stay Metrics Section
    </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row className="addQuestionStyle">
        <Col lg={6}>
        <label>Section Name:</label>
        <Form.Control type="input" 
                        onChange={ (e) => { 
                            dispatch({type: 'setData', field: 'sectionName', payload: e.currentTarget.value}); 
                        }} /> 
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer>

      <Button
        onClick={(e) => {
          props.newSectionName({changedFlag: 1, contents: [], name: store.sectionName});
          props.onHide();
        }}>Add Section</Button>
    </Modal.Footer>
  </Modal>)
  
}
