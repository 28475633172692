import React, { useState, useEffect, useReducer } from "react";
import { Breadcrumb, Col, Container, Row, Button, InputGroup } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import DatePicker from 'react-datepicker';
import SurveyManager from "managers/survey_definition/surveyManager";
import DistributionManager from "managers/survey_distribution/surveyDistributionManager";
import { Helmet } from 'react-helmet'
import Select from "react-select";
import * as moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css';

const reducer = (store, action) =>{
    switch(action.type){
        case 'setData':
            return {...store, [action.field]: action.payload}          
        default :
            return initialState;
    }
}

const initialState = {
    id: 0,
    clientSurveyId: '',
    selectionPlanId: '',
    isActive: true,
    clientId: '',
    eligibilityDays: 0,
    participantPlanId: '',
    contactPlanId: '',
    messagePlanId: '',
    surveyDistributionPlanId: 0,
    contactId: 0,
    openDate: '',
    closeDate: ''
}

export default function NewDistribution(props) {
    let surveyManager = new SurveyManager(props.currentuser.smToken);
    let distributionManager = new DistributionManager(props.currentuser.smToken);

    let [loading, setLoading] = useState(false);
    let [surveys, setSurveys] = useState([]);
    let [selectionPlans, setSelectionPlans] = useState([]);
    let [contactPlans, setContactPlans] = useState([]);
    let [contactPlanMethods, setContactPlanMethods] = useState([]);
    let [messagePlans, setMessagePlans] = useState([]);

    const [store, dispatch] = React.useReducer(reducer, initialState);


    useEffect(() => {
        setLoading(true);

        let surveys = surveyManager.getSurveys().then((surveyList) => {console.log("surveys", surveys); return surveyList});
        let select = distributionManager.getSelectionPlans().then((plans) => {return plans});
        let contact = distributionManager.getContactPlans().then((plans) => {return plans});
        let message = distributionManager.getMessagePlans().then((plans) => {return plans});

        Promise.all([surveys,select,contact, message]).then((values)=>{
            setSurveys(values[0]);
            setSelectionPlans(values[1]);
            setContactPlans(values[2]);
            setMessagePlans(values[3]);


            if (props.match.params.distributionPlanId){
                distributionManager.getDistributionsById(props.match.params.distributionPlanId).then((distribution) =>
                {
                    console.log("distribution",  distribution);
                    let surveyObject = values[0].find(x => x.id ===  distribution.clientSurveyId);
                    let selectionObject = values[1].find(x => x.id ===  distribution.selectionPlanIds[0]);
                    let contactObject = values[2].find(x => x.id ===  distribution.contactPlans[0].contactPlanId);    
                    let messageObject = values[3].find(x => x.id ===  distribution.contactPlans[0].messageTemplateId);

                    dispatch({type: 'setData', field: 'id', payload: distribution.id });
                   
                    dispatch({type: 'setData', field: 'openDate', payload: moment(distribution.openDate).toDate() });
                    dispatch({type: 'setData', field: 'closeDate', payload: moment(distribution.closeDate).toDate() });
                    dispatch({type: 'setData', field: 'contactId', payload: distribution.contactPlans[0].id });
                    dispatch({type: 'setData', field: 'surveyDistributionPlanId', payload: distribution.contactPlans[0].surveyDistributionPlanId });
                    dispatch({type: 'setData', field: 'clientSurveyId', payload:  {label:surveyObject.title, value: surveyObject.id } });
                    dispatch({type: 'setData', field: 'selectionPlanId', payload: {label:selectionObject.name, value: selectionObject.id } });
                    dispatch({type: 'setData', field: 'contactPlanId', payload:  {label:contactObject.name, value: contactObject.id }});
                    dispatch({type: 'setData', field: 'messagePlanId', payload: {label:messageObject.name, value: messageObject.id }});
    
                    console.log("distribution",JSON.stringify(distribution));
                });
            }

            setLoading(false);
        });
    }, []);
    
    const ApiBuild = (mode) =>{

        let tempObject = {};
        tempObject.id = store.id;
        tempObject.openDate = store.openDate;
        tempObject.closeDate = store.closeDate;
        tempObject.clientSurveyId = store.clientSurveyId.value;
        tempObject.isActive = true;
        tempObject.selectionPlanIds = [store.selectionPlanId.value];
        tempObject.eligibilityDays = 0;
        tempObject.contactPlans = [
            {
              "id": store.contactId,
              "surveyDistributionPlanId": store.surveyDistributionPlanId,
              "messageTemplateId": store.messagePlanId.value,
              "contactPlanId": store.contactPlanId.value,
              
            }
          ];

        if (mode === "new"){
            console.log("saving", tempObject);
            distributionManager.saveDistribution(tempObject).then((saved)=>{
                console.log("saved", saved);
                window.location.href = "/survey-distribution";
            });
        }
        else if (mode === "update"){
            console.log("plan", JSON.stringify(tempObject));
            
            distributionManager.updateDistribution(props.match.params.distributionPlanId, tempObject).then((saved)=>{
                console.log("saved", saved);
                window.location.href = "/survey-distribution";
            });
        }
    }

    return (
    <LoadingOverlay
        active={loading}
        spinner={<BounceLoader />}
        styles={{
        overlay: (base) => ({
            ...base,
            background: "rgba(91, 134, 96, 0.5)",
        }),
        content: (base) => ({
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
        }),
        }}
        text="Loading...">    
    <Container fluid={true}>
        <Helmet><title>StayMetrics Admin - New Distribution</title></Helmet>
        <Row>
            <Col lg={12}>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/survey-distribution">Survey Distribution</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">New</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
        </Row>
                <Row className="mainRow">
                    <Col lg={3}><h3>Survey Distribution</h3></Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <InputGroup>
                            <label className="distroLabel">Survey</label>
                            <Select
                                className="surveySelect"
                                options={surveys.map(item => ({ label: item.title, value: item.id }))}
                                isMulti={false}
                                value={store.clientSurveyId}
                                onChange={ (e) => {
                                    dispatch({type: 'setData', field: 'clientSurveyId', payload: e}); 
                                    let client = surveys.filter(item => item.id === e.value )[0]
                                    dispatch({type: 'setData', field: 'clientId', payload: client.clientId})
                                }}
                            />
                        </InputGroup>
                    </Col>
                </Row>                  
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col lg={4}>
                                        <InputGroup><label className="distroLabel">Selection</label>
                                        <Select
                                            className="surveySelect"
                                            options={selectionPlans.map(item => ({ label: item.name, value: item.id }))}
                                            isMulti={false}
                                            value={store.selectionPlanId}
                                            onChange={ (e) => 
                                                dispatch({type: 'setData', field: 'selectionPlanId', payload: e} )}
                                        />
                                        </InputGroup>
                            </Col>
                            <Col lg={4}>
                                        <InputGroup><label className="distroLabel">Contact</label>
                                        <Select
                                            className="surveySelect"
                                            options={contactPlans.map(item => ({ label: item.name, value: item.id }))}
                                            isMulti={false}
                                            value={store.contactPlanId}
                                            onChange={ (e) => dispatch({type: 'setData', field: 'contactPlanId', payload: e} )}
                                        />
                                        </InputGroup>
                            </Col>
                            <Col lg={4}>
                                        <InputGroup>
                                        <label className="distroLabel">Message</label>
                                        <Select
                                            className="surveySelect"
                                            options={messagePlans.map(item => ({ label: item.name, value: item.id }))}
                                            isMulti={false}
                                            value={store.messagePlanId}
                                            onChange={ (e) => dispatch({type: 'setData', field: 'messagePlanId', payload: e} )}
                                        />
                                        </InputGroup>
                            </Col>
                            <Col lg={3}>
                                <InputGroup> 
                                    <label className="distroLabel">Start Date</label><br></br>
                                    <DatePicker className="dateLabel" dateFormat="MM/dd/yyyy" selected={store.openDate}
                                        onChange={date => dispatch({type: 'setData', field: 'openDate', payload: date})} />
                                </InputGroup>
                            </Col>
                            <Col lg={3}> 
                                <InputGroup>   
                                    <label className="distroLabel">End Date</label><br></br>
                                    <DatePicker className="dateLabel" dateFormat="MM/dd/yyyy" selected={store.closeDate}
                                    onChange={date => dispatch({type: 'setData', field: 'closeDate', payload: date})} />
                                </InputGroup>
                            </Col>
                        </Row> 
                    </Col>
            </Row>
                <div className="lowerLine">                               
                    {props.match.params.distributionPlanId === undefined ? <Button className="buttonMove" variant="success" onClick={(e)=>{  ApiBuild("new") }}>Save</Button>: ''}
                    {props.match.params.distributionPlanId > 0 ? <Button className="buttonMove" variant="success" onClick={(e)=>{  ApiBuild("update") }}>Update</Button>: ''}
                </div>
    </Container>
    </LoadingOverlay>
    )

}