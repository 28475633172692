import React, {FC} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Switch from "react-switch";

interface IFtpPane{
  store: any;
  dispatch: DispatchCallback;
}

const FtpPane : FC<IFtpPane> = ({store, dispatch}) => {
  return <Container>
    <Row className="serviceRow">
      <Col className="centerColumn" lg={4}>
        <label>
          <span className="serviceSpan">Client FTP Home Directory</span>
          <input
            readOnly
            value={store.clientInfo.ftpHomeDirectory || ""}
            className="form-control"
            name="firstName"
          />
        </label>
      </Col>
    </Row>
    <Row className="serviceRow">
      <Col className="centerColumn" lg={4}>
        <label>
          <span className="serviceSpan">FTP Login Username</span>
          <input
            readOnly
            value={store.clientInfo.ftpUsername || ""}
            className="form-control"
            name="firstName"
          />
        </label>
      </Col>
      <Col className="centerColumn" lg={4}>
        <label>
          <span className="serviceSpan">FTP Login Password</span>
          <input
            readOnly
            value={store.clientInfo.ftpPassword || ""}
            className="form-control"
            name="firstName"
          />
        </label>
      </Col>
    </Row>
    <Row className="serviceRow">
      <Col className="centerColumn" lg={4}>
        <span className="serviceSpan">FTP File Name</span>
        <input
          defaultValue={store.clientInfo.ftpFileName || ""}
          onBlur={(e)=>{dispatch({type: 'setData', field: 'ftpFileName', payload: e.currentTarget.value});}}
          className="form-control"
          name="ftpFileName"
        />
      </Col>
      <Col className="centerColumn" lg={4}>
        <span className="serviceSpan">FTP File Type</span>
        <input
          defaultValue={store.clientInfo.ftpFileType || ""}
          onBlur={(e)=>{dispatch({type: 'setData', field: 'ftpFileType', payload: e.currentTarget.value});}}
          className="form-control"
          name="ftpFileType"
        />
      </Col>
    </Row>
    <Row className="serviceRow">
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan"> FTP Client Upload Transfer</span>
          <Switch checked={store.clientInfo.ftpAutomation || false}
                  onChange={(e)=>{dispatch({type: 'setData', field: 'ftpAutomation', payload: e});}} />
        </label>
      </Col>
      <Col className="centerColumn" lg={3}>
        <label>
          <span className="serviceSpan">FTP Automation</span>
          <Switch checked={store.clientInfo.ftpClientUploadTransfer || false}
                  onChange={(e)=>{dispatch({type: 'setData', field: 'ftpClientUploadTransfer', payload: e});}} />
        </label>
      </Col>
    </Row>
  </Container>
};

export default FtpPane;