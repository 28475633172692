class SupportingDataService {
  private jwt: string;
  constructor(jwt: string) {
    this.jwt = jwt;
  }

  retrieve() {
    return Promise.resolve([
      { supportingDataId:"1111", description:"Dispatchers" },
      { supportingDataId:"1122", description:"Division" },
      { supportingDataId:"2222", description:"Equipment" },
      { supportingDataId:"3333", description:"Recruiters" },
      { supportingDataId:"4444", description:"Terminals" },
    ]);
  }
}

export default SupportingDataService;
