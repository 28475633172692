import React, { useState, useEffect, useReducer } from "react";
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import ClientUserManager from "managers/client_portal/clientUserManager";
import { getLastItemFromUrl } from "utils";
import { retrieveToken } from "utils";
import ClientManager from "managers/clientManager";
import PowerBiReportManager from "managers/powerBiReportManager";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Select from "react-select";
import { Helmet } from 'react-helmet'

let powerBiReportManager = new PowerBiReportManager(token);
let token = retrieveToken();

const reducer = (store, action) =>{
  switch(action.type){
    case 'updateField':
      return {...store, userObject: {...store.userObject, [action.field]: action.payload}}
    case 'setUserObject':
        return {...store, userObject: action.payload}
    default :
      return initialState;
  }
}

const initialState = {
  userObject: {
    clients: '',
    client: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    active: '',
    powerBiReportId: [],
    defaultPowerBiReportId: '',
  }
}


export default function ClientUserForm() {
  const [clients, setClients] = useState([]);
  const [powerBiReports, setPowerBiReports] = useState([]);
  const [defaultPowerBiReports, setDefaultPowerBiReports] = useState([]);
  const [loading, setLoading] = useState(false);
  let token = retrieveToken();
  let clientUserManager = new ClientUserManager(token);
  let clientManager = new ClientManager(token);
  let powerBiReportManager = new PowerBiReportManager(token);

  let lastItem = getLastItemFromUrl();
  let formType = lastItem === "new" ? "insert" : "update";

  const [store, dispatch] = React.useReducer(reducer, initialState);


  useEffect(() => {
    setLoading(true);
    clientManager.getList().then(x => {
      setClients(x);
      clientUserManager.getById(lastItem).then(user => {
        if (user){
          user.clients = [];
          user.client.forEach(clientId => {
            let clientName = x.filter((c) => c.id === clientId)[0];
            if (clientName){
              user.clients.push({label: clientName.name , value: clientName.id});
            }
          });

          let clientIds = [];
          user.clients.forEach(clients=> {
            clientIds.push(clients.value)
          });

          dispatch({type: 'setUserObject', payload: {
                              id: user.id,
                              clients: user.clients,
                              client: clientIds,
                              firstName: user.firstName,
                              lastName: user.lastName ,
                              emailAddress: user.emailAddress,
                              active: user.active,
                              powerBiReportId: user.powerBiReportId,
                              defaultPowerBiReportId: user.defaultPowerBiReportId,
                            }
          });

          let firstClient = clientIds[0];
          powerBiReportManager.getReportsByClientId(firstClient).then((availableReports)=>{
            setPowerBiReports(availableReports);
            let defaultList = [];

            user.powerBiReportId.forEach(item => {
              let report = x.filter((r) => r.id === item)[0];
              if (report){
                defaultList.push({label: report.name , value: item});
              }
          })
          setDefaultPowerBiReports(defaultList);
          setLoading(false);
        });
      }
      else{
        setLoading(false);
      }
      });
    });
  }, []);

  const handleSelectClient = e => {
    if (e && e.value !== "") {
      //get only the client id's, not the objects
      let clientIds = [];
      e.forEach(clients=>{
        clientIds.push(clients.value)
      })

      dispatch({type: 'updateField', field: 'clients', payload: e});
      dispatch({type: 'updateField', field: 'client', payload: clientIds});
      let firstClient = "";
      if ( clientIds){
       firstClient = clientIds[0];
      }
      setLoading(true);
      powerBiReportManager.getReportsByClientId(firstClient).then((availableReports)=>{
        setPowerBiReports(availableReports);
        setLoading(false);
      });
    }
  };

  const formSubmit = () => {
    setLoading(true);
    if (formType === "insert") {
      clientUserManager.insert(store.userObject).then(x => {
        setLoading(false);
        if (x) {
          window.location.href = "/client-portal/client-users";
        } else {
          setLoading(false);
          alert("There was an error saving your form");
        }
      });
    } else {
      clientUserManager.update(lastItem, store.userObject).then(x => {
        if (x) {
          setLoading(false);
          window.location.href = "/client-portal/client-users";
        } else {
          setLoading(false);
          alert("There was an error saving your form");
        }
      });
    }
  };

  return (
    <Container fluid={true}>
      <Helmet><title>StayMetrics Admin - Client User</title></Helmet>
      <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Client Portal</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Users</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Edit User</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <LoadingOverlay
        active={loading}
        spinner={<BounceLoader />}
        styles={{
          overlay: base => ({
            ...base,
            background: "rgba(91, 134, 96, 0.5)"
          }),
          content: base => ({
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center"
          })
        }}
        text="Loading..."
      >
                <Row>
                  <Col sm={6}>
                    <div className="form-group">
                      <label>Client</label>
                      <Select
                        options={clients.map(item => ({ label: item.name, value: item.id }))}
                        isMulti={true}
                        value={store.userObject.clients}
                        onChange={ (e) => handleSelectClient(e) }
                        />
                    </div>

                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        className="form-control"
                        name="firstName"
                        onChange={ (e) => dispatch({type: 'updateField', field: 'firstName', payload: e.currentTarget.value})}
                        value={store.userObject.firstName}
                      />
                    </div>

                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        className="form-control"
                        name="lastName"
                        onChange={ (e) => dispatch({type: 'updateField', field: 'lastName', payload: e.currentTarget.value})}
                        value={store.userObject.lastName}
                      />
                    </div>

                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        className="form-control"
                        name="emailAddress"
                        onChange={ (e) => dispatch({type: 'updateField', field: 'emailAddress', payload: e.currentTarget.value})}
                        value={store.userObject.emailAddress}
                      />
                    </div>

                    <div className="form-group">
                      <label>Active</label>
                      <select
                        className="form-control"
                        name="active"
                        value={store.userObject.active}
                        onChange={ (e) => dispatch({type: 'updateField', field: 'active', payload: e.currentTarget.value})}
                      >
                        <option>Select</option>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                      </select>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="form-group">
                      <label>
                        Power BI Reports (Use Control to select multiple ones)
                      </label>
                      <select
                        className="form-control"
                        name="powerBiReportId"
                        multiple
                        size="10"
                        value={store.userObject.powerBiReportId}
                        onChange={ (e) => {
                            let selectedItems = Array.from(e.target.selectedOptions, option => option.value);
                            dispatch({type: 'updateField', field: 'powerBiReportId', payload: selectedItems })
                            let defaultList = [];
                            selectedItems.forEach(item => {
                              let report = powerBiReports.filter((r) => r.id === item)[0];
                              if (report){
                                defaultList.push({label: report.name , value: item});
                              }
                            })
                            setDefaultPowerBiReports(defaultList);
                        }}
                      >
                        <option>Select</option>
                        {powerBiReports.map((x, index) => {
                          return (
                            <option key={index} value={x.id}>
                              {x.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Default PowerBi Report</label>
                      <Select
                        name="defaultPowerBiReportId"
                        value={defaultPowerBiReports.filter(function(option) {
                          return option.value === store.userObject.defaultPowerBiReportId;
                        })}
                        options={defaultPowerBiReports}
                        onChange={ (e) => dispatch({type: 'updateField', field: 'defaultPowerBiReportId', payload: e.value})}
                        />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Button
                      variant="success"
                      size="lg"
                      onClick={formSubmit}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
      </LoadingOverlay>
    </Container>
  );
}
