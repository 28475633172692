import React, { useState, useEffect, useReducer } from "react";
import { Breadcrumb, Col, Container, Row, Button, Alert} from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import DistributionManager from "managers/survey_distribution/surveyDistributionManager";
import { Helmet } from 'react-helmet'
import {Trophy} from "react-bootstrap-icons";
import Select from "react-select";
import DatePicker from 'react-datepicker';

const reducer = (store, action) =>{
    switch(action.type){
        case 'setData':
            return {...store, [action.field]: action.payload}        
        default :
            return initialState;
    }
}
    const daysOfWeek = [
        {label: "Monday", value: 0},
        {label: "Tuesday", value: 1},
        {label: "Wednesday", value: 2},
        {label: "Thursday", value: 3},
        {label: "Friday", value: 4},
        {label: "Saturday", value: 5},
        {label: "Sunday", value: 6},
    ]
    const hoursOfDay = [
        {label: "OFF", value: 24},
        {label: "12 AM", value: 0},
        {label: "1 AM", value:1},
        {label: "2 AM", value:2},
        {label: "3 AM", value:3},
        {label: "4 AM", value:4},
        {label: "5 AM", value:5},
        {label: "6 AM", value:6},
        {label: "7 AM", value:7},
        {label: "8 AM", value:8},
        {label: "9 AM", value:9},
        {label: "10 AM", value:10},
        {label: "11 AM", value:11},
        {label: "12 PM", value:12},
        {label: "1 PM", value:13},
        {label: "2 PM", value:14},
        {label: "3 PM", value:15},
        {label: "4 PM", value:16},
        {label: "5 PM", value:17},
        {label: "6 PM", value:18},
        {label: "7 PM", value:19},
        {label: "8 PM", value:20},
        {label: "9 PM", value:21},
        {label: "10 PM", value:22},
        {label: "11 PM", value:23},
    ]
    const initialState = {
        methods:[],
        id: 0,
        name: "",
        contactPlanMethodId: 0,
        contactPlanMethod: {
                id: 0,
                name: "",
                hourlyThroughput: 0
        },
        numberOfContacts: 0,
        mondayStartHour: "",
        mondayEndHour: "",
        tuesdayStartHour: "",
        tuesdayEndHour: "",
        wednesdayStartHour: "",
        wednesdayEndHour: "",
        thursdayStartHour: "",
        thursdayEndHour: "",
        fridayStartHour: "",
        fridayEndHour: "",
        saturdayStartHour: "",
        saturdayEndHour: "",
        sundayStartHour: "",
        sundayEndHour: "",
        contactType: {},
        daysBetweenContacts: 0,
        firstSendDay: 0,
        priority: {},
    }

export default function ContactPlan(props) {

    let [loading, setLoading] = useState(false);
    let [saved, setSaved] = useState(false);
    let [usage, setUsage] = useState([]);

    let distributionManager = new DistributionManager(props.currentuser.smToken);

    const [store, dispatch] = React.useReducer(reducer, initialState);


    useEffect(() => {
        setLoading(true);
        distributionManager.getContactPlanMethods().then((plans) => {
            dispatch({type: 'setData', field: 'methods', payload: plans})
            setLoading(false);

            if (props.match.params.contactPlanId){
                setLoading(true);
                distributionManager.getContactPlansById(props.match.params.contactPlanId).then((contactPlan) => {
                    console.log("contact plan", contactPlan);              
                    dispatch({type: 'setData', field: 'id', payload: contactPlan.id});
                    dispatch({type: 'setData', field: 'name', payload: contactPlan.name});
                    dispatch({type: 'setData', field: 'daysBetweenContacts', payload: contactPlan.daysBetweenContacts});
                    dispatch({type: 'setData', field: 'numberOfContacts', payload: contactPlan.numberOfContacts});
                    dispatch({type: 'setData', field: 'mondayStartHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.mondayStartHour)});
                    dispatch({type: 'setData', field: 'mondayEndHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.mondayEndHour)});
                    dispatch({type: 'setData', field: 'tuesdayStartHour', payload:  hoursOfDay.find(obj => obj.value === contactPlan.tuesdayStartHour)});
                    dispatch({type: 'setData', field: 'tuesdayEndHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.tuesdayEndHour)});
                    dispatch({type: 'setData', field: 'wednesdayStartHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.wednesdayStartHour)});
                    dispatch({type: 'setData', field: 'wednesdayEndHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.wednesdayEndHour)});
                    dispatch({type: 'setData', field: 'thursdayStartHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.thursdayStartHour)});
                    dispatch({type: 'setData', field: 'thursdayEndHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.thursdayEndHour)});
                    dispatch({type: 'setData', field: 'fridayStartHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.fridayStartHour)});
                    dispatch({type: 'setData', field: 'fridayEndHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.fridayEndHour)});
                    dispatch({type: 'setData', field: 'saturdayStartHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.saturdayStartHour)});
                    dispatch({type: 'setData', field: 'saturdayEndHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.saturdayEndHour)});
                    dispatch({type: 'setData', field: 'sundayStartHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.sundayStartHour)});
                    dispatch({type: 'setData', field: 'sundayEndHour', payload: hoursOfDay.find(obj => obj.value === contactPlan.sundayEndHour)});

                    let temp = plans.find(method => method.id === contactPlan.contactPlanMethodId);     
                    dispatch({type: 'setData', field: 'contactPlanMethodId', payload: { label: temp.name, value: temp.id } });

                    dispatch({type: 'setData', field: 'firstSendDay', payload: daysOfWeek.find(day => day.value === contactPlan.firstSendDay)});
                    distributionManager.getContactPlansUsage(props.match.params.contactPlanId).then((usage) => {
                        setUsage(usage);
                        console.log("usage", usage);
                        setLoading(false);
                    });
                });
            } 
        });
    }, [])

    const onShowAlert = () => {
        window.setTimeout(()=>{
            setSaved(false);
            window.location.href = "/survey-distribution/contacts";
        },1000)
    }
    const saveContactPlan = plan => {
        console.log("saving contact plan", plan);
        distributionManager.saveContactPlans(plan).then(()=>{
            setSaved(true);
            onShowAlert();
        });
    }
    const updateContactPlan = plan => {
        console.log("saving contact plan", plan);
        distributionManager.updateContactPlans(store.id, plan).then(()=>{
            setSaved(true);
            onShowAlert();
        });
    }
    const ApiObjectBuilder = (mode) => {
        let tempObject = {};
        console.log("contact", store);

        tempObject.name = store.name;
        tempObject.id = parseInt(store.id);
        tempObject.contactPlanMethodId = parseInt(store.contactPlanMethodId.value);
        tempObject.daysBetweenContacts = parseInt(store.daysBetweenContacts);
        tempObject.numberOfContacts = parseInt(store.numberOfContacts);
        
        tempObject.firstSendDay = parseInt(store.firstSendDay.value);
        tempObject.mondayStartHour = store.mondayStartHour.value;
        tempObject.mondayEndHour = store.mondayEndHour.value;
        tempObject.tuesdayStartHour = store.tuesdayStartHour.value;
        tempObject.tuesdayEndHour = store.tuesdayEndHour.value;
        tempObject.wednesdayStartHour = store.wednesdayStartHour.value;
        tempObject.wednesdayEndHour = store.wednesdayEndHour.value;
        tempObject.thursdayStartHour = store.thursdayStartHour.value;
        tempObject.thursdayEndHour = store.thursdayEndHour.value;
        tempObject.fridayStartHour = store.fridayStartHour.value;
        tempObject.fridayEndHour = store.fridayEndHour.value;
        tempObject.saturdayStartHour = store.saturdayStartHour.value;
        tempObject.saturdayEndHour = store.saturdayEndHour.value;
        tempObject.sundayStartHour = store.sundayStartHour.value;
        tempObject.sundayEndHour = store.sundayEndHour.value;
        
        console.log("ApiObjectBuilder", tempObject);
        if (mode === "new"){
            saveContactPlan(tempObject);
        }
        else{
            updateContactPlan(tempObject);
        }
    }

    return(          
        <Container fluid={true}>
            <LoadingOverlay text="Loading..." active={loading} spinner={<BounceLoader />} styles={{
            overlay: (base) => ({
                ...base,
                background: "rgba(91, 134, 96, 0.5)",
            }),
            content: (base) => ({
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
            }),
            }}> 
        <Helmet><title>StayMetrics Admin - Survey List</title></Helmet>
        <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/survey-distribution/">Survey Distribution</Breadcrumb.Item>
            { props.match.params.contactPlanId > 0 
            ? <Breadcrumb.Item>Edit Contact Plan</Breadcrumb.Item>
            : <Breadcrumb.Item href="contact/new/">New Contact Plan</Breadcrumb.Item>}
          </Breadcrumb>
        </Col>
      </Row>
        <Row className="mainRow">
                    <Col lg={3}><h3>Contact Plan</h3></Col>
        </Row>
        <Row>
        <Col lg={12}>
            <Alert color="info" variant='success' show={saved} >Success</Alert>
        </Col>
        </Row>
        <Row>

            <Col lg={3}>
            <label>Name</label>
            <input
                            className="form-control"
                            name="contactName"
                            onChange={ (e) => dispatch({type: 'setData', field: 'name', payload: e.currentTarget.value})}
                            value={store.name}
                        />
            </Col>
            <Col lg={3}>
            <div className="form-group"> 
                        <label>Type</label>
                        <Select
                            className="surveySelect"
                            value={store.contactPlanMethodId}
                            options={store.methods.map(item => ({ label: item.name, value: item.id }))}
                            isMulti={false}
                            onChange={(e) => {
                                dispatch({type: 'setData', field: 'contactPlanMethodId', payload: e})
                            }}
                        />
                </div> 
            </Col>   
        </Row>
        {store.contactPlanMethodId && store.contactPlanMethodId.value ?
        <div className="contactContainer" fluid="true">
        <Row>
            <Col lg={3}>
                <div className="form-group"> 
                    <label>First Send Day</label>
                    <Select
                        className="surveySelect"
                        options={daysOfWeek}
                        value={store.firstSendDay}
                        isMulti={false}
                        onChange={(e) => {
                            dispatch({type: 'setData', field: 'firstSendDay', payload: e})
                        }}
                    />
                </div> 
            </Col> 
            <Col lg={2}>
            <div className="form-group"> 
                        <label>Days Between Send</label>
                        <input
                            className="form-control numberBox"
                            value={store.daysBetweenContacts}
                            type="number"
                            name="daysBetweenContacts"
                            onChange={(e) => {
                                dispatch({type: 'setData', field: 'daysBetweenContacts', payload: e.currentTarget.value})
                            }}
                        />
                </div> 
            </Col>  
            <Col lg={2}>
            <div className="form-group"> 
                        <label>Total Sends</label>
                        <input
                            className="form-control numberBox"
                            value={store.numberOfContacts}
                            type="number"
                            onChange={(e) => {
                                dispatch({type: 'setData', field: 'numberOfContacts', payload: e.currentTarget.value})
                            }}
                        />
                </div> 
            </Col>  
            <Col lg={3}>
            {/* <div className="form-group"> 
                        <label>Priority</label>
                        <Select
                            className="surveySelect"
                            options={store.priority}
                            isMulti={false}
                            onChange={(e) => {
                                dispatch({type: 'setData', field: 'priority', payload: e})
                            }}
                        />
                </div>  */}
            </Col>      
        </Row>
        <Row>
            <Col className="mx-auto" lg={12}>
                    <h3>{store.contactType.label}</h3>
                    <table size="sm" className="table">
                    <thead>
                        <tr>
                        <th scope="col"></th>
                        <th scope="col">Monday</th>
                        <th scope="col">Tuesday</th>
                        <th scope="col">Wednesday</th>
                        <th scope="col">Thursday</th>
                        <th scope="col">Friday</th>
                        <th scope="col">Saturday</th>
                        <th scope="col">Sunday</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th scope="row">Start</th>
                        <td>
                            <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.mondayStartHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'mondayStartHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.tuesdayStartHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'tuesdayStartHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.wednesdayStartHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'wednesdayStartHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.thursdayStartHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'thursdayStartHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.fridayStartHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'fridayStartHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.saturdayStartHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'saturdayStartHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.sundayStartHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'sundayStartHour', payload: e})}
                            />
                        </td>                                                                                                    
                    </tr>
                    <tr>
                        <th scope="row">End</th>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.mondayEndHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'mondayEndHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.tuesdayEndHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'tuesdayEndHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.wednesdayEndHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'wednesdayEndHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.thursdayEndHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'thursdayEndHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.fridayEndHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'fridayEndHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.saturdayEndHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'saturdayEndHour', payload: e})}
                            />
                        </td>
                        <td>
                        <Select
                                className="timeSelect"
                                options={hoursOfDay}
                                value={store.sundayEndHour}
                                isMulti={false}
                                onChange={(e) => dispatch({type: 'setData', field: 'sundayEndHour', payload: e})}
                            />
                        </td>                                                                                                    
                    </tr>                
                    </tbody>
                    </table>
            </Col> 
            </Row>
            {usage?.length > 0 ? 
            <div className="lowerLine">
                <Col lg={12}> 
                    <h3>Warning</h3>
                    <p>You are also making changes to the following surveys</p>
                    {usage && usage.map ((item,i) => {
                       return (<div key={i}>
                        <span className="block">{item.surveyDetails.clientName +  ': "' + item.surveyDetails.title + '"'}</span> 
                        <span className="block">{"End Date: " + item.surveyDetails.endDate }</span>
                        <span className="block">&nbsp;</span>
                    </div>)})}                                  
                </Col>
            </div>
            : ''}
            <div className="lowerLine">                               
                {store.name  && props.match.params.contactPlanId === undefined ? <Button className="buttonMove" variant="success" onClick={(e)=>{ ApiObjectBuilder("new"); }}>Save</Button>: ''}
                {store.name  && props.match.params.contactPlanId > 0 ? <Button className="buttonMove" variant="success" onClick={(e)=>{ ApiObjectBuilder("update"); }}>Update</Button>: ''}
            </div>
            </div>: ''}  
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            </LoadingOverlay>
        </Container>
      
    )

}