import React, { useState, useEffect, useReducer, useCallback } from "react";
import {Collapse, Popover, OverlayTrigger, Button, Row, Col } from "react-bootstrap";
import { ChangedFlag } from "models/changedFlag";
import {Plus, Dash, ArrowDown, ArrowUp,FileMinusFill,List,QuestionSquareFill, ListStars, CollectionFill, ArrowDownSquareFill,ArrowUpSquareFill, QuestionSquare} from "react-bootstrap-icons";
import "../SurveyBuilder.css";

const reducer = (store, action) =>{
    console.log("sectionBuilderAction", action);
    switch(action.type){
        case 'addSelectedQuestion':
            return {...store, selectedQuestions:[...store.selectedQuestions, action.payload]}
        case 'deleteSelectedQuestion':
            return {...store, selectedQuestions: store.selectedQuestions.filter(item => item.surveyItemWordingId !== action.payload.surveyItemWordingId)}
        case 'setData':
            return {...store, [action.field]: action.payload}
        default :
            return initialState;
    }
}

const initialState = {
    expand: false,
    selectedQuestions: [],
    newSectionName:"",
}

export default function SectionBuilder2(props) {

    const [store, dispatch] = React.useReducer(reducer, initialState);
    const [sectionShowEdit,setSectionShowEdit] = useState(false);
    const [questionBuilderShow,setQuestionBuilderShow] = useState(false);
    const [questionEditBuilderShow,setQuestionEditBuilderShow] = useState(false);
    
    let questions = [];
    questions = props.questions;
    return (
    <div className="sections">
        <Row>
                <Col lg={12} className="sectionStyle">
                    <span className="sectionHeading">
                        {store.expand 
                        ? <Dash className="iconStyleOverall" size={32} onClick={() => {
                                dispatch({type: 'setData', field: 'expand', payload: !store.expand});
                                setQuestionBuilderShow(false);
                                setQuestionEditBuilderShow(false);
                            }} />
                        : <Plus className="iconStyleOverall" size={32} onClick={() => {dispatch({type: 'setData', field: 'expand', payload: !store.expand})}} />
                        }
                        
                        {sectionShowEdit 
                        ?   <span className="editSectionForm">
                            <input className="form-control"
                            onChange={ (e) => dispatch({type: 'setData', field: 'newSectionName', payload: e.currentTarget.value})}
                        />
                                <Button variant="success" size="sm" type="submit" onClick={() => { 
                                            props.handleEditSection({id: props.id, sectionName: store.newSectionName});
                                            setSectionShowEdit(false);
                                        }}>Save</Button>&nbsp;
                                <Button variant="warning" size="sm" type="submit" onClick={() => setSectionShowEdit(false)}>Cancel</Button>
                            </span>  
                        :   <span onClick={(e) => {
                                            setSectionShowEdit(true); 
                                        }} >{props.section.name}</span>
                        }
                    </span>  
                    <span className="sectionHeadingTools sectionArrows">
                        <span className="sectionHeadingToolsItem">
                            {props.first !== true ? 
                                <ArrowUpSquareFill className="iconStyleTools" size={24} onClick={(e) => {
                                            props.handleMoveSectionUp({id:props.id}) 
                                        }}
                                /> 
                            : ''}
                        </span>
                        <span className="sectionHeadingToolsItem">
                            {props.last !== true ?
                                <ArrowDownSquareFill className="iconStyleTools" size={24} onClick={() => {
                                            props.handleMoveSectionDown({id:props.id}) 
                                        }}
                                /> : ''}
                        </span>
                    </span>
                    <span className="sectionHeadingDeleteTools">
                        {store.selectedQuestions.length > 0 ?
                            <FileMinusFill  size={24} onClick={ (e) => {
                                        props.handleDeleteQuestion({id: props.id, questions: store.selectedQuestions})
                                        props.handleDeleteQuestionUsed(store.selectedQuestions.map(item =>({surveyItemId: item.surveyItemId})))
                                        dispatch({type: 'setData', field: 'selectedQuestions', payload: new Array})
                                        e.currentTarget.value = "";
                                    }} />
                            : ''}
                        </span>                    
                    <span className="sectionHeadingTools">
                        <ListStars className="iconStyleAdd"  size={24} onClick={() => {
                            props.showAddQuestionModal(props.id);
                            dispatch({type: 'setData', field: 'expand', payload: true});
                        }}
                        /> 
                    </span>  
                    <span className="sectionHeadingToolsMove">
                    {store.selectedQuestions.length > 0 ?
                        <div>
                        <select onChange={ (e) => {
                                if (parseInt(e.currentTarget.value) !== props.id){
                                    props.handleMoveQuestion({id: e.currentTarget.value, questions: store.selectedQuestions})
                                    e.currentTarget.value = "";
                                }
                                dispatch({type: 'setData', field: 'selectedQuestions', payload: new Array})
                            }}>
                        <option key={9999} value="">Move To</option>
                        {props.sectionNames && props.sectionNames.map( (s,i)=>{
                            return (<option key={i} value={i}>{s.name}</option>)}
                        )}
                        </select>
                        </div>
                    : ''
                    } 
                    </span>
                </Col>
        </Row>
        <Row>
            <Col lg={12} className="listBackground">   
                <Collapse in={store.expand}>
                    <div id={`section-${props.id}`} lg={12}>
                        
                    {props.section.contents &&
                        props.section.contents.map((item, i) => {
                            item.currentSection = props.id;
                            let q = "";
                            
                            
                                if (questions && questions.find(quest => quest.surveyItemWordingId === item.surveyItemWordingId)){
                                    q = questions.find(quest => quest.surveyItemWordingId === item.surveyItemWordingId);
                                    
                                    if (q !== undefined){
                                        q.label = q.text;
                                    }
                                }

                                let flag = "";
                                if (item.changedFlag === ChangedFlag.New){
                                    flag = "greenFlag";
                                }
                                else if (item.changedFlag === ChangedFlag.Update){
                                        flag = "yellowFlag";
                                }
                                else if (item.changedFlag === ChangedFlag.Deleted){
                                    flag = "redFlag";
                                }
                                //IF I USE QUESTION.ID BELOW, NEW QUESTIONS CAN'T BE SELECTED BECAUSE THEY HAVE NO ID.
                                    
                            return (
                                <div className = {`questionStyle  ${flag}`} id={`question-${props.id}-${i}`} key={i}>
                                <span className="selectTools">
                                    {store.selectedQuestions.find(obj => obj.surveyItemWordingId === item.surveyItemWordingId) 
                                    ? <QuestionSquareFill className="tool" size={24} onClick={ (e) => {
                                        dispatch({type: 'deleteSelectedQuestion', payload: item});
                                        }}   />
                                    : <QuestionSquare className="tool" size={24} onClick={ (e) => {
                                        dispatch({type: 'addSelectedQuestion',  payload: item})
                                        }} />
                                    }
                                </span>
                                <span onClick={(e) => {
                                    props.showAddQuestionEditModal(item);
                                    }} className="sectionNumber">{q.question_number}</span>
                                <OverlayTrigger
                                    trigger="hover"
                                    delay={{ show: 250, hide: 400 }}
                                    key={i}
                                    
                                    overlay={
                                        <Popover id={`popover-positioned-${i}`}>
                                            <Popover.Title
                                            className="popTitleStyle"
                                            as="h3"
                                            >{q.responseSetDescription}</Popover.Title>
                                            <Popover.Content className="popTitleStyle">
                                            <ul
                                                className="popTitleStyle"
                                                id={i}
                                            >
                                                {q.choices &&
                                                q.choices.map(
                                                    (choice, index) => {
                                                    return (
                                                        <li key={index}>
                                                        {choice.text}
                                                        </li>
                                                    );
                                                    }
                                                )}
                                            </ul>
                                            </Popover.Content>
                                        </Popover>
                                    }>
                                    <span className="responseTools">
                                        {q.choices && q.choices.length > 0 
                                            ? <List className="tool"size={24} />
                                            : <>&nbsp;</>
                                        }
                                    </span>
                                </OverlayTrigger>
                                <span className="questionTools">
                                    {q.label}
                                </span>
                                <span className="sectionHeadingTools">
                                    <span className="sectionHeadingToolsItem">
                                    {props.section.contents[0].position !== item.position ?
                                        <ArrowUpSquareFill  size={24}
                                        className="iconStyle"
                                        onClick={(e) => {
                                            props.handleMoveQuestionUp({id:props.id, item: item}) 
                                        }}
                                        /> : ''}
                                    </span>    
                                    <span className="sectionHeadingToolsItem">
                                    {props.section.contents.slice(-1).pop().position !== item.position ?    
                                        <ArrowDownSquareFill  size={24}
                                        className="iconStyle"
                                        onClick={() => {
                                            props.handleMoveQuestionDown({id:props.id, item: item}) 
                                        }}
                                        /> : ''}
                                    </span>
                                </span>
                            </div>
                            )
                        })
                    }
                    </div>
                </Collapse>
            </Col>
        </Row>
    </div>
    )

}