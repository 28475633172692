import {getLastItemFromUrl, getSurveyTypes, retrieveToken} from "utils";
import SurveyService from "services/survey_definition/survey";
import SurveyManager from "managers/survey_definition/surveyManager";
import React, {useState, useEffect} from "react";
import {Field, Form, Formik} from "formik";
import {Breadcrumb, Button, Col, Container, Row} from "react-bootstrap";
import BootstrapForm from "react-bootstrap/Form";
import * as Yup from 'yup';
import ClientManager from "managers/clientManager";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { Helmet } from 'react-helmet'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function SurveyForm(props) {

  const FormHeaderSchema = Yup.object().shape({
    surveyTypeId: Yup.number()
      .required('Required')
      .positive('Required'),

    title: Yup.string()
      .min(2, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Required'),

  });
  const surveyIdParam = getLastItemFromUrl();
  let surveyService = new SurveyService(retrieveToken());
  let surveyManager = new SurveyManager(retrieveToken());
  let clientManager = new ClientManager(retrieveToken());
  let [loading, setLoading] = useState(false);

  let [formData, setFormData] = useState(SurveyService.getBlank());

  let [surveyTypes, setSurveyTypes] = useState([]);
  let [surveyTemplates, setSurveyTemplates] = useState([]);
  let [clientList, setClientList] = useState([]);
  let [legacyId, setLegacyId] = useState("");
  let [clientId, setClientId] = useState("");
  let [startDate, setStartDate] = useState("");
  let [endDate, setEndDate] = useState("");
  let regex = /^SV_[a-zA-Z0-9]{11,15}$/;

  useEffect(() => {
    setLoading(true);
    getSurveyTypes().then(x => setSurveyTypes(x));
    surveyManager.getSurveyTemplates().then((x) => {
      setSurveyTemplates(x);
    });

    // If we're not on a new version of the page, load it from the database
    if (surveyIdParam !== "new") {
      surveyService.getById(surveyIdParam).then((x) => {
        
        var end = new Date(x.endDate);
        var start = new Date(x.startDate);

        setEndDate(end);
        setStartDate(start);
        setFormData(x);
        setLegacyId(x.legacyClientId);
        setClientId(x.clientId);
      });
    }

    clientManager.getList().then( x=> {
      setClientList(x);
      setLoading(false);
    });
  }, []);

  return (
    <LoadingOverlay
    active={loading}
    spinner={<BounceLoader />}
    styles={{
      overlay: (base) => ({
        ...base,
        background: "rgba(91, 134, 96, 0.5)",
      }),
      content: (base) => ({
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
      }),
    }}
    text="Loading...">
    <Container fluid={true}>
      {surveyIdParam === "new"
      ? <Helmet><title>StayMetrics Admin - New Survey</title></Helmet>
      : <Helmet><title>StayMetrics Admin - Edit Survey</title></Helmet>}
      <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Survey Definition</Breadcrumb.Item>
            <Breadcrumb.Item href="/survey-definition/survey">
              Surveys
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#">Create New Survey</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row><Col><h1>Survey Header</h1></Col></Row>

      <Formik
        initialValues={formData}
        validationSchema={FormHeaderSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {

          values.startDate = startDate;
          values.endDate = endDate;

          if (surveyIdParam === "new") {
            values.clientId = clientId;
            values.legacyClientId = legacyId;
            let new_survey = surveyService.insert(values);
            new_survey.then((data)=>{
              if (data)
              {
                window.location.href = "/survey-definition/survey/" + data +"/build";
              }
            })
          } else {
            values.clientId = clientId;
            values.legacyClientId = legacyId;
            let new_survey = surveyService.update(surveyIdParam, values);
            new_survey.then((data)=>{
                window.location.href = "/survey-definition/survey/"+ data +"/build";
            });
          }
        }}
      >
        {props => {
          return (<Form>
              <Row className="show-grid">
              <Col xs={1} md={4}></Col>
              <Col xs={4} md={4}>
              <div className="form-group">
              <label>Client: </label>
              <Field
                as="select"
                onChange={ (e)=>{
                  setLegacyId(clientList.filter((client) => { return client.id == e.currentTarget.value })[0].stayMetricsId);
                  setClientId(e.currentTarget.value);
                  }
                }
                multiple={false}
                className={`form-control ${props.touched.clientId && props.errors.clientId ? "is-invalid" : ""}`}
                name="clientId"
                value={clientId}
              >
                <option value="">Select</option>
                {clientList.map((x, i) => {
                        return <option key={i} value={x.id}>{x.name}</option>
                      })}
              </Field>
              <BootstrapForm.Control.Feedback type="invalid">
                {props.errors.clientId}
              </BootstrapForm.Control.Feedback>
                </div>
                </Col>
                <Col xs={1} md={4}></Col>
            </Row>
            <Row className="show-grid">
              <Col xs={1} md={4}></Col>
              <Col xs={4} md={4}>
                <div className="form-group">
                  <label>Legacy ID: </label>
                  <Field
                  as="input"
                    disabled
                    className="form-control"
                    name="stayMetricsId"
                    value={legacyId}
                  />
                </div>
              </Col>
              <Col xs={1} md={4}></Col>
            </Row>
            <Row className="show-grid">
              <Col xs={1} md={4}></Col>
              <Col xs={4} md={4}>
                <div className="form-group">
                  <label className="dateLabel">Start Date</label>
                  <DatePicker className="dateLabel"
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  showTimeSelect
                  timeFormat="hh:mm"
                  timeIntervals={1}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  />
                <br></br>
                <label className="dateLabel">End Date</label>
                  <DatePicker className="dateLabel"
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  showTimeSelect
                  timeFormat="hh:mm"
                  timeIntervals={1}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                    />
               </div>
              </Col>
              <Col xs={1} md={4}></Col>
            </Row>
            <Row className="show-grid">
              <Col xs={1} md={4}></Col>
              <Col xs={4} md={4}>
                <div className="form-group">
                  <label>Participant Type: </label>
                  <Field
                    className="form-control"
                    name="participantTypeText"
                    value={props.values.participantTypeText}
                  />
                </div>
              </Col>
              <Col xs={1} md={4}></Col>
            </Row>
              <Row className="show-grid">
                <Col xs={1} md={4}></Col>
                <Col xs={4} md={4}>
                  <div className="form-group">
                    <label>Survey Type: </label>
                    <Field
                      as="select"
                      className={`form-control ${props.touched.surveyTypeId && props.errors.surveyTypeId ? "is-invalid" : ""}`}
                      name="surveyTypeId"
                      value={props.values.surveyTypeId}
                    >
                      <option>Select</option>
                      {surveyTypes.map((x, i) => {
                        return <option key={i} value={i}>{x}</option>
                      })}
                    </Field>
                    <BootstrapForm.Control.Feedback type="invalid">
                      {props.errors.surveyTypeId}
                    </BootstrapForm.Control.Feedback>
                  </div>
                </Col>
                <Col xs={1} md={4}></Col>
              </Row>
              <Row className="show-grid">
                <Col xs={1} md={4}></Col>
                <Col xs={4} md={4}>
                  <div className="form-group">
                    <label>Title: </label>
                    <Field
                      name="title"
                      value={props.values.title}
                      className={`form-control ${props.touched.title && props.errors.title ? "is-invalid" : ""}`}
                    />
                    <BootstrapForm.Control.Feedback type="invalid">
                      {props.errors.title}
                    </BootstrapForm.Control.Feedback>
                  </div>
                </Col>
                <Col xs={1} md={4}></Col>
              </Row>
            { (surveyIdParam !== "new") ? '' :
              <Row className="show-grid">
                <Col xs={1} md={4}></Col>
                <Col xs={4} md={4}>
                  <div className="form-group">
                    <label>Template: </label>
                    <Field
                      as="select"
                      className={`form-control`}
                      name="copyFromClientSurveyId"
                    >
                      <option>Select</option>
                      {surveyTemplates.map((x, i) => {
                        return <option key={i} value={x.id}>{x.template_name}</option>
                      })}
                    </Field>
                    <BootstrapForm.Control.Feedback type="invalid">
                      {props.errors.surveyTypeId}
                    </BootstrapForm.Control.Feedback>
                  </div>
                </Col>
                <Col xs={1} md={4}></Col>
              </Row>
            }

              <Row className="show-grid">
                <Col xs={1} md={4}></Col>
                <Col xs={4} md={4}>
                  <div className="form-group">
                    <label>Qualtrics Survey ID: </label>
                    <Field
                      className="form-control"
                      name="qualtricsSurveyId"
                      value={props.values.qualtricsSurveyId}
                    />
                  </div>
                </Col>
                <Col xs={1} md={4}></Col>
              </Row>
              <Row className="show-grid">
                <Col xs={1} md={4}></Col>
                <Col xs={4} md={4}>
                  <div className="form-group">
                    <label>Auto Import: </label>
                    <Field
                      as="select"
                      className={`form-control`}
                      name="isAutoImport"
                      disabled={!regex.test(props.values.qualtricsSurveyId)}
                      value={props.values.isAutoImport}
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </Field>
                  </div>
                </Col>
                <Col xs={1} md={4}></Col>
              </Row>
              <Row className="show-grid">
                <Col xs={1} md={4}></Col>
                <Col xs={4} md={4}>
                  <div className="form-group">
                    <label>Active: </label>
                    <Field
                      as="select"
                      className={`form-control`}
                      name="isActive"
                      value={props.values.isActive}
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </Field>
                  </div>
                </Col>
                <Col xs={1} md={4}></Col>
              </Row>
              <Row className="show-grid">
                <Col xs={1} md={4}></Col>
                <Col xs={4} md={4}>
                  <div className="form-group">
                    <label>Notes: </label>
                    <Field
                      className="form-control"
                      name="notes"
                      value={props.values.notes}
                    />
                  </div>
                </Col>
                <Col xs={1} md={4}></Col>
              </Row>
              <Row className="show-grid">
                <Col xs={1} md={4}></Col>
                <Col xs={4} md={4}>
                {clientId !=="" && legacyId !== 0 ?
                  <Button variant="success" size="lg" type="submit">
                    {surveyIdParam === "new" ? 'Create New Survey' : 'Save Update'}
                    </Button>
                  : ''
                }
                </Col>
                <Col xs={1} md={4}></Col>
              </Row>
          </Form>)
        }}
      </Formik>

    </Container>
    </LoadingOverlay>
  );
}
