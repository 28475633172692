import React, {useEffect} from "react";
import {Col, Row, Modal, Button, Form} from "react-bootstrap";
import MenuList from "components/survey_definition/survey/MenuList";
import "../SurveyBuilder.css";
import Select from "react-select";
import {ChangedFlag} from "models/changedFlag";

const reducer = (store, action) => {
  switch (action.type) {
    case 'setData':
      return {...store, [action.field]: action.payload}
    case 'addSelectedQuestion':
      return {...store, questionsToAdd: [...store.questionsToAdd, action.payload]}
    case 'deleteSelectedQuestion':
      return {
        ...store,
        questionsToAdd: store.questionsToAdd.filter(item => item.surveyItemWordingId !== action.payload.surveyItemWordingId)
      }
    default :
      return initialState;
  }
}
const initialState = {
  defaultWording: "",
  selectedQuestionNumber: "",
  selectedQuestion: "",
  reportCategory: "",
  variableName: "",
  questionsToAdd: [],
  questions: []
}

export default function AddQuestionModel(props) {
  const [store, dispatch] = React.useReducer(reducer, initialState);

  useEffect(() => {
    let questions = props.questions;
    questions = questions.filter(ar => !props.questionsUsed.find(rm => (rm === ar.surveyItemId)))
    dispatch({type: 'setData', field: 'questions', payload: questions});
  }, [props.questionsUsed, props.sectionId, props.questions]);

  return (<Modal dialogClassName="questionModal" show={props.show} onHide={props.onHide}
                 size="lg"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered>
    {props.children}
    <Modal.Header className="modalBG" closeButton>
      <Modal.Title className="modalBG" id="contained-modal-title-vcenter">
        Add Questions
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="modalBG">
      <Row className="addQuestionStyle">
        <Col lg={12}>
          <ul>
            <li className="addQuestionHeader">
              <Row>
                <Col lg={2}>
                  <label>SMQ #</label>
                  <Select className="addQuestionItem"
                          components={{MenuList}}
                          options={store.questions.map(item => ({
                            label: item.question_number,
                            value: item.surveyItemId,
                            variableName: item.variableName,
                            reportCategory: item.reportCategory
                          }))}
                          value={store.selectedQuestionNumber}
                          onChange={(e) => {
                            dispatch({type: 'setData', field: 'selectedQuestionNumber', payload: e});
                            let x = store.questions.filter(item => ((item.surveyItemId === e.value) && item.default === true))
                            let defaultWording = store.questions.find(item => (item.surveyItemWordingId === x[0].surveyItemWordingId))
                            dispatch({type: 'setData', field: 'selectedQuestion', payload: defaultWording});
                            dispatch({type: 'setData', field: 'variableName', payload: e.variableName});
                            dispatch({type: 'setData', field: 'reportCategory', payload: e.reportCategory});
                          }}
                          captureMenuScroll={false}
                  />
                </Col>
                <Col lg={4}>

                  {Object.keys(store.selectedQuestionNumber).length !== 0 ?
                    <>
                      <label>Select Wording</label>
                      <Select className="addQuestionItem"
                              components={{MenuList}}
                              getOptionLabel={option => option.fullText}
                              getOptionValue={option => option.surveyItemWordingId}
                              value={store.selectedQuestion}
                              options={store.questions.filter(item => (item.surveyItemId === store.selectedQuestionNumber.value))}
                              onChange={(e) => {
                                dispatch({type: 'setData', field: 'selectedQuestion', payload: e});
                              }}
                      />

                    </>
                    : ''}
                </Col>
                {Object.keys(store.selectedQuestion).length !== 0 ?
                  <>
                    <Col lg={2}>
                      <label>Variable Name</label>
                      <Form.Control type="input" className="addQuestionItem"
                                    value={store.variableName}
                                    onChange={(e) => {
                                      dispatch({
                                        type: 'setData',
                                        field: 'variableName',
                                        payload: e.currentTarget.value
                                      });
                                    }}
                      />
                    </Col>
                    <Col lg={2}>
                      <label>Report Category</label>
                      <Form.Control type="input" className="addQuestionItem"
                                    value={store.reportCategory}
                                    onChange={(e) => {
                                      dispatch({
                                        type: 'setData',
                                        field: 'reportCategory',
                                        payload: e.currentTarget.value
                                      });
                                    }}
                      />
                    </Col>
                    <Col lg={1}>
                      <Button className="buttonFormat" variant="success" size="sm" type="submit" onClick={(e) => {
                        let q = store.selectedQuestion;
                        q.changedFlag = ChangedFlag.New;
                        q.reportCategory = store.reportCategory;
                        q.variableName = store.variableName;

                        let questions = store.questions.filter(item => item.surveyItemId !== q.surveyItemId);
                        dispatch({type: 'setData', field: 'questions', payload: questions});
                        dispatch({type: 'addSelectedQuestion', payload: q})
                        dispatch({type: 'setData', field: 'reportCategory', payload: ""});
                        dispatch({type: 'setData', field: 'variableName', payload: ""});
                        dispatch({type: 'setData', field: 'selectedQuestion', payload: {}});
                        dispatch({type: 'setData', field: 'selectedQuestionNumber', payload: {}});
                      }}>+</Button>
                    </Col>
                  </> : ''
                }
              </Row>
            </li>

            {store.questionsToAdd && store.questionsToAdd.map((q, i) => {
              return (<li key={i} className="addQuestionList">
                  <Row>
                    <Col lg={1}>{i + 1}</Col>
                    <Col lg={4}>{q.fullText}</Col>
                    <Col lg={2}>{q.variableName}</Col>
                    <Col lg={2}>{q.reportCategory}</Col>
                    <Col lg={2}>
                      <Button variant="success" size="sm" type="submit" onClick={(e) => {

                        dispatch({type: 'deleteSelectedQuestion', payload: q})
                      }}>-</Button>
                    </Col>
                  </Row>
                </li>
              );
            })}
            <li className="addQuestionFooter">
              <Row>
                <Col lg={12}>
                  <Button variant="success" size="sm" type="submit" onClick={(e) => {
                    props.addQuestions({id: props.sectionId, questions: store.questionsToAdd});
                    dispatch({type: 'setData', field: 'questionsToAdd', payload: []});
                  }}>Add Questions</Button>&nbsp;
                  <Button variant="success" size="sm" type="submit" onClick={(e) => {
                    dispatch({type: 'setData', field: 'questionsToAdd', payload: []});
                    props.onHide();
                  }}>Cancel</Button>
                </Col>
              </Row>
            </li>
          </ul>
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer className="modalBG">

    </Modal.Footer>
  </Modal>)

}
