import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import QuestionService from "services/survey_definition/question";
import DefinitionService from "services/survey_definition/definitions";
import { Helmet } from 'react-helmet'
import LoadingOverlayWrapper from "../../LoadingOverlayWrapper";

export default function QuestionList(props) {

  const [questions, setQuestions] = useState([]);
  let [loading, setLoading] = useState(false);

  let token = props.currentuser.smToken;
  let questionService = new QuestionService(token);
  let definitionService = new DefinitionService(token);

  useEffect(() => {
    setLoading(true);
    questionService.list()
      .then(q => {

        let responseSetType = [];
        let questionCatType = [];
        let approvalStatus = [];


        definitionService.retrieve()
          .then(definition => {
            definition.surveyItem.approvalStatuses.forEach(x => approvalStatus[x.id] = x.status);
            definition.surveyItem.itemTypes.forEach(x => questionCatType[x.id] = x.description);
            definition.surveyItem.responseSetTypes.forEach(x => responseSetType[x.id] = x.description);
            let myrow = [];
            q.forEach(x => {
              x.wordings.forEach(y => {
                if (y.default) {
                  y.notes = x.notes
                  y.defaultWording = '✅';
                }
                y.questionNumber = x.questionNumber;

                y.responseSetDescription = x.responseSetDescription;
                y.categoryText = questionCatType[x.itemTypeId];
                y.questionTypeText = responseSetType[x.responseSetTypeId];
                y.approvalStatusText = approvalStatus[x.approvalStatusId];
                y.editLink = <Link to={'/survey-definition/question/' + x.id}>Edit</Link>;
                myrow.push(y);
              });
            });
            setQuestions(myrow);
            setLoading(false);
          });
      });

  }, [setQuestions]);

  const { SearchBar } = Search;
  const columns = [
    {
      text: 'Question Number',
      dataField: 'questionNumber'
    },
    {
      text: 'Question Text',
      dataField: 'text'
    },
    {
      text: 'Default Wording',
      dataField: 'defaultWording'
    },
    {
      text: 'Category',
      dataField: 'categoryText'
    },
    {
      text: 'Question Type',
      dataField: 'questionTypeText'
    },
    {
      text: 'Response Set Description',
      dataField: 'responseSetDescription'
    },
    {
      text: 'Notes',
      dataField: 'notes'
    },
    {
      text: 'Status',
      dataField: 'approvalStatusText'
    },
    {
      text: '',
      dataField: 'editLink'
    },
  ];

  return (
    <Container fluid={true}>
      <Helmet><title>StayMetrics Admin - Question List</title></Helmet>
      <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Survey Definition</Breadcrumb.Item>
            <Breadcrumb.Item href="#">
              Questions
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row><Col><h1>Questions</h1></Col></Row>
      <LoadingOverlayWrapper loading={loading}>
        <ToolkitProvider
          keyField="id"
          data={questions}
          columns={columns}
          search
        >
          {
            props => (
              <>
                <Row>
                  <Col lg={6}>
                    <Link className="btn btn-primary" to="/survey-definition/question/new">New Question</Link>
                  </Col>
                  <Col lg={6} className="text-right">
                    <SearchBar {...props.searchProps} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory()}
                    />
                  </Col>
                </Row>
              </>
            )
          }
        </ToolkitProvider>
      </LoadingOverlayWrapper>
    </Container>
  );
}
