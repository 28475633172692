import PowerBiReport from "models/powerBiReport";
import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import PowerBiWorkspace from "models/powerBiWorkspace";

export default class PowerBiReportService {
  private client: AxiosInstance;
  constructor(token: string) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_BASEURL,
      headers: {
        "content-type": "application/json",
        "Authorization": "bearer " + token
      }
    };
    this.client = axios.create(config);
  }

  getReportsByClientId(id: number): Promise<PowerBiReport[]>{
    return this.client.get<PowerBiReport[]>(`api/v2/client-portal/powerbi/${id}`)
      .then((definitions: AxiosResponse<PowerBiReport[]>) => definitions.data)
      .catch((er: any) => { console.log(er); return [] });
  }

  getList(): Promise<PowerBiReport[]> {
    return this.client.get<PowerBiReport[]>('api/v2/client-portal/powerbi')
      .then((definitions: AxiosResponse<PowerBiReport[]>) => definitions.data)
      .catch((er: any) => { console.log(er); return [] });
  }

  getWorkspaces(): Promise<PowerBiWorkspace[]>{
    return this.client.get<PowerBiWorkspace[]>('api/v2/client-portal/powerbi/workspaces')
    .then((definitions: AxiosResponse<PowerBiWorkspace[]>) => definitions.data)
    .catch((er: any) => { console.log(er); return [] });
  }
}
