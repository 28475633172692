import React from "react";
import MultipleChoiceResponseSetType from "./response_set_forms/multiple_choice";
import Form from "react-bootstrap/Form";
import {QuestionReducerContext} from "../questionReducerContext";

const QuestionResponseSet = (props) => {
  const {store, dispatch} = React.useContext(QuestionReducerContext);
  const types=store.surveyItemDefinition.responseSetTypes;
  const UpdateSet = (e) => {
    if (!isMultipleChoiceResponseSetType(store.question.responseSet)) {
      dispatch({type: 'setQuestionField', field: 'choices', payload: []})
    }
    dispatch({type:'setQuestionField', field:'responseSet', payload: Number(e.currentTarget.value)});
  }
  const isMultipleChoiceResponseSetType = (responseSet) => [2,3].indexOf(responseSet) > -1;
  return (
    <>
      <label>Select Type:</label>
      <Form.Control
        as="select"
        defaultValue={store.question.responseSet}
        isInvalid={store.touched.responseSet && store.errors.responseSet}
        onChange={UpdateSet}
      >
        <option value={0}>Select</option>
        {types.map((option, index) => {
          return <option key={index} value={option.id}>{option.description}</option>
        })}
      </Form.Control>

      <MultipleChoiceResponseSetType display={isMultipleChoiceResponseSetType(store.question.responseSet)} />
      <Form.Control.Feedback type="invalid">
        {store.errors.responseSet}
      </Form.Control.Feedback>
    </>
  );
};

export default QuestionResponseSet;
