import {ItemTypeBySurveyType} from "managers/survey_definition/models";
import {Col, Row, Form} from "react-bootstrap";
import React from "react";
import {ItemTypeSelectedCallback} from "./index";
import ItemType from "models/itemType";

export const ItemTypesBySurveyTypesRow = (props: ItemTypesBySurveyTypesRowParameters) => {
  const {itemTypeBySurveyType, index, itemTypes, onItemTypeChanged} = props;
  return (
    <Row className="rowColor">
      <Col xs={4} lg={4}>{itemTypeBySurveyType.name}</Col>
      <Col xs={4} lg={4}>{itemTypeBySurveyType.group}</Col>
      <Col xs={4} lg={4}>
        <Form.Control
          as="select"
          defaultValue={itemTypeBySurveyType.itemTypeId ?? 0}
          onChange={e => onItemTypeChanged(Number(e.currentTarget.value), index)}
        >
          <option value={0}>&nbsp;</option>

          {itemTypes.map(itemType => {
            return <option key={itemType.id} value={itemType.id}>
              {itemType.description}
            </option>
          })}
        </Form.Control>
      </Col>
    </Row>
  );
};

interface ItemTypesBySurveyTypesRowParameters {
  itemTypeBySurveyType: ItemTypeBySurveyType;
  index: number;
  itemTypes: ItemType[];
  onItemTypeChanged : ItemTypeSelectedCallback;
}
