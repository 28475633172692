import React from "react";
import Form from "react-bootstrap/Form";
import {QuestionReducerContext} from "components/survey_definition/question/questionReducerContext";

const QuestionInfo = (props: QuestionInfoParameters) => {
  const {store, dispatch} = React.useContext(QuestionReducerContext);
  return (
    <fieldset className="border p-2">
      <legend className="w-auto">Question Info</legend>
      <Form.Group controlId="questionNumber">
        <Form.Row>
          <Form.Label className="inline-block mr-2">SMQ</Form.Label>
          {
            props.formType === 'new' && store.surveyItemDefinition.lastSmqUsed ?
              <Form.Text className="text-muted">Last SMQ used: {store.surveyItemDefinition.lastSmqUsed.join(', ')}</Form.Text>
              :
              <></>
          }
        </Form.Row>

        <Form.Control
          placeholder={"SMQ#"}
          defaultValue={store.question.questionNumber}
          onBlur={e => dispatch({type:'setQuestionField', field:'questionNumber', payload:e.currentTarget.value})}
          isInvalid={store.touched.questionNumber && store.errors.questionNumber}
        />
        <Form.Control.Feedback type="invalid">
          {store.errors.questionNumber}
        </Form.Control.Feedback>
      </Form.Group>
    </fieldset>
  );
};

interface QuestionInfoParameters {
  formType: string;
}

export default QuestionInfo;
