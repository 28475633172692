import MetaService from "services/metaService";
import Meta from "models/meta";

export default class MetaManager {
    private metaService: MetaService;

    constructor(token: string) {
        this.metaService = new MetaService(token);
    }

    getList(): Promise<Meta[]> {
        return this.metaService.getList()
        .then(x => {
            x.sort((a, b) => (a.name > b.name) ? 1 : -1)
            return x
        });
    }

    getTelnyxList(): Promise<any[]> {
        return this.metaService.getTelnyxList()
        .then(x => {
            x.sort((a, b) => (a.name > b.name) ? 1 : -1)
            return x
        });
    }

}
